import React from 'react';
import PropTypes from 'prop-types';
import styles from './sass/ImageSlider.module.scss';

import { ReactComponent as ArrowNextActive } from '../../assets/icons/arrowNextActive.svg';
import { ReactComponent as ArrowNextDisable } from '../../assets/icons/arrowNextDisable.svg';

const NextArrow = ({
  className, style, onClick, currentSlide, slideCount
}) => (
  <button className={`${className} ${styles.arrow}`} onClick={onClick} style={style}>
    {currentSlide < slideCount - 1 ? <ArrowNextActive /> : <ArrowNextDisable />}
  </button>
);

NextArrow.propTypes = {
  className: PropTypes.string,
  style: PropTypes.shape({}),
  onClick: PropTypes.func,
  currentSlide: PropTypes.number,
  slideCount: PropTypes.number
};

NextArrow.defaultProps = {
  className: '',
  style: undefined,
  onClick: undefined,
  currentSlide: 0,
  slideCount: 0
};

export default NextArrow;
