import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import instance from '../../services/axios';
import notify from '../../utils/notify';
import { ENCRYPT_GIFTOON_API, PRIVATE } from '../../constants/api';

export const initialState = {
  error: null,
  isLoading: false,
  data: {},
};

export const ReceivedGiftoonThunk = createAsyncThunk(
  'receivedGiftoon/receivedGiftoonThunk',
  async ({ hash }, { rejectWithValue }) => {
    try {
      const { data } = await instance.get(`${ENCRYPT_GIFTOON_API}${hash}${PRIVATE}`);
      return data;
    } catch ({ response: { data } }) {
      notify('error', Object.values(data)[0]);
      return rejectWithValue(data);
    }
  }
);

export const ReceivedGiftoonSlice = createSlice({
  name: 'receivedGiftoon',
  initialState,
  extraReducers: (builder) => {
    builder
      .addCase(ReceivedGiftoonThunk.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(ReceivedGiftoonThunk.fulfilled, (state, action) => {
        state.isLoading = false;
        state.data = action.payload;
      })
      .addCase(ReceivedGiftoonThunk.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
      });
  }
});

export default ReceivedGiftoonSlice.reducer;
