import React from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import Button from '../Button/Button';
import styles from './sass/GiftoonCard.module.scss';

import SmileIcon from '../../assets/icons/smile.svg';

const GiftoonCard = ({
  persons, time, image, onSelect, id
}) => {
  const { t } = useTranslation('', { keyPrefix: 'shopGiftoons' });
  return (
    <div className={styles.giftoonCard}>
      <img className={styles.giftoonImage} src={image} alt="Card" />
      <div role="presentation" className={styles.focusedCard} onClick={() => onSelect(id)}>
        <h2>{`${persons} ${persons > 1 ? t('persons') : t('person')}`}</h2>
        <p>{time}</p>
        <div className={styles.buttonContainer}>
          <Button
            text={t('goECards')}
            iconLeft={SmileIcon}
            buttonStyle={styles.cardButton}
          />
        </div>
      </div>
    </div>
  );
};

GiftoonCard.propTypes = {
  persons: PropTypes.number.isRequired,
  time: PropTypes.string.isRequired,
  onSelect: PropTypes.func.isRequired,
  id: PropTypes.number.isRequired,
  image: PropTypes.string
};

GiftoonCard.defaultProps = {
  image: '../../assets/mock/giftoonDefaultImage.jpg'
};

export default GiftoonCard;
