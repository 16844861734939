import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import Modal from '../../../components/Modal/Modal';
import styles from '../sass/RetailerModal.module.scss';

import CancelIcon from '../../../assets/icons/cancelIcon.svg';
import InfoIcon from '../../../assets/icons/info.svg';

const RetailerModal = ({ isOpen, onClose, text }) => {
  const { t } = useTranslation('', { keyPrefix: 'selectBrands' });
    
  return (
    <Modal isOpen={isOpen} onClose={onClose} containerStyles={styles.modalContainer}>
      <button onClick={onClose} className={styles.button}>
        <img src={CancelIcon} alt="cancel" />
      </button>
      <div>
        <img className={styles.infoIcon} src={InfoIcon} alt="info" />
        <h1 className={styles.heading}>{t('retailerTerms')}</h1>
        <p className={styles.modalDescription}>{text}</p>
      </div>
    </Modal>
  );
};

RetailerModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  text: PropTypes.string.isRequired,
};

export default RetailerModal;
