import React from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import Input from '../../../components/Input/Input';
import styles from '../sass/ContactUs.module.scss';
import Button from '../../../components/Button/Button';
import ArrowIcon from '../../../assets/icons/signInCircleWhite.svg';

const InputForm = ({
  handleSubmit, name, errors, email, message, handleChange, handleBlur
}) => {
  const { t } = useTranslation('', { keyPrefix: 'contactUs' });
  return (
    <div className={styles.form}>
      <h1>{t('contact')}</h1>
      <p className={styles.description}>{t('getInTouch')}</p>
      <div className={styles.inputContainer}>
        <Input
          name="name"
          value={name}
          placeholder={t('name')}
          error={errors.name}
          onChange={handleChange}
          onBlur={handleBlur}
        />
      </div>
      <div className={styles.inputContainer}>
        <Input
          name="email"
          value={email}
          placeholder={t('email')}
          error={errors.email}
          onChange={handleChange}
          onBlur={handleBlur}
        />
      </div>
      <div className={styles.inputContainer}>
        <Input
          name="message"
          value={message}
          placeholder={t('yourMessage')}
          error={errors.message}
          onChange={handleChange}
          onBlur={handleBlur}
        />
      </div>
      <Button
        onClick={handleSubmit}
        buttonStyle={styles.button}
        isFilled
        text={t('sendMessage')}
        iconRight={ArrowIcon}
      />
    </div>
  );
};

InputForm.propTypes = {
  name: PropTypes.string.isRequired,
  email: PropTypes.string.isRequired,
  message: PropTypes.string.isRequired,
  errors: PropTypes.shape({
    name: PropTypes.string,
    email: PropTypes.string,
    message: PropTypes.string,
  }).isRequired,
  handleChange: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  handleBlur: PropTypes.func.isRequired,
};

export default InputForm;
