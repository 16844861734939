import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import styles from '../sass/SignIn.module.scss';

const ResendEmailText = ({ onResendClick }) => {
  const { t } = useTranslation('', { keyPrefix: 'signIn' });

  return (
    <div className={styles.verifyText}>
      {t('ifAlreadyRegistered')}
      <span className={styles.link} onClick={onResendClick} role="presentation">
        {t('verifyHere')}
      </span>
    </div>
  );
};

ResendEmailText.propTypes = {
  onResendClick: PropTypes.func.isRequired
};

export default ResendEmailText;
