import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import styles from '../sass/FooterSection.module.scss';

const FooterSection = ({ title, items }) => {
  return (
    <div className={styles.section}>
      <p className={styles.sectionHeading}>{ title }</p>
      <ul className={styles.sectionList}>
        {items.map(({ name, link }) => (
          <li key={name}>
            <Link to={link}>{name}</Link>
          </li>
        ))}
      </ul>
    </div>
  );
};

FooterSection.propTypes = {
  title: PropTypes.string.isRequired,
  items: PropTypes.arrayOf(PropTypes.shape({
    name: PropTypes.string,
    link: PropTypes.string,
  })).isRequired,
};

export default FooterSection;
