import React from 'react';
import PropTypes from 'prop-types';
import { useFormik } from 'formik';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { GiftShareToEmailViewThunk } from '../../../store/giftView/slice';
import { sendEmailValidation } from '../../../utils/validation';
import Modal from '../../Modal/Modal';
import Input from '../../Input/Input';
import Button from '../../Button/Button';
import styles from '../sass/EmailShareModal.module.scss';

import CrossIcon from '../../../assets/icons/circleCross.svg';

const EmailShareModal = ({ modalIsOpened, closeModal, giftoonUrl }) => {
  const { t } = useTranslation('', { keyPrefix: 'giftView' });
  const dispatch = useDispatch();

  const {
    handleSubmit,
    values: { senderName, recipientsEmails },
    handleChange,
    validateForm,
    errors,
    handleBlur,
  } = useFormik({
    initialValues: { senderName: '', recipientsEmails: '' },
    onSubmit: (values) => validateForm(values).then(() => {
      dispatch(GiftShareToEmailViewThunk({ email: values.recipientsEmails, url: giftoonUrl }));
      closeModal();
    }),
    validationSchema: sendEmailValidation,
    validateOnBlur: false,
    validateOnChange: false
  });

  return (
    <Modal isOpen={modalIsOpened} onClose={closeModal}>
      <div className={styles.modalContainer}>
        <div className={styles.modalHeader}>
          <h3>{t('sendEmail')}</h3>
          <button className={styles.closeButton} onClick={closeModal}>
            <img src={CrossIcon} alt="close" />
          </button>
        </div>
        <div className={styles.formContainer}>
          <Input
            name="senderName"
            placeholder={t('theirName')}
            value={senderName}
            error={errors.senderName}
            onChange={handleChange}
            onBlur={handleBlur}
          />
          <Input
            name="recipientsEmails"
            placeholder={t('theirEmail')}
            value={recipientsEmails}
            error={errors.recipientsEmails}
            onChange={handleChange}
            onBlur={handleBlur}
          />
          <div className="buttonContainer">
            <Button onClick={handleSubmit} text={t('send')} isFilled />
          </div>
        </div>
      </div>
    </Modal>
  );
};

EmailShareModal.propTypes = {
  modalIsOpened: PropTypes.bool.isRequired,
  closeModal: PropTypes.func.isRequired,
  giftoonUrl: PropTypes.string.isRequired,
};

export default EmailShareModal;
