import React from 'react';
import Slider from 'react-slick';
import { useTranslation } from 'react-i18next';
import HowItWorksStep from './HowItWorksStep';
import styles from '../sass/HowItWorks.module.scss';

import Line from '../../../assets/images/linePink.jpg';

const HowItWorks = () => {
  const { t } = useTranslation('', { keyPrefix: 'home' });

  const stepsInfo = [
    { heading: t('howItWorksStepOneHeading'), stepNumber: 1, description: t('howItWorksStepOneDescription') },
    { heading: t('howItWorksStepTwoHeading'), stepNumber: 2, description: t('howItWorksStepTwoDescription') },
    { heading: t('howItWorksStepThreeHeading'), stepNumber: 3, description: t('howItWorksStepThreeDescription') },
    { heading: t('howItWorksStepFourHeading'), stepNumber: 4, description: t('howItWorksStepFourDescription') },
  ];

  const settings = {
    dots: true,
    slidesToShow: 2,
    slidesToScroll: 1,
    swipeToSlide: true,
    infinite: false,
    arrows: false,

    responsive: [
      {
        breakpoint: 370,
        settings: {
          slidesToShow: 1,
        }
      }
    ]
  };

  return (
    <div className={styles.block}>
      <div className={`container ${styles.container}`}>
        <h1 className={styles.heading}>{t('howItWorks')}</h1>
        <img className={styles.line} src={Line} alt={t('howItWorks')} />
        <div className={styles.guide}>
          {stepsInfo.map(({ heading, stepNumber, description }) => (
            <HowItWorksStep
              key={stepNumber}
              heading={heading}
              stepNumber={stepNumber}
              description={description}
            />
          ))}
        </div>
        <Slider className={styles.slider} {...settings}>
          {stepsInfo.map(({ heading, stepNumber, description }) => (
            <HowItWorksStep
              key={stepNumber}
              heading={heading}
              stepNumber={stepNumber}
              description={description}
            />
          ))}
        </Slider>
      </div>
    </div>
  );
};

export default HowItWorks;
