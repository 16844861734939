import React, { useState, useEffect } from 'react';
import Page from '../../layout/Page/Page';
import BannerBlock from './components/BannerBlock';
import BrandsSlider from './components/BrandSlider';
import DescriptionBlock from './components/DescriptionBlock';
import HowItWorks from './components/HowItWorks';
import InformationBlock from './components/InformationBlock';
import CategorySearch from './components/CategorySearch';
import FeaturedBlock from './components/FeaturedBlock';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import styles from './sass/Home.module.scss';

const Home = () => {
  const [scrollPosition, setScrollPosition] = useState(0);
  const [headerTransparent, setHeaderTransparent] = useState(true);

  const handleScroll = () => {
    const position = window.scrollY;
    setScrollPosition(position);
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll, { passive: true });

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  useEffect(() => {
    if (scrollPosition > 5 || window.innerWidth < 768) {
      setHeaderTransparent(false);
    } else {
      setHeaderTransparent(true);
    }
  }, [scrollPosition]);

  return (
    <Page headerStyles="headerFixed" headerTransparent={headerTransparent} pageContainerStyle={styles.container}>
      <BannerBlock />
      <BrandsSlider />
      <DescriptionBlock />
      <HowItWorks />
      <InformationBlock />
      <CategorySearch />
      <FeaturedBlock />
    </Page>
  );
};

export default Home;
