import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from 'axios';
import { clearFacebookToken } from '../facebook/slice';
import notify from '../../utils/notify';

export const initialState = {
  error: null,
  isLoading: false,
  data: null,
  paging: null,
};

export const getFacebookImages = createAsyncThunk(
  'facebookImages/getImages',
  async ({ token, isNext }, { rejectWithValue, getState, dispatch }) => {
    try {
      const { facebook, facebookImages } = getState();
      const facebookToken = token || facebook.data;
      const url = isNext ? facebookImages.paging.next : 'https://graph.facebook.com/v15.0/me/photos/uploaded';

      const { data } = await axios.get(url, {
        params: isNext ? {} : {
          fields: 'images,alt_text',
          access_token: facebookToken?.accessToken
        }
      });

      return { ...data, isNext };
    } catch (error) {
      if (error.response.status === 400) {
        dispatch(clearFacebookToken());
      }
      notify('error', 'Something went wrong. Try again');
      return rejectWithValue(error.response.data.error.message || error.message);
    }
  }
);

export const FacebookImagesSlice = createSlice({
  name: 'facebookImages',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getFacebookImages.pending, (state) => ({
        ...state,
        isLoading: true
      }))
      .addCase(getFacebookImages.fulfilled, (state, action) => {
        state.isLoading = false;
        state.data = action.payload.isNext && state.data?.length
          ? [...state.data, ...action.payload.data] : action.payload.data;
        state.paging = action.payload.paging;
      })
      .addCase(getFacebookImages.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
      });
  },
});

export default FacebookImagesSlice.reducer;
