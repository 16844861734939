import React from 'react';
import PropTypes from 'prop-types';
import { Toaster } from 'react-hot-toast';
import { shallowEqual, useSelector } from 'react-redux';
import notifyStyles from '../../constants/toaster';
import Header from '../Header/Header';
import Footer from '../Footer/Footer';
import Loader from '../../components/Loader/Loader';
import styles from './sass/Page.module.scss';

export default function Page({
  children, pageContainerStyle, headerStyles, headerTransparent, withoutFooter, loader
}) {
  const { tokens } = useSelector(({ auth }) => auth, shallowEqual);

  return (
    <div className={styles.page}>
      <Loader isOpen={loader} />
      <Header transparent={headerTransparent} containerStyles={headerStyles} loggedIn={!!tokens?.accessToken} />
      <div className={`container ${pageContainerStyle}`}>
        {children}
      </div>
      {!withoutFooter && <Footer />}
      <Toaster toastOptions={notifyStyles} />
    </div>
  );
}

Page.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ]).isRequired,
  pageContainerStyle: PropTypes.string,
  headerStyles: PropTypes.string,
  headerTransparent: PropTypes.bool,
  withoutFooter: PropTypes.bool,
  loader: PropTypes.bool
};

Page.defaultProps = {
  pageContainerStyle: '',
  headerStyles: '',
  headerTransparent: false,
  withoutFooter: false,
  loader: false
};
