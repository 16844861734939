import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import instance from '../../services/axios';
import { VERIFY_EMAIL_API } from '../../constants/api';
import { setTokens } from '../auth/slice';
import { setId } from '../profile/slice';
import notify from '../../utils/notify';

export const initialState = {
  error: null,
  isLoading: false,
  data: true,
};

export const VerifyEmailThunk = createAsyncThunk(
  'verifyEmail/VerifyEmailThunk',
  async ({
    token, navigate
  }, { rejectWithValue, dispatch }) => {
    try {
      const result = await instance.post(VERIFY_EMAIL_API, { token }, { withoutAuth: true });
      const {
        token_data: { refresh_token: refreshToken, access_token: accessToken },
        user_data: { id }
      } = result.data;

      localStorage.setItem('accessToken', accessToken);
      localStorage.setItem('refreshToken', refreshToken);
      localStorage.setItem('id', id);

      dispatch(setTokens({ accessToken, refreshToken }));
      dispatch(setId(id));
      navigate('/');

      return result.data;
    } catch (error) {
      notify('error', 'Invalid token');
      return rejectWithValue(error.message);
    }
  }
);

export const VerifyEmailSlice = createSlice({
  name: 'verifyEmail',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(VerifyEmailThunk.pending, (state) => ({
        ...state,
        isLoading: true
      }))
      .addCase(VerifyEmailThunk.fulfilled, (state, action) => {
        state.isLoading = false;
        state.data = action.payload;
      })
      .addCase(VerifyEmailThunk.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
      });
  },
});

export default VerifyEmailSlice.reducer;
