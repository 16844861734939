import { useTranslation } from 'react-i18next';
import useLogout from '../../../hooks/useLogout';
import Button from '../../../components/Button/Button';
import styles from '../sass/Header.module.scss';

import SignOutIcon from '../../../assets/icons/signOutCirclePink.svg';

const UserButtons = () => {
  const { t } = useTranslation('', { keyPrefix: 'header' });
  const logout = useLogout();

  return (
    <div className={styles.buttonsContainer}>
      <div className={styles.linkButton}>
        <Button
          text={t('logout')}
          iconLeft={SignOutIcon}
          buttonStyle={styles.logInButton}
          onClick={logout}
        />
      </div>
    </div>
  );
};

export default UserButtons;
