import React from 'react';
import PropTypes from 'prop-types';
import ButtonList from '../ButtonList/ButtonList';
import styles from './sass/ShopBrandFilter.module.scss';

const ShopBrandFilter = ({
  onClick, categories, activeFilters
}) => {
  return (
    <div className={styles.brandFilterContainer}>
      <ButtonList
        data={categories}
        activeButtons={activeFilters}
        onClick={onClick}
      />
    </div>
  );
};

ShopBrandFilter.propTypes = {
  onClick: PropTypes.func.isRequired,
  categories: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  activeFilters: PropTypes.arrayOf(PropTypes.string).isRequired,
};

export default ShopBrandFilter;
