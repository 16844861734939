import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { ReceivedGiftoonThunk } from '../../store/receivedGiftoon/slice';
import { CouponThunk } from '../../store/coupon/slice';
import { SHOP_BRANDS } from '../../constants/links';
import ErrorChecker from '../../hoc/ErrorChecker/ErrorChecker';
import Page from '../../layout/Page/Page';
import ReceivedGiftoonItem from '../../components/ReceivedGiftoonItem/ReceivedGiftoonItem';
import ButtonLink from '../../components/ButtonLink/ButtonLink';
import CouponModal from '../../components/CouponModal/CouponModal';
import styles from './sass/ReceivedGiftoon.module.scss';

import PlayIcon from '../../assets/icons/playVideoTransparent.svg';

const ReceivedGiftoon = () => {
  const { t } = useTranslation('', { keyPrefix: 'receivedGiftoon' });
  const dispatch = useDispatch();
  const { hash } = useParams();

  const [modalIsOpened, setModalIsOpened] = useState(false);

  const {
    receivedGiftoon: {
      isLoading: isLoadingGiftoon,
      data: {
        giftoon,
        brand,
        giveaway,
        brand_price: brandPrice,
        created_at: createdAt,
        email,
        phone,
        order_data: orderData
      },
      error
    },
    coupon: {
      isLoading: isLoadingCoupon,
      data: couponData
    }
  } = useSelector((state) => ({ receivedGiftoon: state?.receivedGiftoon, coupon: state?.coupon }));

  const handleUseCoupon = () => {
    dispatch(CouponThunk({ id: orderData?.CustomerOrderId, brandName: brand?.product_name }));
    setModalIsOpened(true);
  };

  const withGiftoon = useMemo(() => !!giveaway?.title, [giveaway]);

  useEffect(() => {
    dispatch(ReceivedGiftoonThunk({ hash }));
  }, []);

  return (
    <ErrorChecker error={error}>
      <Page loader={isLoadingGiftoon && !isLoadingCoupon} withoutFooter>
        <section className={styles.section}>
          <CouponModal
            onClose={() => setModalIsOpened(false)}
            couponModalOpened={modalIsOpened && !isLoadingCoupon}
            coupon={couponData}
          />
          <h1 className={styles.heading}>{t('receivedGiftoon')}</h1>
          {!isLoadingGiftoon && (
            <div className={styles.couponWrapper}>
              <ReceivedGiftoonItem
                withGiftoon={withGiftoon}
                customPlayIcon={PlayIcon}
                couponStyles={styles.coupon}
                giftoonVideo={giftoon}
                brandImageUrl={brand?.high_resolution_image || brand?.image_url}
                previewImageUrl={giveaway?.preview_image}
                brandName={brand?.brand_name}
                brandPrice={brandPrice}
                createdAt={createdAt}
                email={email}
                phone={phone}
                onClickUseCoupon={handleUseCoupon}
              />
            </div>
          )}
          <ButtonLink buttonStyle={styles.button} text={t('sayThankYou')} link={SHOP_BRANDS} />
        </section>
      </Page>
    </ErrorChecker>
  );
};

export default ReceivedGiftoon;
