import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import InputMask from 'comigo-tech-react-input-mask';
import styles from './sass/MaskInput.module.scss';

const MaskInput = ({
  type,
  mask,
  maskPlaceholder,
  placeholder,
  autocomplete,
  inputStyle,
  focusedStyle,
  containerStyle,
  iconLeft,
  iconRight,
  onClick,
  onChange,
  onBlur,
  onRightIconClick,
  onLeftIconClick,
  value,
  name,
  label,
  error,
  isDisabled,
}) => {
  const [focused, setFocused] = useState(false);

  const handleBlur = useCallback((e) => {
    onBlur(e);
    setFocused(false);
  }, [onBlur]);

  return (
    <label className={styles.label} htmlFor={name}>
      {label}
      <div
        onClick={onClick}
        role="presentation"
        className={`
          ${styles.inputField}
          ${error && styles.error}
          ${(focused && !error) ? styles.focused : ''}
          ${(focusedStyle && !error) ? focusedStyle : ''}
          ${containerStyle}
        `}
      >
        {iconLeft && (
          <img
            className={styles.iconLeft}
            src={iconLeft}
            onClick={onLeftIconClick}
            alt="icon"
            role="presentation"
          />
        )}
        <InputMask
          type={type}
          autoComplete={autocomplete}
          disabled={isDisabled}
          mask={mask}
          className={`
            ${styles.inputText} 
            ${iconRight ? '' : styles.disableMarginRight} 
            ${iconLeft ? '' : styles.disableMarginLeft}
            ${inputStyle} 
          `}
          placeholder={placeholder}
          maskPlaceholder={maskPlaceholder}
          onChange={onChange}
          value={value}
          id={name}
          name={name}
          onBlur={(e) => handleBlur(e)}
          onFocus={() => setFocused(true)}
        />
        {iconRight && (
          <img
            className={styles.iconRight}
            src={iconRight}
            onClick={onRightIconClick}
            alt="icon"
            role="presentation"
          />
        )}
      </div>
      {error && <span className={styles.errorMessage}>{error}</span>}
    </label>
  );
};

MaskInput.propTypes = {
  type: PropTypes.string,
  mask: PropTypes.string.isRequired,
  maskPlaceholder: PropTypes.string,
  placeholder: PropTypes.string,
  autocomplete: PropTypes.string,
  inputStyle: PropTypes.string,
  focusedStyle: PropTypes.string,
  containerStyle: PropTypes.string,
  iconLeft: PropTypes.string,
  iconRight: PropTypes.string,
  value: PropTypes.string.isRequired,
  name: PropTypes.string,
  label: PropTypes.string,
  error: PropTypes.string,
  onClick: PropTypes.func,
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  onRightIconClick: PropTypes.func,
  onLeftIconClick: PropTypes.func,
  isDisabled: PropTypes.bool
};

MaskInput.defaultProps = {
  type: 'text',
  maskPlaceholder: 'text',
  placeholder: '',
  autocomplete: 'on',
  inputStyle: '',
  focusedStyle: '',
  containerStyle: '',
  name: '',
  label: '',
  error: '',
  iconLeft: null,
  iconRight: null,
  onClick: () => {},
  onChange: () => {},
  onBlur: () => {},
  onRightIconClick: () => {},
  onLeftIconClick: () => {},
  isDisabled: false
};

export default MaskInput;
