import React, { useCallback, useEffect, useState } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import Slider from 'react-slick';
import { SHOP_BRANDS } from '../../../constants/links';
import { HomeBrandsThunk } from '../../../store/homeBrands/slice';
import BrandSliderItem from './BrandSliderItem';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import styles from '../sass/BrandsSlider.module.scss';

const BrandSlider = () => {
  const { t } = useTranslation('', { keyPrefix: 'home' });
  const dispatch = useDispatch();
  const { brandList } = useSelector(({ homeBrands }) => (homeBrands.data), shallowEqual);
  const [linksDisabled, setLinksDisabled] = useState(false);

  const handleMouseDown = useCallback(() => {
    setTimeout(() => {
      setLinksDisabled(true);
    }, 100);
  }, [setLinksDisabled]);

  const handleMouseUp = useCallback(() => {
    setTimeout(() => {
      setLinksDisabled(false);
    }, 100);
  }, [setLinksDisabled]);

  useEffect(() => {
    dispatch(HomeBrandsThunk({}));
  }, []);

  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 2,
    slidesToScroll: 1,
    swipeToSlide: true,
    centerMode: true,
    centerPadding: '23%',
    arrows: false,

    responsive: [
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 1
        }
      }
    ]
  };

  return (
    <div className={styles.section}>
      <h2>{t('browseGiftCards')}</h2>
      <div role="presentation" onMouseDown={handleMouseDown} onMouseUp={handleMouseUp} className={styles.slider}>
        <Slider {...settings}>
          {Array.isArray(brandList) && brandList.map(({
            id,
            product_name: brandName,
            price_low: priceLow,
            price_high: priceHigh,
            currency,
            image_url: imageUrl
          }) => (
            <BrandSliderItem
              key={id}
              heading={brandName}
              currency={currency}
              priceLow={priceLow}
              priceHigh={priceHigh}
              image={imageUrl}
              link={linksDisabled ? '' : SHOP_BRANDS}
            />
          ))}
        </Slider>
      </div>
    </div>
  );
};

BrandSlider.propTypes = {};

export default BrandSlider;
