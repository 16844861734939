import React, { useEffect } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import InfiniteScroll from 'react-infinite-scroll-component';
import { clearSentGiftoons, SentGiftoonsThunk } from '../../store/sentGiftoons/slice';
import Loader from '../../components/Loader/Loader';
import SentGiftoonsHeading from './components/SentGiftoonsHeading';
import SentGiftoonsInfoRow from './components/SentGiftoonsInfoRow';
import styles from './sass/ProfileSentGiftoons.module.scss';

import GiftoonSmileIcon from '../../assets/icons/giftoonSmile.svg';

const ProfileSentGiftoons = () => {
  const dispatch = useDispatch();
  const {
    sentGiftoons: {
      isLoading,
      data: giftoons,
      next
    },
  } = useSelector((state) => ({ sentGiftoons: state?.sentGiftoons }), shallowEqual);

  const getNextGiftoons = () => {
    dispatch(SentGiftoonsThunk({ next }));
  };

  useEffect(() => {
    getNextGiftoons();
    return () => dispatch(clearSentGiftoons());
  }, []);

  return (
    <section className={styles.section}>
      <Loader isOpen={isLoading} />
      <div className={styles.container}>
        <SentGiftoonsHeading />
        <InfiniteScroll
          next={getNextGiftoons}
          hasMore={next}
          loader={<img className={styles.giftoonSmile} src={GiftoonSmileIcon} alt="giftoon" />}
          dataLength={giftoons.length}
        >
          {giftoons.map(
            ({
              id,
              giveaway: { title, preview_image: previewImage },
              brand: { product_name: productName, image_url: brandImage },
              receiver_first_name: receiverName,
              email,
              phone,
              send_at: sendDate,
              was_send_at: wasSentAt,
              brand_price: brandPrice
            }) => (
              <SentGiftoonsInfoRow
                key={id}
                giftoonTitle={title}
                brandTitle={productName}
                giftoonImage={previewImage}
                brandImage={brandImage}
                receiverName={receiverName}
                email={email}
                phone={phone}
                sendDate={wasSentAt || sendDate}
                brandPrice={brandPrice}
              />
            )
          )}
        </InfiniteScroll>
      </div>
    </section>
  );
};

export default ProfileSentGiftoons;
