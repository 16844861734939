import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import instance from '../../services/axios';
import { GET_CARD_API } from '../../constants/api';

export const initialState = {
  error: null,
  isLoading: false,
  data: {
    id: null,
    imageUrl: null,
    cardNumber: null,
    pin: null,
    balance: null,
    barcodeUrl: null,
    certificateUrl: null,
  }
};

export const CouponThunk = createAsyncThunk(
  'coupon/couponThunk',
  async ({ id, brandName }, { rejectWithValue }) => {
    try {
      const { data } = await instance.get(`${GET_CARD_API}${id}/`);
      return { brandName, ...data[0] };
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const CouponSlice = createSlice({
  name: 'coupon',
  initialState,
  reducers: {
    clearCoupon: () => (initialState)
  },
  extraReducers: (builder) => {
    builder
      .addCase(CouponThunk.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(CouponThunk.fulfilled, (state, action) => {
        state.isLoading = false;
        state.data.id = action.payload?.id;
        state.data.imageUrl = action.payload?.image_url;
        state.data.pin = action.payload?.pin;
        state.data.balance = action.payload?.balance;
        state.data.barcodeUrl = action.payload?.barcode_url;
        state.data.cardNumber = action.payload?.card_number;
        state.data.brandName = action.payload?.brandName;
        state.data.certificateUrl = action.payload?.sertificate_url;
      })
      .addCase(CouponThunk.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
      });
  }
});

export const { clearCoupon } = CouponSlice.actions;
export default CouponSlice.reducer;
