import { createAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { USERS_API } from '../../constants/api';
import instance from '../../services/axios';
import notify from '../../utils/notify';

export const initialState = {
  error: null,
  isLoading: false,
  data: {
    id: localStorage.getItem('id') || undefined,
    username: '',
    fullName: '',
    email: '',
    cardNumber: '',
    cardDate: ''
  },
};

export const setId = createAction('auth/setId');

export const ProfileGetUserDataThunk = createAsyncThunk(
  'profile/profileGetUserDataThunk',
  async (
    { id },
    { rejectWithValue }
  ) => {
    try {
      const result = await instance.get(`${USERS_API}${id}/`, { id });
      return result.data;
    } catch ({ response: { data } }) {
      notify('error', Object.values(data)[0]);
      return rejectWithValue(data);
    }
  }
);

export const ProfileUpdateUserDataThunk = createAsyncThunk(
  'profile/profileUpdateUserDataThunk',
  async (
    { fullName, email, id },
    { rejectWithValue }
  ) => {
    const values = { email, full_name: fullName };
    try {
      const result = await instance.patch(`${USERS_API}${id}/`, values);
      notify('success', 'Your info has been changed successfully!');
      return result.data;
    } catch ({ response: { data } }) {
      notify('error', Object.values(data)[0]);
      return rejectWithValue(data);
    }
  }
);

export const ProfileChangePasswordThunk = createAsyncThunk(
  'profile/profileChangePasswordThunk',
  async (
    {
      id, currentPassword, newPassword, confirmNewPassword
    },
    { rejectWithValue }
  ) => {
    const values = { password: currentPassword, new_password: newPassword, new_password_confirm: confirmNewPassword };
    try {
      const result = await instance.patch(`${USERS_API}${id}/`, values);
      notify('success', 'Your password has been changed successfully!');
      return result.data;
    } catch ({ response: { data } }) {
      notify('error', Object.values(data)[0]);
      return rejectWithValue(data);
    }
  }
);

export const ProfileSlice = createSlice({
  name: 'profile',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(setId, (state, action) => {
        state.data.id = action.payload;
      })

      .addCase(ProfileGetUserDataThunk.pending, (state) => ({
        ...state,
        isLoading: true
      }))
      .addCase(ProfileGetUserDataThunk.fulfilled, (state, action) => {
        state.isLoading = false;
        state.data.username = action.payload.username;
        state.data.fullName = action.payload?.additional_data?.full_name;
        state.data.email = action.payload.email;
      })
      .addCase(ProfileGetUserDataThunk.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
      })

      .addCase(ProfileUpdateUserDataThunk.pending, (state) => ({
        ...state,
        isLoading: true
      }))
      .addCase(ProfileUpdateUserDataThunk.fulfilled, (state, action) => {
        state.isLoading = false;
        state.data.fullName = action.payload?.additional_data?.full_name;
        state.data.email = action.payload.email;
      })
      .addCase(ProfileUpdateUserDataThunk.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
      })

      .addCase(ProfileChangePasswordThunk.pending, (state) => ({
        ...state,
        isLoading: true
      }))
      .addCase(ProfileChangePasswordThunk.fulfilled, (state, action) => {
        state.isLoading = false;
        state.data = action.payload;
      })
      .addCase(ProfileChangePasswordThunk.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
      });
  },
});

export default ProfileSlice.reducer;
