import React, {
  useCallback, useEffect, useMemo, useState
} from 'react';
import PropTypes from 'prop-types';
import { useNavigate, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { clearStepSelectedGiveaway, StepSelectedGiveawayThunk } from '../../store/stepSelectedGiveaway/slice';
import { setGiveawayInfo } from '../../store/giftFlow/slice';
import { STEP_ADD_BRAND_LIST, STEP_ADD_GIFTOON_LIST, STEP_SELECT_PHOTO } from '../../constants/links';
import { clearGiftReceiver } from '../../store/giftReceiver/slice';
import secondsToTimeString from '../../helpers/secondsToTimeString';
import SendingPage from '../../layout/SendingPage/SendingPage';
import Button from '../../components/Button/Button';
import ButtonLink from '../../components/ButtonLink/ButtonLink';
import VideoPlayer from '../../components/VideoPlayer/VideoPlayer';
import styles from './sass/StepSelectGiftoon.module.scss';

import ArrowLeftIcon from '../../assets/icons/arrowLeftPink.svg';
import ArrowRightIcon from '../../assets/icons/arrowRightWhite.svg';
import ClockIcon from '../../assets/icons/clockSmall.svg';

const StepSelectedGiveaway = ({ stepNumber }) => {
  const { t } = useTranslation('', { keyPrefix: 'selectGiftoon' });
  const { id } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [videoLoaded, setVideoLoaded] = useState(false);
  const {
    data: {
      video, title, description, videoLength, persons
    }, isLoading
  } = useSelector(({ stepSelectedGiveaway }) => stepSelectedGiveaway);

  const isFirstStep = useMemo(() => (stepNumber === 1), [stepNumber]);

  const handleNextStep = useCallback((link) => {
    dispatch(setGiveawayInfo({
      giveawayId: id, giveawayName: title, clearPrevData: isFirstStep
    }));
    if (isFirstStep) dispatch(clearGiftReceiver());
    navigate(link);
  }, [id, title, isFirstStep]);

  const buttonLeft = useMemo(() => (
    !isFirstStep && (
      <ButtonLink
        link={STEP_ADD_GIFTOON_LIST}
        buttonStyle={styles.footerButton}
        text={t('back')}
        iconLeft={ArrowLeftIcon}
      />
    )
  ), []);

  const buttonRight = useMemo(() => {
    const link = isFirstStep ? STEP_ADD_BRAND_LIST : STEP_SELECT_PHOTO;
    const text = isFirstStep ? t('selectBrand') : t('nextStep');
    return (
      <Button
        buttonStyle={styles.footerButton}
        text={text}
        iconRight={ArrowRightIcon}
        isFilled
        onClick={() => handleNextStep(link)}
      />
    );
  }, [title]);

  const dataString = useMemo(() => (
    `${persons} - ${t(persons > 1 ? 'persons' : 'person')}, ${secondsToTimeString(videoLength)} ${t('sec')}.  `
  ), [persons, videoLength, t]);

  useEffect(() => {
    dispatch(StepSelectedGiveawayThunk({ id }));
    return () => dispatch(clearStepSelectedGiveaway());
  }, []);

  return (
    <SendingPage
      stepNumber={stepNumber}
      stepName={t('selectGiftoon')}
      buttonLeft={buttonLeft}
      buttonRight={buttonRight}
      loader={isLoading || !videoLoaded}
    >
      {!isLoading && video && (
        <section className={styles.section}>
          <VideoPlayer
            videoContainerStyles={styles.videoContainer}
            video={video}
            onLoadedData={() => setVideoLoaded(true)}
          />
          <div className={styles.textContainer}>
            <h2>{title}</h2>
            <p>{description}</p>
            <div className={styles.time}>
              <img src={ClockIcon} alt={t('clock')} />
              <span>{dataString}</span>
            </div>
          </div>
        </section>
      )}
    </SendingPage>
  );
};

StepSelectedGiveaway.propTypes = {
  stepNumber: PropTypes.number.isRequired
};

export default StepSelectedGiveaway;
