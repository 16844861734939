import React from 'react';
import PropTypes from 'prop-types';
import { DateTime } from 'luxon';
import { useTranslation } from 'react-i18next';
import styles from '../sass/SendAtText.module.scss';

const SendAtText = ({ sendAt }) => {
  const { t } = useTranslation('', { keyPrefix: 'purchaseCompleted' });

  return (
    <div className={styles.sendAtText}>
      <div className={styles.title}><span>{t('sendAt')}</span></div>
      <span className={styles.date}>{DateTime.fromISO(sendAt).toFormat("'on' dd MMMM, yyyy 'at' HH:mm")}</span>
    </div>
  );
};

SendAtText.propTypes = {
  sendAt: PropTypes.instanceOf(Date).isRequired
};

export default SendAtText;
