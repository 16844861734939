import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import styles from '../sass/PromoCodeLine.module.scss';
import Button from '../../../components/Button/Button';
import Input from '../../../components/Input/Input';

const PromoCodeLine = ({
  text, handleEvent, promoCodePercentage, promoCodeError, handleResetError,
}) => {
  const [showPromoInput, setShowPromoInput] = useState(false);
  const [promo, setPromo] = useState('');
  const { t } = useTranslation('', { keyPrefix: 'checkout' });

  return showPromoInput
    ? (
      <>
        <div className={`${styles.enterPromoLine} ${promoCodePercentage !== null ? styles.applied : ''}`}>
          <Input
            value={promo}
            name="promo"
            placeholder={t('enterPromoCode')}
            onChange={(e) => {
              setPromo(e.target.value);
              handleResetError();
            }}
            inputStyle={styles.input}
          />
          <Button
            onClick={() => {
              if (promoCodePercentage !== null) {
                setShowPromoInput(false);
                setPromo('');
              }
              handleEvent(promo);
            }}
            buttonStyle={styles.applyButton}
            isFilled
            text={
              promoCodePercentage !== null ? t('reset') : t('apply')
            }
          />
        </div>
        {promoCodeError
          && (
          <div className={styles.promoError}>
            {promoCodeError}
            <Button
              buttonStyle={styles.resetLink}
              text={t('reset')}
              onClick={() => {
                setShowPromoInput(false);
                setPromo('');
                handleResetError();
              }}
            />
          </div>
          )}
      </>

    )
    : (
      <Button text={text} buttonStyle={styles.promoLine} onClick={() => setShowPromoInput(true)} />
    );
};

PromoCodeLine.propTypes = {
  text: PropTypes.string.isRequired,
  handleEvent: PropTypes.func,
  handleResetError: PropTypes.func,
  promoCodePercentage: PropTypes.number,
  promoCodeError: PropTypes.string,
};

PromoCodeLine.defaultProps = {
  handleEvent: () => {},
  promoCodePercentage: null,
  promoCodeError: '',
  handleResetError: () => {},
};

export default PromoCodeLine;
