import React from 'react';
import PropTypes from 'prop-types';
import styles from './sass/Checkbox.module.scss';

import CheckIcon from '../../assets/icons/checkWhite.svg';

const Checkbox = ({
  name, isChecked, onChange, label
}) => {
  return (
    <label htmlFor={name} className={`${styles.checkbox} ${isChecked && styles.active}`}>
      <img className={`${isChecked || styles.imageDisabled}`} src={CheckIcon} alt="check" />
      <input
        type="checkbox"
        id={name}
        name={name}
        checked={isChecked}
        onChange={onChange}
      />
      <span>{label}</span>
    </label>
  );
};
Checkbox.propTypes = {
  isChecked: PropTypes.bool,
  onChange: PropTypes.func,
  name: PropTypes.string,
  label: PropTypes.string,
};
Checkbox.defaultProps = {
  isChecked: false,
  onChange: () => {},
  name: '',
  label: '',
};
export default Checkbox;
