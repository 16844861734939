import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import {
  REDIRECT, SIGN_IN, SIGN_UP, STEP_SELECT_PHOTO 
} from '../../../constants/links';
import Modal from '../../../components/Modal/Modal';
import ButtonLink from '../../../components/ButtonLink/ButtonLink';
import Button from '../../../components/Button/Button';
import styles from '../sass/WithoutAuthModal.module.scss';

import CloseButtonIcon from '../../../assets/icons/closeButton.svg';
import EnterIcon from '../../../assets/icons/signInCirclePink.svg';
import ArrowIcon from '../../../assets/icons/arrowRightPink.svg';
import Smile from '../../../assets/images/upsideDownSmile.jpg';
import Smile2x from '../../../assets/images/upsideDownSmile2x.jpg';

const WithoutAuthModal = ({ closeModal, onContinueAsGuest, isOpen }) => {
  const { t } = useTranslation('', { keyPrefix: 'selectPhoto' });

  return (
    <Modal onClose={closeModal} isOpen={isOpen}>
      <div className={styles.modal}>
        <Button buttonStyle={styles.closeButton} iconLeft={CloseButtonIcon} onClick={closeModal} />
        <div className={styles.textBlock}>
          <img className={styles.smile} src={Smile} srcSet={`${Smile2x} 2x`} alt="giftoon" />
          <h1>{t('beforeYouContinue')}</h1>
          <p>{t('pleaseSignIn')}</p>
        </div>
        <div className={styles.buttonContainer}>
          <ButtonLink
            link={SIGN_UP}
            buttonStyle={styles.gradientButton}
            text={t('signUp')}
            isFilled
          />
        </div>
        <div className={styles.buttonContainer}>
          <ButtonLink
            link={`${SIGN_IN}${REDIRECT}${STEP_SELECT_PHOTO}`}
            buttonStyle={styles.pinkButton}
            text={t('signIn')}
            iconLeft={EnterIcon}
          />
        </div>
        <div className={styles.buttonContainer}>
          <Button
            onClick={onContinueAsGuest}
            buttonStyle={styles.pinkButton}
            text={t('continueAsGuest')}
            iconRight={ArrowIcon}
            isFilled
          />
        </div>
      </div>
    </Modal>
  );
};

WithoutAuthModal.propTypes = {
  closeModal: PropTypes.func.isRequired,
  onContinueAsGuest: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired
};

export default WithoutAuthModal;
