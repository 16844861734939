import { useEffect } from 'react';

const useTimeoutCallback = (callback, timeout = 1000) => {
  useEffect(() => {
    setTimeout(() => {
      callback();
    }, timeout);
  }, [callback, timeout]);
};

export default useTimeoutCallback;
