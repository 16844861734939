import React from 'react';
import PropTypes from 'prop-types';
import styles from './sass/RadioButton.module.scss';

import CheckIcon from '../../assets/icons/checkWhite.svg';

const RadioButton = ({
  name, label, onChange, isChecked, value, disabled,
}) => {
  return (
    <label htmlFor={label} className={`${styles.radioButton} ${isChecked && styles.active}`}>
      {isChecked && <img src={CheckIcon} alt="check" />}
      <input
        value={value}
        id={label}
        name={name}
        type="radio"
        onChange={onChange}
        disabled={disabled}
      />
      <span>{label}</span>
    </label>
  );
};

RadioButton.propTypes = {
  name: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  label: PropTypes.string,
  onChange: PropTypes.func,
  isChecked: PropTypes.bool,
  disabled: PropTypes.bool,
};

RadioButton.defaultProps = {
  label: '',
  onChange: () => {},
  isChecked: false,
  disabled: false,
};

export default RadioButton;
