import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { DateTime } from 'luxon';
import instance from '../../services/axios';
import { GIFTOONS_API, GIFTOONS_SEND_API } from '../../constants/api';
import { CHECKOUT } from '../../constants/links';
import { setReceiver } from '../giftReceiver/slice';
import { FLOW_TYPES } from '../../constants/constants';
import notify from '../../utils/notify';

const initialState = {
  savedData: {
    brandId: null,
    brandName: null,
    giveawayId: null,
    giveawayName: null,
    price: null,
    tcnActivationFee: null,
    imageId: null,
    faceId: null,
    flowType: null,
    buyJustCoupon: false,
  },
  data: {},
  error: null,
  isLoading: false,
};

export const processOnlyCoupon = createAsyncThunk(
  'giftFlow/processOnlyCoupon',
  async ({ brandId, price, navigate }, { rejectWithValue }) => {
    try {
      const { data } = await instance.post(GIFTOONS_API, { brand: brandId, brand_price: price });
      navigate();
      return data;
    } catch ({ response: { data, status } }) {
      return rejectWithValue({ data, code: status });
    }
  }
);

export const processGiftFlowAsync = createAsyncThunk(
  'giftFlow/process',
  async (newData, { getState, rejectWithValue }) => {
    const { savedData, data: { is_in_queue: isInQueue, id: giftoonId } } = getState().giftFlow;
    const {
      brandId, giveawayId, price, faceId, navigate
    } = { ...savedData, ...newData };
    try {
      const requestUrl = isInQueue ? `${GIFTOONS_API}${giftoonId}/video/` : GIFTOONS_API;

      const { data } = await instance.post(requestUrl, {
        brand_price: price, photo: faceId, giveaway: giveawayId, brand: brandId
      });
      if (data.is_in_queue) {
        notify('error', 'Ops! It\'s a traffic jam. Please, try later.');
      } else {
        navigate();
      }
      return data;
    } catch ({ response: { data, status } }) {
      return rejectWithValue({ data, code: status });
    }
  }
);

export const sendGiftFlowAsync = createAsyncThunk('giftFlow/send', async ({
  receiverFirstName, receiverLastName, email, sendAt, phone, senderName, reportsEmail, toWhom, message, navigate
}, { rejectWithValue, getState, dispatch }) => {
  dispatch(setReceiver({
    firstName: receiverFirstName,
    lastName: receiverLastName,
    email,
    sendAt,
    phone,
    senderName,
    reportsEmail,
    toWhom,
    message,
  }));
  const { giftFlow: { data: { id } } } = getState();

  const newDate = JSON.parse(sendAt)
    ? DateTime.fromISO(JSON.parse(sendAt)).toLocal().toString()
    : null;

  try {
    const { data } = await instance.post(`${GIFTOONS_SEND_API}${id}/`, {
      receiver_first_name: receiverFirstName,
      receiver_last_name: receiverLastName,
      email,
      send_at: newDate,
      phone,
      sender_name: senderName,
      reports_email: reportsEmail,
      message,
    });
    navigate(CHECKOUT);
    return data;
  } catch ({ response: { data, status } }) {
    return rejectWithValue({ data, code: status });
  }
});

export const GiftFlowSlice = createSlice({
  name: 'giftFlow',
  initialState,
  reducers: {
    clearGiftFlow: () => initialState,
    disableFlowLoading: (state) => {
      state.isLoading = false;
    },
    setBrandInfo: (state, action) => {
      if (action.payload.clearPrevData) {
        state.savedData = { ...initialState.savedData };
        state.data = { ...initialState.data };
        state.savedData.flowType = FLOW_TYPES.BRAND;
      }
      state.savedData.brandId = action.payload.brandId;
      state.savedData.brandName = action.payload.brandName;
      state.savedData.price = action.payload.price;
      state.savedData.tcnActivationFee = action.payload.tcnActivationFee;
      state.savedData.buyJustCoupon = action.payload.buyJustCoupon;
    },
    setGiveawayInfo: (state, action) => {
      if (action.payload.clearPrevData) {
        state.savedData = { ...initialState.savedData };
        state.data = { ...initialState.data };
        state.savedData.flowType = FLOW_TYPES.GIVEAWAY;
      }
      state.savedData.giveawayId = action.payload.giveawayId;
      state.savedData.giveawayName = action.payload.giveawayName;
    },
    setImageId: (state, action) => {
      state.savedData.imageId = action.payload;
      state.savedData.faceId = null;
    },
    setFaceId: (state, action) => {
      state.savedData.faceId = action.payload;
    }
  },
  extraReducers: (builder) => {
    builder
      // process only coupon
      .addCase(processOnlyCoupon.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(processOnlyCoupon.fulfilled, (state, action) => {
        state.isLoading = false;
        state.data = action.payload;
      })
      .addCase(processOnlyCoupon.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
      })

      // process
      .addCase(processGiftFlowAsync.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(processGiftFlowAsync.fulfilled, (state, action) => {
        state.isLoading = false;
        state.data = action.payload;
      })
      .addCase(processGiftFlowAsync.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
      })

      // send
      .addCase(sendGiftFlowAsync.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(sendGiftFlowAsync.fulfilled, (state, action) => {
        state.isLoading = false;
        state.data = action.payload;
      })
      .addCase(sendGiftFlowAsync.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
      });
  }
});

export const {
  clearGiftFlow, disableFlowLoading, setBrandInfo, setGiveawayInfo, setImageId, setFaceId
} = GiftFlowSlice.actions;
export default GiftFlowSlice.reducer;
