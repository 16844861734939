import React from 'react';
import { useTranslation } from 'react-i18next';
import Page from '../../layout/Page/Page';
import styles from './sass/Corporate.module.scss';

import CorporateImage from '../../assets/images/corporate.jpg';

const Corporate = () => {
  const { t } = useTranslation('', { keyPrefix: 'corporate' });
  return (
    <Page>
      <section className={styles.section}>
        <div className={styles.infoContainer}>
          <h1>{t('giftoonForBusiness')}</h1>
          <img src={CorporateImage} alt={t('corporate')} />
          <p>{t('description1')}</p>
          <br />
          <p>{t('description2')}</p>
        </div>
        <div className="klaviyo-form-Y8knMD" />
      </section>
    </Page>
  );
};

export default Corporate;
