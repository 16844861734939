import React from 'react';
import PropTypes from 'prop-types';
import Button from '../../../components/Button/Button';
import styles from '../sass/StepSelectBrand.module.scss';

const BrandPriceButtons = ({ brands, activeBrand, onChoseBrand }) => {
  return (
    <div className={styles.buttonContainer}>
      {brands.map((brand, index) => (
        <Button
          key={`BrandButton_${index.toString()}`}
          buttonStyle={`${styles.customButton} ${brand.id === activeBrand ? styles.activeButton : ''}`}
          textStyle={`${styles.customTextStyle} ${brand.id === activeBrand ? styles.activeTextStyle : ''}`}
          text={`$${brand?.price_low}`}
          onClick={() => onChoseBrand(brand.id, index)}
        />
      ))}
    </div>
  );
};

BrandPriceButtons.propTypes = {
  brands: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  onChoseBrand: PropTypes.func.isRequired,
  activeBrand: PropTypes.number
};

BrandPriceButtons.defaultProps = {
  activeBrand: null
};

export default BrandPriceButtons;
