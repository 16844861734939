import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import ButtonLink from '../../../components/ButtonLink/ButtonLink';
import { RECEIVED_GIFTOON } from '../../../constants/links';
import styles from '../sass/CouponDescription.module.scss';

import ArrowIcon from '../../../assets/icons/arrowRightWhite.svg';

const CouponDescription = ({
  firstLine, secondLine, thirdName, message, hash,
}) => {
  const { t } = useTranslation('', { keyPrefix: 'giftView' });

  return (
    <div className={styles.textContainer}>
      <h1 className={styles.firstLine}>{firstLine}</h1>
      <h2 className={styles.secondLine}>{secondLine}</h2>
      <h2 className={styles.thirdLine}>{thirdName}</h2>
      <p className={styles.message}>{message}</p>
      <ButtonLink
        link={`${RECEIVED_GIFTOON}/${hash}`}
        buttonStyle={styles.getGiftoonButton}
        text={t('claimEGift')}
        iconRight={ArrowIcon}
        isFilled
      />
    </div>
  );
};

CouponDescription.propTypes = {
  firstLine: PropTypes.string.isRequired,
  secondLine: PropTypes.string.isRequired,
  thirdName: PropTypes.string.isRequired,
  message: PropTypes.string.isRequired,
  hash: PropTypes.string.isRequired,
};

export default CouponDescription;
