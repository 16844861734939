import React from 'react';
import PropTypes from 'prop-types';
import VideoPlayer from '../../../components/VideoPlayer/VideoPlayer';
import styles from '../sass/GiftoonPreview.module.scss';

const GiftoonPreview = ({
  brand, giftoon, buyJustCoupon, setVideoLoaded 
}) => {
  return buyJustCoupon ? (
    <img
      className={styles.imagePreview}
      src={brand?.high_resolution_image || brand?.image_url}
      alt={brand?.brand_name}
    />
  ) : (
    <VideoPlayer
      onLoadedData={() => setVideoLoaded(true)}
      videoContainerStyles={styles.videoContainer}
      video={giftoon}
    />
  );
};

GiftoonPreview.propTypes = {
  brand: PropTypes.shape({
    brand_name: PropTypes.string,
    image_url: PropTypes.string,
    high_resolution_image: PropTypes.string,
  }),
  giftoon: PropTypes.string,
  buyJustCoupon: PropTypes.bool.isRequired,
  setVideoLoaded: PropTypes.func.isRequired,
};

GiftoonPreview.defaultProps = {
  brand: null,
  giftoon: null,
};

export default GiftoonPreview;
