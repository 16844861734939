import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { GIFTOON_IMAGE_VERIFY_API } from '../../constants/api';
import instance from '../../services/axios';
import getFileFromUrl from '../../utils/getFileFromUrl';
import notify from '../../utils/notify';

export const initialState = {
  error: null,
  isLoading: false,
  data: null,
};

export const croppingFaceVerify = createAsyncThunk(
  'cropFaceVerify/croppingFaceVerify',
  async ({ image, callback }, { rejectWithValue, getState }) => {
    try {
      const { id } = getState().cropFace.data;
      const formData = new FormData();
      const file = image instanceof File ? image : await getFileFromUrl(image, 'face.png');
      formData.append('face_image', file);
      formData.append('giftoon_image_id', id);
      const { data } = await instance.post(GIFTOON_IMAGE_VERIFY_API, formData);
      if (!data.is_valid) {
        notify('error', 'Face is not detected');
        return null;
      }
      callback?.();
      return data.preview_face;
    } catch (error) {
      notify('error', `${error.message} Something went wrong`);
      return rejectWithValue(error.message);
    }
  }
);

export const CropFaceVerifySlice = createSlice({
  name: 'cropFaceVerify',
  initialState,
  reducers: {
    clearCropFaceVerify: () => initialState
  },
  extraReducers: (builder) => {
    builder
      .addCase(croppingFaceVerify.pending, (state) => ({
        ...state,
        isLoading: true
      }))
      .addCase(croppingFaceVerify.fulfilled, (state, action) => {
        state.isLoading = false;
        state.data = action.payload;
      })
      .addCase(croppingFaceVerify.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
      });
  },
});

export const { clearCropFaceVerify } = CropFaceVerifySlice.actions;
export default CropFaceVerifySlice.reducer;
