import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { SHOP_GIVEAWAY_LIMIT } from '../../constants/constants';
import { getGiveawayRequest } from '../../helpers/requests';
import { SHOP_GIVEAWAY_API, SHOP_GIVEAWAY_CATEGORY_API } from '../../constants/api';
import instance from '../../services/axios';

export const initialState = {
  error: null,
  isLoadingGiveaway: false,
  isLoadingCategory: false,
  data: {
    limit: SHOP_GIVEAWAY_LIMIT,
    categories: [],
    giveawayList: [],
    next: `${SHOP_GIVEAWAY_API}?limit=${SHOP_GIVEAWAY_LIMIT}`
  }
};

export const ShopGiveawayThunk = createAsyncThunk(
  'shopGiveaway/shopGiveawayThunk',
  async ({
    url, limit, filter = []
  }, { rejectWithValue, getState }) => {
    try {
      const { shopGiveaway: { data: { giveawayList, categories } } } = getState();

      const data = await getGiveawayRequest(categories, filter, url, limit);

      return { giveawayList: url ? [...giveawayList, ...data.results] : data.results, next: data.next };
    } catch ({ response: { data, status } }) {
      return rejectWithValue({ data, code: status });
    }
  }
);

export const ShopGiveawayCategoriesThunk = createAsyncThunk(
  'shopGiveaway/shopGiveawayCategoriesThunk',
  async ({ rejectWithValue }) => {
    try {
      const result = await instance.get(
        SHOP_GIVEAWAY_CATEGORY_API,
        {},
        { withoutAuth: true }
      );
      return result.data.results;
    } catch ({ response: { data, status } }) {
      return rejectWithValue({ data, code: status });
    }
  }
);

export const GiveawaySlice = createSlice({
  name: 'shopGiveaway',
  initialState,
  extraReducers: (builder) => {
    builder
      .addCase(ShopGiveawayThunk.pending, (state) => ({
        ...state,
        isLoadingGiveaway: true
      }))
      .addCase(ShopGiveawayThunk.fulfilled, (state, action) => ({
        ...state,
        isLoadingGiveaway: false,
        data: {
          ...state.data,
          giveawayList: action.payload.giveawayList,
          next: action.payload.next,
        }
      }))
      .addCase(ShopGiveawayThunk.rejected, (state, action) => {
        state.isLoadingGiveaway = false;
        state.error = action.payload;
      })
      .addCase(ShopGiveawayCategoriesThunk.pending, (state) => ({
        ...state,
        isLoadingCategory: true
      }))
      .addCase(ShopGiveawayCategoriesThunk.fulfilled, (state, action) => {
        state.isLoadingCategory = false;
        state.data.categories = action.payload;
      })
      .addCase(ShopGiveawayCategoriesThunk.rejected, (state, action) => {
        state.isLoadingCategory = false;
        state.error = action.payload;
      });
  }
});

export default GiveawaySlice.reducer;
