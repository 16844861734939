import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import instance from '../../services/axios';
import { SIGN_IN_API } from '../../constants/api';
import { HOMEPAGE } from '../../constants/links';
import { setTokens } from '../auth/slice';
import { setId } from '../profile/slice';
import { setEmail } from '../resendEmail/slice';
import notify from '../../utils/notify';

export const initialState = {
  error: null,
  isLoading: false,
  data: true,
};

export const SignInThunk = createAsyncThunk(
  'signIn/signInThunk',
  async ({
    values: { password, email, keepMeLoggedIn }, navigate, redirectUrl
  }, { rejectWithValue, dispatch }) => {
    dispatch(setEmail(email));
    try {
      const result = await instance.post(SIGN_IN_API, { password, email }, { withoutAuth: true });
      const {
        token_data: { refresh_token: refreshToken, access_token: accessToken },
        user_data: { id }
      } = result.data;

      localStorage.setItem('accessToken', accessToken);
      localStorage.setItem('refreshToken', keepMeLoggedIn ? refreshToken : null);
      localStorage.setItem('id', id);

      dispatch(setTokens({ accessToken, refreshToken: keepMeLoggedIn ? refreshToken : null }));
      dispatch(setId(id));
      navigate(redirectUrl || HOMEPAGE);

      return result.data;
    } catch ({ response: { data, status } }) {
      notify('error', Object.values(data)[0]);
      return rejectWithValue({ data, code: status });
    }
  }
);

export const SignInSlice = createSlice({
  name: 'signIn',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(SignInThunk.pending, (state) => ({
        ...state,
        isLoading: true
      }))
      .addCase(SignInThunk.fulfilled, (state, action) => {
        state.isLoading = false;
        state.data = action.payload;
      })
      .addCase(SignInThunk.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
      });
  },
});

export default SignInSlice.reducer;
