import React from 'react';
import PropTypes from 'prop-types';
import styles from '../sass/GiftoonInfoLine.module.scss';

const GiftoonInfoLine = ({ heading, value }) => {
  return (
    <div className={styles.infoLine}>
      <h3 className={styles.infoHeading}>{heading}</h3>
      <p className={styles.infoDescription}>{value}</p>
    </div>
  );
};

GiftoonInfoLine.propTypes = {
  heading: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
};

export default GiftoonInfoLine;
