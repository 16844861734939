import React from 'react';
import { useTranslation } from 'react-i18next';
import styles from '../sass/SentGiftoonsHeading.module.scss';

const SentGiftoonsHeading = () => {
  const { t } = useTranslation('', { keyPrefix: 'profileSentGiftoons' });
  return (
    <div className={styles.headingsContainer}>
      <div className={styles.itemHeading}>
        <h2>{t('eCards')}</h2>
      </div>
      <div className={styles.itemHeading}>
        <h2>{t('coupons')}</h2>
      </div>
      <div className={styles.recipientHeading}>
        <h2>{t('recipient')}</h2>
      </div>
      <div className={styles.dateHeading}>
        <h2>{t('date')}</h2>
      </div>
    </div>
  );
};

export default SentGiftoonsHeading;
