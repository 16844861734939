import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import instance from '../../services/axios';
import { RESEND_EMAIL_API } from '../../constants/api';
import { RESENT_EMAIL_TIMEOUT } from '../../constants/constants';

export const initialState = {
  isLoading: false,
  data: {
    email: null,
    disabledTimeEnd: null,
  },
  error: null,
};

export const ResendEmailThunk = createAsyncThunk(
  'resendEmail/resendEmailThunk',
  async (_, { rejectWithValue, getState }) => {
    try {
      const { resendEmail: { data: { email } } } = getState();
      const { data } = await instance.post(RESEND_EMAIL_API, { email });
      return data;
    } catch ({ response: { data, status } }) {
      return rejectWithValue({ data, code: status });
    }
  }
);

export const ResendEmailSlice = createSlice({
  name: 'resendEmail',
  initialState,
  reducers: {
    setEmail: (state, action) => {
      state.data.email = action.payload;
    },
    setResendButtonActive: (state) => {
      state.data.disabledTimeEnd = null;
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(ResendEmailThunk.pending, (state) => {
        state.isLoading = true;
        state.data.disabledTimeEnd = new Date().getTime() + RESENT_EMAIL_TIMEOUT;
      })
      .addCase(ResendEmailThunk.fulfilled, (state) => {
        state.isLoading = false;
      })
      .addCase(ResendEmailThunk.rejected, (state) => {
        state.isLoading = false;
      });
  }
});

export const { setEmail, setResendButtonActive } = ResendEmailSlice.actions;
export default ResendEmailSlice.reducer;
