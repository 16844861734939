import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import DatePicker from 'react-datepicker';
import Button from '../../../components/Button/Button';
import 'react-datepicker/dist/react-datepicker.css';
import '../sass/DatePickerModalGlobalStyles.scss';

import styles from '../sass/DatePickerModal.module.scss';

const DatePickerModal = ({
  selectedDate, setDate: setDefaultDate, closeModal,
}) => {
  const { t } = useTranslation('', { keyPrefix: 'sendGiftoon' });
  const [date, setDate] = useState(selectedDate);
  
  return (
    <div className={styles.datePickerContainer}>
      <DatePicker
        selected={date}
        onChange={(newDate) => setDate(newDate)}
        minDate={new Date()}
        inline
      />
      <div className={styles.buttonContainer}>
        <Button onClick={closeModal} buttonStyle={styles.cancelButton} text={t('cancel')} />
        <Button onClick={() => setDefaultDate(date)} buttonStyle={styles.applyButton} text={t('apply')} isFilled />
      </div>
    </div>
  );
};

DatePickerModal.propTypes = {
  selectedDate: PropTypes.instanceOf(Date).isRequired,
  setDate: PropTypes.func.isRequired,
  closeModal: PropTypes.func.isRequired,
};

export default DatePickerModal;
