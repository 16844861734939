import React, {
  useCallback, useEffect, useMemo, useState
} from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { BrandSelectAmountThunk, clearSelectAmount } from '../../store/brandSelectAmount/slice';
import {
  STEP_SEND_GIFTOON, SHOP_BRANDS, STEP_ADD_BRAND_LIST, STEP_ADD_GIFTOON_LIST, STEP_SELECT_PHOTO
} from '../../constants/links';
import { clearGiftReceiver } from '../../store/giftReceiver/slice';
import { processOnlyCoupon, setBrandInfo } from '../../store/giftFlow/slice';
import BrandDescription from './components/BrandDescription';
import ButtonLink from '../../components/ButtonLink/ButtonLink';
import SendingPage from '../../layout/SendingPage/SendingPage';
import Button from '../../components/Button/Button';
import MaskInput from '../../components/MaskInput/MaskInput';
import BrandPriceButtons from './components/BrandPriceButton';
import RetailerModal from './components/RetailerModal';
import BrandTerms from './components/BrandTerms';
import styles from './sass/StepSelectBrand.module.scss';

import ArrowLeftIcon from '../../assets/icons/arrowLeftPink.svg';
import ArrowRightIcon from '../../assets/icons/arrowRightWhite.svg';

const StepSelectBrand = ({ stepNumber }) => {
  const { t } = useTranslation('', { keyPrefix: 'selectBrands' });
  const { id: brandName } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [chosenPrice, setChosenPrice] = useState(null);
  const [error, setError] = useState('');
  const [retailModalOpened, setRetailModalOpened] = useState(false);

  const {
    brandSelectAmount: {
      isLoading: isLoadingBrand,
      data: unsortedBrands
    },
    giftFlow: {
      savedData: { brandId: storeBrandId },
      isLoading: isLoadingGiftFlow
    }
  } = useSelector((state) => ({
    brandSelectAmount: state?.brandSelectAmount,
    giftFlow: state?.giftFlow
  }), shallowEqual);

  const [activeBrand, setActiveBrand] = useState(storeBrandId);

  const brands = useMemo(
    () => [...unsortedBrands].sort((brand1, brand2) => brand1.price_low - brand2.price_low),
    [unsortedBrands]
  );
  const termsConditions = useMemo(() => brands[0]?.termsconditions, [brands]);
  const brandDescription = useMemo(() => brands[0].marketingdescription, [brands]);
  const priceLow = useMemo(() => Math.min(...brands.map((brand) => brand.price_low)), [brands]);
  const priceHigh = useMemo(() => Math.max(...brands.map((brand) => brand.price_high)), [brands]);
  const imageUrl = useMemo(() => brands[0].high_resolution_image || brands[0].image_url, [brands]);
  const tcnActivationFee = useMemo(() => (
    brands.length === 1 || !chosenPrice
      ? brands[0].activation_fee
      : brands.find((brand) => brand.price_low === chosenPrice).activation_fee
  ), [brands, chosenPrice]);
  const expirationText = useMemo(() => brands[0].expiration_date || t('noExpirationDate'), [brands]);
  const displayButtons = useMemo(() => brands.length > 1 || priceLow === priceHigh, [brands]);

  const handleInputChange = (inputValue) => {
    setChosenPrice(inputValue.slice(2));
  };

  const handleClickPriceButton = (brandId, index) => {
    setActiveBrand(brandId);
    setChosenPrice(brands[index].price_low);
  };

  const isFirstStep = useMemo(() => (stepNumber === 1), [stepNumber]);

  const handleNextStep = useCallback((link, buyJustCoupon = false) => {
    if (chosenPrice > priceHigh || chosenPrice < priceLow) {
      setError(t('amountErrorMessage'));
    } else {
      dispatch(setBrandInfo({
        brandId: brands.length > 1
          ? activeBrand
          : brands[0].id,
        brandName,
        price: chosenPrice,
        tcnActivationFee,
        clearPrevData: isFirstStep,
        buyJustCoupon
      }));
      if (isFirstStep) dispatch(clearGiftReceiver());
      if (buyJustCoupon) {
        dispatch(processOnlyCoupon({
          brandId: brands.length > 1 ? activeBrand : brands[0].id,
          price: chosenPrice,
          navigate: () => navigate(STEP_SEND_GIFTOON)
        }));
      } else {
        navigate(link);
      }
    }
  }, [chosenPrice, priceHigh, priceLow, isFirstStep, brandName]);

  const buttonLeft = useMemo(() => (
    <ButtonLink
      buttonStyle={styles.leftButton}
      textStyle={styles.buttonText}
      link={isFirstStep ? SHOP_BRANDS : STEP_ADD_BRAND_LIST}
      text={t('back')}
      iconLeft={ArrowLeftIcon}
    />
  ), []);

  const buttonRight = useMemo(() => {
    const link = isFirstStep ? STEP_ADD_GIFTOON_LIST : STEP_SELECT_PHOTO;
    return (
      <Button
        buttonStyle={styles.rightButton}
        textStyle={styles.buttonText}
        text={t('nextStep')}
        iconRight={ArrowRightIcon}
        isFilled
        onClick={() => handleNextStep(link)}
        isDisabled={!chosenPrice}
      />
    );
  }, [chosenPrice, setChosenPrice]);

  const buttonRightExtra = useMemo(() => (
    isFirstStep && (
    <Button
      buttonStyle={styles.extraButton}
      textStyle={styles.buttonText}
      text={t('sendCoupon')}
      isFilled
      onClick={() => handleNextStep(STEP_SEND_GIFTOON, true)}
      isDisabled={!chosenPrice}
    />
    )
  ), [isFirstStep, chosenPrice, handleNextStep]);

  const inputMask = useMemo(() => (
    `$ ${'9'.repeat(priceHigh?.toString().length)}`
  ), [priceHigh]);

  useEffect(() => {
    dispatch(BrandSelectAmountThunk({ productName: brandName }));
    return () => dispatch(clearSelectAmount());
  }, []);

  useEffect(() => {
    if (storeBrandId && brands && brands.length > 1) {
      const brandPrice = brands.find(({ id }) => id === storeBrandId)?.price_low || null;
      setChosenPrice(brandPrice);
    }
  }, [storeBrandId, brands]);

  return (
    <SendingPage
      stepNumber={stepNumber}
      stepName={t(`${isFirstStep ? 'selectAmount' : 'addCoupon'}`)}
      buttonLeft={buttonLeft}
      buttonRight={buttonRight}
      buttonRightExtra={buttonRightExtra}
      loader={isLoadingBrand || isLoadingGiftFlow}
    >
      {!isLoadingBrand && brandName && (
        <section className={styles.section}>
          <div className={styles.imageContainer}>
            <img className={styles.brandImage} src={imageUrl} alt={t('appleGift')} />
            {termsConditions && <BrandTerms setRetailModalOpened={setRetailModalOpened} />}
          </div>
          <div className={styles.textContainer}>
            <h2 className={styles.brandName}>{brandName}</h2>
            <span className={styles.expirationDate}>{expirationText}</span>
            <p className={styles.cardValueSelection}>{t('selectCardValue')}</p>
            {displayButtons ? (
              <BrandPriceButtons brands={brands} onChoseBrand={handleClickPriceButton} activeBrand={activeBrand} />
            ) : (
              <MaskInput
                containerStyle={styles.maskContainerInput}
                maskPlaceholder=""
                mask={inputMask}
                value={`${chosenPrice}`}
                onChange={(e) => handleInputChange(e.target.value)}
                placeholder={`${t('enterAmount')} ${priceLow} ${t('to')} ${priceHigh}`}
                error={error}
              />
            )}
            { brandDescription && <BrandDescription description={brandDescription} />}
          </div>
        </section>
      )}
      <RetailerModal
        isOpen={retailModalOpened}
        onClose={() => setRetailModalOpened(false)}
        text={termsConditions}
      />
    </SendingPage>
  );
};

StepSelectBrand.propTypes = {
  stepNumber: PropTypes.number.isRequired
};

export default StepSelectBrand;
