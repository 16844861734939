import { createAsyncThunk, createSlice, createAction } from '@reduxjs/toolkit';
import instance from '../../services/axios';
import { FACEBOOK_LOGIN_API } from '../../constants/api';
import { HOMEPAGE } from '../../constants/links';
import notify from '../../utils/notify';
import { setFacebookToken } from '../facebook/slice';
import { setId } from '../profile/slice';

export const initialState = {
  tokens: {
    accessToken: localStorage.getItem('accessToken') || undefined,
    refreshToken: localStorage.getItem('refreshToken') || undefined,
  },
};

export const setTokens = createAction('auth/setTokens');

export const AuthFacebookThunk = createAsyncThunk(
  'auth/authFacebookThunk',
  async ({
    facebook, navigate
  }, { rejectWithValue, dispatch }) => {
    const requestData = {
      token: facebook.accessToken,
      client_id: process.env.REACT_APP_AUTH_CLIENT_ID,
      client_secret: process.env.REACT_APP_AUTH_CLIENT_SECRET,
      grant_type: 'convert_token',
      backend: 'facebook'
    };

    try {
      const result = await instance.post(FACEBOOK_LOGIN_API, requestData, { withoutAuth: true });
      const {
        token_data: { refresh_token: refreshToken, access_token: accessToken },
        user_data: { id }
      } = result.data;

      localStorage.setItem('accessToken', accessToken);
      localStorage.setItem('refreshToken', refreshToken);
      localStorage.setItem('id', id);

      dispatch(setFacebookToken(facebook));
      dispatch(setId(id));
      dispatch(setTokens({ accessToken, refreshToken }));
      navigate(HOMEPAGE);

      return result.data;
    } catch ({ response: { data, status } }) {
      notify('error', Object.values(data)[0]);
      return rejectWithValue({ data, code: status });
    }
  }
);

export const AuthSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    logOut: () => {
      localStorage.removeItem('accessToken');
      localStorage.removeItem('refreshToken');
      return initialState;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(setTokens, (state, action) => {
        state.tokens = action.payload;
      })
      .addCase(AuthFacebookThunk.pending, (state) => ({
        ...state,
        isLoading: true
      }))
      .addCase(AuthFacebookThunk.fulfilled, (state, action) => {
        state.isLoading = false;
        state.data = action.payload;
      })
      .addCase(AuthFacebookThunk.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
      });
  }
});

export const { logOut } = AuthSlice.actions;
export default AuthSlice.reducer;
