import { useCallback, useRef, useState, } from 'react';
import { toPng } from 'html-to-image';
import notify from '../utils/notify';

const useCropFace = (onStart, onSuccess, onFailed) => {
  const cropRef = useRef(null);
  const [position, setPosition] = useState({ x: 0, y: 0 });
  const [scale, setScale] = useState(1);
  const [rotate, setRotate] = useState(0);

  const onClickCropping = useCallback(async () => {
    onStart();
    const getFileSize = (dataUrl) => {
      const stringLength = dataUrl.length - 'data:image/png;base64,'.length;
      const sizeInBytes = 4 * Math.ceil((stringLength / 3)) * 0.5624896334383812;
      return sizeInBytes / 1000;
    };
    const cropImage = async () => {
      const dataUrl = await toPng(cropRef.current, {
        cacheBust: true,
        skipFonts: true
      });
      return dataUrl;
    };

    const tryAgain = async (i = 10) => {
      if (i <= 0) {
        notify('error', 'Something went wrong');
        onFailed?.();
        return;
      }

      const dataUrl = await cropImage();
      const size = getFileSize(dataUrl);

      if (size < 10) {
        tryAgain(i - 1);
      } else {
        onSuccess?.(dataUrl);
      }
    };

    try {
      tryAgain();
    } catch (e) {
      notify('error', 'Unable to process image');
      onFailed?.();
    }
  }, [onSuccess, onFailed]);

  return {
    cropRef,
    position,
    setPosition,
    scale,
    setScale,
    rotate,
    setRotate,
    onClickCropping,
  };
};

export default useCropFace;
