import React from 'react';
import { useTranslation } from 'react-i18next';
import { HOMEPAGE } from '../../constants/links';
import Page from '../../layout/Page/Page';
import ButtonLink from '../../components/ButtonLink/ButtonLink';
import styles from './sass/ErrorPage.module.scss';

import Logo from '../../assets/images/errorPageLogo.jpg';
import Logo2x from '../../assets/images/errorPageLogo2x.jpg';

const ErrorPage = () => {
  const { t } = useTranslation('', { keyPrefix: 'error' });

  return (
    <Page withoutFooter>
      <section className={styles.section}>
        <div className={styles.container}>
          <img className={styles.logo} src={Logo} srcSet={`${Logo2x} 2x`} alt="404" />
          <p>{t('contentDoesntExist')}</p>
          <ButtonLink buttonStyle={styles.button} link={HOMEPAGE} text={t('goToHomepage')} isFilled />
        </div>
      </section>
    </Page>
  );
};

export default ErrorPage;
