import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { USERS_API } from '../../constants/api';
import { logOut } from '../auth/slice';
import { HOMEPAGE } from '../../constants/links';
import instance from '../../services/axios';

export const initialState = {
  error: null,
  isLoading: false,
};

export const DeleteAccountThunk = createAsyncThunk(
  'deleteAccount/deleteAccountThunk',
  async (_, { rejectWithValue, getState, dispatch }) => {
    try {
      const { profile: { data: { id } } } = getState();
      const { data } = await instance.delete(`${USERS_API}${id}/`);
      dispatch(logOut());
      window.location.href = `${window.location.origin}${HOMEPAGE}`;
      return data;
    } catch ({ response: { data } }) {
      return rejectWithValue(data);
    }
  }
);

export const DeleteAccountSlice = createSlice({
  name: 'deleteAccount',
  initialState,
  extraReducers: (builder) => {
    builder
      .addCase(DeleteAccountThunk.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(DeleteAccountThunk.fulfilled, (state) => {
        state.isLoading = false;
      })
      .addCase(DeleteAccountThunk.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
      });
  }
});

export default DeleteAccountSlice.reducer;
