import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { SIGN_IN } from '../constants/links';
import { logOut } from '../store/auth/slice';

const useLogout = () => {
  const dispatch = useDispatch();
  return useCallback(() => {
    dispatch(logOut());
    window.location.href = `${window.location.origin}${SIGN_IN}`;
  }, []);
};

export default useLogout;
