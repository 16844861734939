import React from 'react';
import { useTranslation } from 'react-i18next';
import {
  CONTACT_US,
  CORPORATE,
  HOW_IT_WORKS,
  OUR_WHY,
  PRIVACY_POLICY,
  SHOP_BRANDS,
  TERMS_AND_CONDITIONS
} from '../../constants/links';
import FooterSection from './components/FooterSection';
import FooterMedia from './components/FooterMedia';
import styles from './sass/Footer.module.scss';

import GiftoonIcon from '../../assets/icons/giftoonWhite.svg';
import CardIcon from '../../assets/images/footerCard.png';

const Footer = () => {
  const { t } = useTranslation('', { keyPrefix: 'footer' });

  const shopLinks = [
    { name: t('buyGiftCards'), link: SHOP_BRANDS },
    { name: t('howItWorks'), link: HOW_IT_WORKS }
  ];

  const aboutLinks = [
    { name: t('ourWhy'), link: OUR_WHY },
    { name: t('corporate'), link: CORPORATE }
  ];

  const finePrint = [
    { name: t('privacyPolicy'), link: PRIVACY_POLICY },
    { name: t('termsConditions'), link: TERMS_AND_CONDITIONS }
  ];

  const supportLinks = [
    { name: t('contactUs'), link: CONTACT_US }
  ];

  return (
    <footer>
      <div className={`container ${styles.footerWrapper}`}>
        <div className={styles.footerContainer}>
          <div className={styles.mediaSection}>
            <img className={styles.logo} src={GiftoonIcon} alt="Logo" />
            <FooterMedia />
          </div>
          <div className={styles.footerNavigationSection}>
            <FooterSection title={t('shop')} items={shopLinks} />
            <FooterSection title={t('aboutUs')} items={aboutLinks} />
            <FooterSection title={t('support')} items={supportLinks} />
            <FooterSection title={t('finePrint')} items={finePrint} />
          </div>
        </div>
        <hr />
        <div className={styles.infoContainer}>
          <img className={styles.cardImage} src={CardIcon} alt="footer" />
          <p className={styles.infoText}>{t('footerInfo')}</p>
          <p className={`${styles.infoText} ${styles.companyName}`}>{t('companyName')}</p>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
