import { SHOP_BRANDS_API, SHOP_GIVEAWAY_API } from '../constants/api';
import instance from '../services/axios';

const filterCategories = (categories, filter) => (
  categories.filter((category) => filter.includes(category.name)).reduce(
    (acc, currentValue) => `${acc}&categories=${currentValue.id}`,
    ''
  ));

export const getBrandsRequest = async (categories, filter, url, limit, search) => {
  const result = filterCategories(categories, filter);
  const searchParam = `&search=${search}`;
  const apiUrl = url || (
    SHOP_BRANDS_API
      .concat(`?limit=${limit}&offset=0`)
      .concat(`${result.length ? result : ''}`)
      .concat(`${search ? searchParam : ''}`)
  );

  const { data, config: { url: requestUrl } } = await instance.get(apiUrl, {}, { withoutAuth: true });

  const newUrl = new URL(requestUrl);
  const { searchParams } = newUrl;

  const urlLimit = +searchParams.get('limit');
  const offset = +searchParams.get('offset') + urlLimit;

  searchParams.set('offset', offset);
  newUrl.search = searchParams.toString();

  const nextUrl = newUrl.toString();

  return { brands: data, next: nextUrl };
};

export const getGiveawayRequest = async (categories, filter, url, limit) => {
  const result = filterCategories(categories, filter);
  const apiUrl = url || (
    SHOP_GIVEAWAY_API
      .concat(`?limit=${limit}`)
      .concat(`${result.length ? result : ''}`)
  );
  const { data } = await instance.get(apiUrl, {}, { withoutAuth: true });
  return data;
};
