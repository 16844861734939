import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Toaster } from 'react-hot-toast';
import notifyStyles from '../../constants/toaster';
import SendingHeader from './components/SendingHeader';
import SendingFooter from './components/SendingFooter';
import styles from './sass/SendingPage.module.scss';
import Loader from '../../components/Loader/Loader';

const SendingPage = ({
  children, stepNumber, stepName, buttonLeft, buttonRight, buttonRightExtra, withoutFooter, loader
}) => {
  const { t } = useTranslation('', { keyPrefix: 'sendingPage' });

  const step = useMemo(() => (
    stepNumber ? `${t('step')} ${stepNumber}: ` : ''
  ), []);

  return (
    <div className={styles.page}>
      <Loader isOpen={loader} />
      <Toaster toastOptions={notifyStyles} />
      <SendingHeader heading={`${step}${stepName}`} stepNumber={stepNumber} />
      <div className={`container ${styles.pageContainerStyle}`}>
        {children}
      </div>
      { !withoutFooter && (
        <SendingFooter buttonLeft={buttonLeft} buttonRight={buttonRight} buttonRightExtra={buttonRightExtra} />
      )}
    </div>
  );
};

SendingPage.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ]),
  stepNumber: PropTypes.number,
  stepName: PropTypes.string.isRequired,
  buttonLeft: PropTypes.node,
  buttonRight: PropTypes.node,
  buttonRightExtra: PropTypes.node,
  withoutFooter: PropTypes.bool,
  loader: PropTypes.bool
};

SendingPage.defaultProps = {
  children: '',
  buttonLeft: '',
  buttonRight: '',
  buttonRightExtra: '',
  stepNumber: undefined,
  withoutFooter: false,
  loader: false
};

export default SendingPage;
