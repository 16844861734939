import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import styles from './sass/InputTextArea.module.scss';

const InputTextArea = ({
  inputStyles,
  labelStyles,
  placeholder,
  iconLeft,
  iconRight,
  onChange,
  onBlur,
  toggleInputIsHidden,
  value,
  name,
  label,
  error,
  isDisabled
}) => {
  const [focused, setFocused] = useState(false);

  const handleBlur = useCallback((e) => {
    onBlur(e);
    setFocused(false);
  }, [onBlur]);

  return (
    <div>
      <span className={`${styles.label} ${labelStyles}`}>{label}</span>
      <div className={`${styles.inputField} ${error && styles.error} ${focused && styles.focused} ${inputStyles}`}>
        { iconLeft && <img className={styles.iconLeft} src={iconLeft} alt="icon" /> }
        <textarea
          rows={3}
          className={styles.inputText}
          placeholder={placeholder}
          onChange={onChange}
          value={value}
          name={name}
          onBlur={(e) => handleBlur(e)}
          onFocus={() => setFocused(true)}
          disabled={isDisabled}
        />
        { iconRight && (
          <img
            className={styles.iconRight}
            src={iconRight}
            onClick={toggleInputIsHidden}
            alt="icon"
            role="presentation"
          />
        )}
      </div>
      {error && <span className={styles.errorMessage}>{error}</span>}
    </div>
  );
};

InputTextArea.propTypes = {
  inputStyles: PropTypes.string,
  labelStyles: PropTypes.string,
  placeholder: PropTypes.string.isRequired,
  iconLeft: PropTypes.string,
  iconRight: PropTypes.string,
  value: PropTypes.string.isRequired,
  name: PropTypes.string,
  label: PropTypes.string,
  error: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  onBlur: PropTypes.func,
  toggleInputIsHidden: PropTypes.func,
  isDisabled: PropTypes.bool
};

InputTextArea.defaultProps = {
  inputStyles: '',
  labelStyles: '',
  name: '',
  label: '',
  error: '',
  iconLeft: null,
  iconRight: null,
  onBlur: () => {},
  toggleInputIsHidden: () => {},
  isDisabled: false
};

export default InputTextArea;
