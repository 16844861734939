export default {
  success: {
    iconTheme: {
      primary: '#D62FC3',
      secondary: '#fff',
    },
  },
  error: {
    iconTheme: {
      primary: '#fff',
      secondary: '#DC2626',
    },
    style: {
      background: '#DC2626',
      color: '#fff'
    }
  }
};
