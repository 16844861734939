import React from 'react';
import PropTypes from 'prop-types';
import Slider from 'react-slick';
import NextArrow from './NextArrow';
import PrevArrow from './PrevArrow';

import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import styles from './sass/ImageSlider.module.scss';

const ImageSlider = ({ images, onChange }) => {
  const settings = {
    className: styles.slider,
    dots: true,
    infinite: false,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: true,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
    dotsClass: `slick-dots ${styles.dots}`,
    adaptiveHeight: true,
    afterChange: onChange,
  };

  return (
    <Slider {...settings}>
      {images.map((image) => (
        <div key={image}>
          <div className={styles.imageContainer}>
            <img className={styles.image} src={image} alt="face" />
          </div>
        </div>
      ))}
    </Slider>
  );
};

ImageSlider.propTypes = {
  images: PropTypes.arrayOf(PropTypes.string).isRequired,
  onChange: PropTypes.func
};

ImageSlider.defaultProps = {
  onChange: undefined
};

export default ImageSlider;
