import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { HomeBrandsCategoryThunk } from '../../../store/homeBrands/slice';
import Category from './Category';
import { SHOP_BRANDS } from '../../../constants/links';
import styles from '../sass/CategorySearch.module.scss';

const CategorySearch = () => {
  const { t } = useTranslation('', { keyPrefix: 'home' });
  const dispatch = useDispatch();
  const categories = useSelector(({ homeBrands }) => homeBrands.data.categories);

  useEffect(() => {
    dispatch(HomeBrandsCategoryThunk({}));
  }, []);

  return (
    <div className={`container ${styles.container}`}>
      <h1 className={styles.heading}>{t('searchByCategory')}</h1>
      <div className={styles.categories}>
        {categories.map(({ name, preview_image: previewImage }) => (
          <Category key={name} heading={name} link={`${SHOP_BRANDS}?filter=${name}`} image={previewImage} />
        ))}
      </div>
    </div>
  );
};

export default CategorySearch;
