import React from 'react';
import PropTypes from 'prop-types';
import Modal from '../Modal/Modal';

import loader from '../../assets/icons/loader.svg';

const Loader = ({ isOpen }) => {
  return (
    <Modal isOpen={isOpen}>
      <img src={loader} alt="loader" />
    </Modal>
  );
};

Loader.propTypes = {
  isOpen: PropTypes.bool.isRequired
};

export default Loader;
