import React, { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { croppingFaceVerify } from '../../store/cropFaceVerify/slice';
import { STEP_CHECK_PHOTO } from '../../constants/links';
import CropFace from '../../components/CropFace/CropFace';
import SendingPage from '../../layout/SendingPage/SendingPage';
import Slider from '../../components/Slider/Slider';
import Button from '../../components/Button/Button';
import useCropFace from '../../hooks/useCropFace';
import ButtonLink from '../../components/ButtonLink/ButtonLink';
import styles from './sass/StepCropPhoto.module.scss';

import RefundBack from '../../assets/icons/refundBack.svg';
import RefundFront from '../../assets/icons/refundForward.svg';
import ArrowLeftIcon from '../../assets/icons/arrowLeftPink.svg';
import ArrowRightIcon from '../../assets/icons/arrowRightWhite.svg';

const SCALE = {
  min: 0.12,
  max: 5,
  step: 0.01
};

const ROTATE = {
  min: -180,
  max: 180,
  step: 1
};

const StepCropPhoto = () => {
  const { t } = useTranslation('', { keyPrefix: 'cropPhoto' });
  const [localLoading, setLocalLoading] = React.useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const image = useSelector((state) => state.cropFace.data?.image, shallowEqual);
  const { isLoading } = useSelector(({ cropFaceVerify }) => cropFaceVerify, shallowEqual);
  const onCropFace = useCallback((cropImage) => {
    dispatch(croppingFaceVerify({ image: cropImage, callback: () => navigate(STEP_CHECK_PHOTO) }));
    setLocalLoading(false);
  }, []);

  const onStartCrop = useCallback(() => {
    setLocalLoading(true);
  }, []);

  const onFailedCrop = useCallback(() => {
    setLocalLoading(false);
  }, []);

  const {
    cropRef,
    position,
    setPosition,
    scale,
    setScale,
    rotate,
    setRotate,
    onClickCropping,
  } = useCropFace(onStartCrop, onCropFace, onFailedCrop);

  const buttonLeft = useMemo(() => (
    <ButtonLink
      buttonStyle={styles.buttonLink}
      text={t('back')}
      iconLeft={ArrowLeftIcon}
      link={STEP_CHECK_PHOTO}
    />
  ), []);

  const buttonRight = useMemo(() => (
    <Button
      buttonStyle={styles.buttonLink}
      text={t('saveChanges')}
      iconRight={ArrowRightIcon}
      onClick={onClickCropping}
      isFilled
    />
  ), [onClickCropping]);

  return (
    <SendingPage
      stepNumber={3}
      stepName={t('addFace')}
      buttonLeft={buttonLeft}
      buttonRight={buttonRight}
      loader={isLoading || localLoading}
    >
      <section className={styles.section}>
        <div className={styles.wrapper}>
          <div className={styles.cropFace__wrapper}>
            {image && (
            <CropFace
              cropRef={cropRef}
              position={position}
              onChangePosition={setPosition}
              scale={scale}
              onScale={setScale}
              scaleConfig={SCALE}
              rotate={rotate}
              onRotate={setRotate}
              rotateConfig={ROTATE}
              image={image}
            />
            )}
          </div>
          <div className={styles.controls}>
            <div>
              <h2 className={styles.heading}>{t('createCharacter')}</h2>
              <p className={styles.description}>{t('description')}</p>
            </div>
            <div className={styles.sliders}>
              <Slider
                onChange={setScale}
                value={scale}
                min={SCALE.min}
                title={t('scale')}
                max={SCALE.max}
                step={SCALE.step}
                className={styles.slider}
              />
              <Slider
                onChange={setRotate}
                value={rotate}
                min={ROTATE.min}
                title={t('rotate')}
                max={ROTATE.max}
                step={ROTATE.step}
                leftIcon={RefundFront}
                rightIcon={RefundBack}
                className={styles.slider}
              />
            </div>
          </div>
        </div>
      </section>
    </SendingPage>
  );
};

export default StepCropPhoto;
