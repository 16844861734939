import React from 'react';
import { useTranslation } from 'react-i18next';
import ButtonLink from '../../../components/ButtonLink/ButtonLink';
import { SHOP_BRANDS } from '../../../constants/links';
import styles from '../sass/DescriptionBlock.module.scss';

import PhoneInHand from '../../../assets/images/PhoneInHand.png';

const DescriptionBlock = () => {
  const { t } = useTranslation('', { keyPrefix: 'home' }); 
  
  return (
    <div className={styles.block}>
      <div className={`container ${styles.container}`}>
        <div className={styles.left}>
          <p className={styles.shortText}>{t('personaliseIt')}</p>
          <h1 className={styles.heading}>{t('giveYourLovedOne')}</h1>
          <p className={styles.description}>{t('simplyAddTheirFace')}</p>
          <ButtonLink buttonStyle={styles.button} link={SHOP_BRANDS} text={t('shopNow')} />
        </div>
      </div>
      <img className={styles.phoneImage} src={PhoneInHand} alt={t('personaliseIt')} />
    </div>
  );
};

export default DescriptionBlock;
