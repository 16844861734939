import React from 'react';
import PropTypes from 'prop-types';
import ErrorPage from '../../pages/ErrorPage/ErrorPage';

const ErrorChecker = ({ children, error }) => {
  return error ? <ErrorPage /> : children;
};

ErrorChecker.propTypes = {
  children: PropTypes.node.isRequired,
  error: PropTypes.string,
};

ErrorChecker.defaultProps = {
  error: null,
};

export default ErrorChecker;
