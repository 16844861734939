import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import instance from '../../services/axios';
import notify from '../../utils/notify';
import {
  ENCRYPT_GIFTOON_API, GIFTOONS_SHARE_EMAIL_API, PRIVATE, PUBLIC
} from '../../constants/api';

export const initialState = {
  error: null,
  isLoading: false,
  data: {},
};

export const GiftViewThunk = createAsyncThunk(
  'giftView/giftViewThunk',
  async ({ hash }, { rejectWithValue }) => {
    try {
      const { data } = await instance.get(`${ENCRYPT_GIFTOON_API}${hash}${PRIVATE}`);
      return data;
    } catch (error) {
      notify('error', 'Invalid token');
      return rejectWithValue(error.message);
    }
  } 
);

export const GiftShareViewThunk = createAsyncThunk(
  'giftView/giftShareViewThunk',
  async ({ hash }, { rejectWithValue }) => {
    try {
      const { data } = await instance.get(`${ENCRYPT_GIFTOON_API}${hash}${PUBLIC}`);
      return data;
    } catch (error) {
      notify('error', 'Invalid token');
      return rejectWithValue(error.message);
    }
  }
);

export const GiftShareToEmailViewThunk = createAsyncThunk(
  'giftView/giftShareToEmailViewThunk',
  async ({ email, url }, { rejectWithValue }) => {
    try {
      const { data } = await instance.post(GIFTOONS_SHARE_EMAIL_API, { giftoon_url: url, email }, { wihoutAuth: true });
      notify('success', 'Email was send');
      return data;
    } catch (error) {
      notify('error', 'Try again later');
      return rejectWithValue(error.message);
    }
  }
);

export const GiftViewSlice = createSlice({
  name: 'giftView',
  initialState,
  extraReducers: (builder) => {
    builder
      .addCase(GiftViewThunk.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(GiftViewThunk.fulfilled, (state, action) => {
        state.isLoading = false;
        state.data = action.payload;
      })
      .addCase(GiftViewThunk.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
      })

      .addCase(GiftShareViewThunk.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(GiftShareViewThunk.fulfilled, (state, action) => {
        state.isLoading = false;
        state.data = action.payload;
      })
      .addCase(GiftShareViewThunk.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
      });
  }
});

export default GiftViewSlice.reducer;
