import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import Input from '../../../components/Input/Input';
import styles from '../sass/SenderInputForm.module.scss';

import UserIcon from '../../../assets/icons/user.svg';
import MessageIcon from '../../../assets/icons/message.svg';

const SenderInputForm = ({
  isGift, handleChange, handleBlur, errors, senderName, senderEmail
}) => {
  const { t } = useTranslation('', { keyPrefix: 'sendGiftoon' });

  return (
    isGift && (
      <div className={styles.senderInfoContainer}>
        <h2 className={styles.senderName}>{t('sendersDetails')}</h2>
        <div className={styles.senderInfoInputs}>
          <Input
            onChange={handleChange}
            onBlur={handleBlur}
            placeholder={t('sendersName')}
            iconLeft={UserIcon}
            value={senderName}
            error={errors.senderName}
            name="senderName"
          />
          <Input
            onChange={handleChange}
            onBlur={handleBlur}
            placeholder={t('sendersEmail')}
            iconLeft={MessageIcon}
            value={senderEmail}
            error={errors.senderEmail}
            name="senderEmail"
          />
        </div>
      </div>
    )
  );
};

SenderInputForm.propTypes = {
  isGift: PropTypes.bool.isRequired,
  handleChange: PropTypes.func.isRequired,
  handleBlur: PropTypes.func.isRequired,
  errors: PropTypes.shape({
    senderName: PropTypes.string,
    senderEmail: PropTypes.string
  }).isRequired,
  senderName: PropTypes.string.isRequired,
  senderEmail: PropTypes.string.isRequired
};

export default SenderInputForm;
