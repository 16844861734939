import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { DateTime } from 'luxon';
import Button from '../Button/Button';
import VideoPlayer from '../VideoPlayer/VideoPlayer';
import styles from './sass/ReceivedGiftoonItem.module.scss';

const ReceivedGiftoonItem = ({
  withGiftoon,
  customPlayIcon,
  couponStyles,
  isUsed,
  isNew,
  giftoonVideo,
  brandImageUrl,
  previewImageUrl,
  brandName,
  brandPrice,
  createdAt,
  email,
  phone,
  onClickUseCoupon,
}) => {
  const { t } = useTranslation('', { keyPrefix: 'wallet' });

  return (
    <div className={`${styles.container} ${couponStyles}`}>
      {withGiftoon && (
        <div className={styles.videoContainer} style={{ backgroundImage: `url(${previewImageUrl})` }}>
          <VideoPlayer
            customPlayIcon={customPlayIcon}
            fullscreenDisabled={false}
            video={giftoonVideo}
            videoContainerStyles={styles.walletVideo}
            playerStyles={styles.video}
          />
        </div>
      )}
      <img
        className={`${styles.walletImage} ${!withGiftoon ? styles.onlyImage : ''}`}
        src={brandImageUrl}
        alt="giftoon"
      />
      <div className={styles.giftInfo}>
        <p className={styles.userData}>
          {email || phone}
          <br />
          {DateTime.fromISO(createdAt).toFormat('hh:mm , DDD')}
          <br />
          <span className={styles.expirationDate}>{t('noExpirationDate')}</span>
        </p>
        <div className={styles.couponData}>
          {isNew && <span className={styles.new}>{t('new')}</span>}
          <h2 className={styles.couponName}>{`${brandName} - ${t('digitalDelivery')}`}</h2>
          <h2 className={styles.couponPrice}>{`$${brandPrice}`}</h2>
        </div>
        <Button
          buttonStyle={`buttonContainer ${styles.couponButton} ${isUsed && styles.usedCoupon}`}
          text={isUsed ? t('couponUsed') : t('useCoupon')}
          isFilled
          isDisabled={isUsed}
          onClick={onClickUseCoupon}
        />
      </div>
    </div>
  );
};

ReceivedGiftoonItem.propTypes = {
  withGiftoon: PropTypes.bool.isRequired,
  customPlayIcon: PropTypes.string,
  couponStyles: PropTypes.string,
  isUsed: PropTypes.bool,
  isNew: PropTypes.bool,
  giftoonVideo: PropTypes.string,
  brandImageUrl: PropTypes.string,
  previewImageUrl: PropTypes.string,
  brandName: PropTypes.string,
  brandPrice: PropTypes.number,
  createdAt: PropTypes.string,
  email: PropTypes.string,
  phone: PropTypes.string,
  onClickUseCoupon: PropTypes.func.isRequired,
};

ReceivedGiftoonItem.defaultProps = {
  customPlayIcon: '',
  couponStyles: '',
  isUsed: false,
  isNew: false,
  giftoonVideo: '',
  brandImageUrl: '',
  previewImageUrl: '',
  brandName: '',
  brandPrice: null,
  createdAt: '',
  email: '',
  phone: ''
};

export default ReceivedGiftoonItem;
