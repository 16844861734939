import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import MaskInput from '../MaskInput/MaskInput';
import Input from '../Input/Input';
import styles from './sass/PinPay.module.scss';

import CardNumberIcon from '../../assets/icons/creditCardSilver.svg';
import ExpireDateIcon from '../../assets/icons/calendar.svg';
import CvcIcon from '../../assets/icons/lock.svg';

const PinPay = ({
  cardNumber, expiry, cvc, errors, handleChange, handleBlur, name, emailField
}) => {
  const { t } = useTranslation('', { keyPrefix: 'checkout' });

  return (
    <div className={styles.pinPay}>
      <MaskInput
        mask="9999 9999 9999 9999"
        onChange={handleChange}
        onBlur={handleBlur}
        focusedStyle={styles.focused}
        placeholder={t('cardNumber')}
        maskPlaceholder=""
        error={errors.cardNumber}
        value={cardNumber}
        name="cardNumber"
        iconLeft={CardNumberIcon}
      />
      <div className={styles.groupInputs}>
        <MaskInput
          mask="99/99"
          onChange={handleChange}
          onBlur={handleBlur}
          focusedStyle={styles.focused}
          placeholder={t('expireDate')}
          maskPlaceholder=""
          error={errors.expiry}
          value={expiry}
          name="expiry"
          iconLeft={ExpireDateIcon}
        />
        <MaskInput
          type="password"
          mask="9999"
          onChange={handleChange}
          focusedStyle={styles.focused}
          onBlur={handleBlur}
          cvvNumber
          placeholder={t('cvvNumber')}
          maskPlaceholder=""
          error={errors.cvc}
          value={cvc}
          name="cvc"
          iconLeft={CvcIcon}
        />
      </div>
      <Input
        value={name}
        name="name"
        placeholder={t('name')}
        onChange={handleChange}
        error={errors.name}
        onBlur={handleBlur}
        inputStyle={styles.input}
      />
      <Input
        value={emailField}
        name="emailField"
        placeholder={t('email')}
        onChange={handleChange}
        error={errors.emailField}
        onBlur={handleBlur}
        inputStyle={styles.input}
      />
    </div>
  );
};

PinPay.propTypes = {
  handleChange: PropTypes.func.isRequired,
  handleBlur: PropTypes.func.isRequired,
  errors: PropTypes.shape({
    cardNumber: PropTypes.string,
    expiry: PropTypes.string,
    cvc: PropTypes.string,
    name: PropTypes.string,
    city: PropTypes.string,
    address: PropTypes.string,
    country: PropTypes.string,
    emailField: PropTypes.string,
  }).isRequired,
  cardNumber: PropTypes.string,
  expiry: PropTypes.string,
  cvc: PropTypes.string,
  name: PropTypes.string,
  emailField: PropTypes.string,
};

PinPay.defaultProps = {
  cardNumber: '',
  expiry: '',
  cvc: '',
  name: '',
  emailField: '',
};
export default PinPay;
