import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import styles from '../sass/SendingFooter.module.scss';

const SendingFooter = ({ buttonLeft, buttonRight, buttonRightExtra }) => {
  const renderRightButtons = useMemo(() => (
    buttonRight || buttonRightExtra
  ), [buttonRight, buttonRightExtra]);

  return (
    <div className={styles.footer}>
      <div className={`container ${styles.buttonsContainer} ${buttonRightExtra && styles.withExtraButton}`}>
        <div>
          {buttonLeft}
        </div>
        {renderRightButtons && (
          <div className={styles.rightButtonContainer}>
            {buttonRightExtra}
            {buttonRight}
          </div>
        )}
      </div>
    </div>
  );
};

SendingFooter.propTypes = {
  buttonLeft: PropTypes.node.isRequired,
  buttonRight: PropTypes.node.isRequired,
  buttonRightExtra: PropTypes.node.isRequired
};

export default SendingFooter;
