import React from 'react';
import PropTypes from 'prop-types';
import styles from './sass/FileInput.module.scss';

const FileInput = ({
  icon, id, name, text, acceptedFiles, inputStyle, onChange, onClick
}) => {
  return (
    <label className={`${styles.inputLabel} ${inputStyle}`} htmlFor={id}>
      <img src={icon} alt="icon" />
      <input
        name={name}
        id={id}
        type="file"
        accept={acceptedFiles}
        onChange={onChange}
        onClick={onClick}
      />
      <span>{text}</span>
    </label>
  );
};

FileInput.propTypes = {
  icon: PropTypes.string,
  id: PropTypes.string,
  name: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
  acceptedFiles: PropTypes.string,
  inputStyle: PropTypes.string,
  onChange: PropTypes.func,
  onClick: PropTypes.func,
};

FileInput.defaultProps = {
  icon: '',
  id: 'file',
  acceptedFiles: '',
  inputStyle: '',
  onChange: () => {},
  onClick: () => {},
};

export default FileInput;
