import React from 'react';
import PropTypes from 'prop-types';
import InfiniteScroll from 'react-infinite-scroll-component';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import BrandCard from '../BrandCard/BrandCard';

import styles from './sass/BrandList.module.scss';
import GiftoonSmileIcon from '../../assets/icons/giftoonSmile.svg';

const BrandList = ({
  brandList, next, thunk, onSelectBrand
}) => {
  const { t } = useTranslation('', { keyPrefix: 'shopBrands' });
  const dispatch = useDispatch();

  const loadNextBrands = () => {
    dispatch(thunk({ url: next }));
  };

  return (
    <InfiniteScroll
      next={loadNextBrands}
      hasMore={next}
      dataLength={brandList.length}
      className={styles.brandList}
      loader={<img className={styles.giftoonSmile} src={GiftoonSmileIcon} alt={t('giftoon')} />}
    >
      {brandList?.map(({
        id,
        product_name: brandName,
        price_low: priceLow,
        price_high: priceHigh,
        currency,
        image_url: imageUrl
      }) => (
        <BrandCard
          key={id}
          brandName={brandName}
          priceHigh={priceHigh}
          priceLow={priceLow}
          currency={currency}
          image={imageUrl}
          onSelect={onSelectBrand}
        />
      ))}
    </InfiniteScroll>
  );
};

BrandList.propTypes = {
  brandList: PropTypes.arrayOf(PropTypes.shape({})),
  next: PropTypes.string,
  thunk: PropTypes.func.isRequired,
  onSelectBrand: PropTypes.func.isRequired,
};

BrandList.defaultProps = {
  brandList: [],
  next: ''
};

export default BrandList;
