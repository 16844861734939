export const REDIRECT = '?redirect=';
export const PRODUCT_NAME = '?product_name=';
export const HOME_SLIDER = '?home_slider=1';

export const NON_EXISTING_PAGE = '/*';

export const HOMEPAGE = '/';

export const SIGN_IN = '/sign-in';
export const SIGN_UP = '/sign-up';
export const VERIFY_EMAIL = '/verify-email';

export const FORGOT_PASSWORD = '/forgot-password';
export const RESET_PASSWORD = '/reset-password';
export const RESET_PASSWORD_SENT = '/reset-password-sent';

export const SHOP_BRANDS = '/shop-brands';
export const SHOP_GIFTOONS = '/shop-giftoons';

export const STEP_SELECT_BRAND = '/step-select-brand';
export const STEP_SELECT_GIFTOON = '/step-select-giftoon';
export const STEP_ADD_BRAND_LIST = '/step-add-brand-list';
export const STEP_ADD_GIFTOON_LIST = '/step-add-giftoon-list';
export const STEP_ADD_COUPON = '/step-add-coupon';
export const STEP_ADD_GIFTOON = '/step-add-giftoon';
export const STEP_SELECT_PHOTO = '/step-select-photo';
export const STEP_CHECK_PHOTO = '/step-check-photo';
export const STEP_CROP_PHOTO = '/step-crop-photo';
export const STEP_SEND_GIFTOON = '/step-send-giftoon';

export const PROFILE = '/profile';
export const GIFT_VIEW = '/gift-view';
export const RECEIVED_GIFTOON = '/received-giftoon';
export const GIFT_SHARE_VIEW = '/gift-share-view';

export const CHECKOUT = '/checkout';
export const PURCHASE_COMPLETED = '/purchase-completed';

export const CORPORATE = '/corporate';
export const CONTACT_US = '/contact-us';

export const PRIVACY_POLICY = '/privacy-policy';
export const TERMS_AND_CONDITIONS = '/terms-and-conditions';
export const OUR_WHY = '/our-why';
export const HOW_IT_WORKS = '/how-it-works';

export const COMPETITION = '/competition';

export const APPLICATION_REDIRECT_URL = 'giftoon://';
