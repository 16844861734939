import { createSlice } from '@reduxjs/toolkit';

export const initialState = {
  error: null,
  data: {
    accessToken: null,
    dateExpiresIn: null
  },
};

export const FacebookSlice = createSlice({
  name: 'facebookImages',
  initialState,
  reducers: {
    setFacebookToken: (state, action) => {
      state.data = action.payload;
      state.data.dateExpiresIn = new Date().getTime() + action.payload.expiresIn * 1000;
    },
    clearFacebookToken: (state) => {
      state.data = null;
    }
  }
});

export const { setFacebookToken, clearFacebookToken } = FacebookSlice.actions;
export default FacebookSlice.reducer;
