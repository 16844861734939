import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { HOMEPAGE } from '../../constants/links';
import Page from '../../layout/Page/Page';
import Button from '../../components/Button/Button';
import styles from './sass/ResetEmailSent.module.scss';
import '../../styles/authentificationStyles.scss';

import ArrowIcon from '../../assets/icons/arrowRightWhite.svg';

const ResetPasswordSent = () => {
  const { t } = useTranslation('', { keyPrefix: 'resetEmailSent' });
  return (
    <Page withoutFooter>
      <section className="authentificationContainer">
        <h1 className={styles.emailSentMessage}>{t('emailWasSent')}</h1>
        <p className={styles.followInstructionsText}>{t('followInstructions')}</p>
        <Link className={`buttonContainer ${styles.linkButtonContainer}`} to={HOMEPAGE}>
          <Button text={t('next')} iconRight={ArrowIcon} isFilled />
        </Link>
      </section>
    </Page>
  );
};

export default ResetPasswordSent;
