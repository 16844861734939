import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import instance from '../../services/axios';
import { GET_SENT_GIFTOONS_API } from '../../constants/api';
import { SENT_GIFTOONS_LIMIT } from '../../constants/constants';

export const initialState = {
  next: `${GET_SENT_GIFTOONS_API}?limit=${SENT_GIFTOONS_LIMIT}`,
  error: null,
  isLoading: false,
  data: [],
};

export const SentGiftoonsThunk = createAsyncThunk(
  'sentGiftoons/sentGiftoonsThunk',
  async ({ next }, { rejectWithValue, getState }) => {
    try {
      const { sentGiftoons: { data: previousGiftoons } } = getState();
      const { data } = await instance.get(next);
      return { results: [...previousGiftoons, ...data.results], next: data?.next };
    } catch ({ response: { data } }) {
      return rejectWithValue(data);
    }
  }
);

export const SentGiftoonsSlice = createSlice({
  name: 'sentGiftoons',
  initialState,
  reducers: {
    clearSentGiftoons: () => (initialState)
  },
  extraReducers: (builder) => {
    builder
      .addCase(SentGiftoonsThunk.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(SentGiftoonsThunk.fulfilled, (state, action) => {
        state.isLoading = false;
        state.data = action.payload.results;
        state.next = action?.payload?.next;
      })
      .addCase(SentGiftoonsThunk.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
      });
  }
});

export const { clearSentGiftoons } = SentGiftoonsSlice.actions;
export default SentGiftoonsSlice.reducer;
