import React from 'react';
import PropTypes from 'prop-types';
import styles from '../sass/StepRow.module.scss';

const StepRow = ({
  stepNumber, heading, description, image, image2x
}) => {
  return (
    <div className={styles.step}>
      <div className={styles.stepText}>
        <span className={styles.stepNumber}>{stepNumber}</span>
        <h2>{heading}</h2>
        {description}
      </div>
      <img src={image} alt={heading} srcSet={`${image2x} 2x`} />
    </div>
  );
};

StepRow.propTypes = {
  stepNumber: PropTypes.string.isRequired,
  heading: PropTypes.string.isRequired,
  description: PropTypes.node.isRequired,
  image: PropTypes.string.isRequired,
  image2x: PropTypes.string.isRequired,
};

export default StepRow;
