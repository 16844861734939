import React from 'react';
import { useTranslation } from 'react-i18next';
import styles from '../sass/FeaturedBlock.module.scss';

import Banner7News from '../../../assets/images/7newsFeaturedBanner.jpg';
import Banner7News2x from '../../../assets/images/7newsFeaturedBanner2x.jpg';
import Banner7Life from '../../../assets/images/7lifeFeaturedBanner.jpg';
import Banner7Life2x from '../../../assets/images/7lifeFeaturedBanner2x.jpg';
import BannerFinder from '../../../assets/images/finderFeaturedBanner.jpg';
import BannerFinder2x from '../../../assets/images/finderFeaturedBanner2x.jpg';
import BannerPopsugar from '../../../assets/images/popsugarFeaturedBanner.jpg';
import BannerPopsugar2x from '../../../assets/images/popsugarFeaturedBanner2x.jpg';

const FeaturedBlock = () => {
  const { t } = useTranslation('', { keyPrefix: 'home' });

  return (
    <div className={styles.block}>
      <div className={`container ${styles.container}`}>
        <h1 className={styles.heading}>{t('asFeatured')}</h1>
        <div className={styles.imageContainer}>
          <img className={styles.image} src={Banner7News} srcSet={`${Banner7News2x} 2x`} alt={t('asFeatured')} />
          <img className={styles.image} src={Banner7Life} srcSet={`${Banner7Life2x} 2x`} alt={t('asFeatured')} />
          <img className={styles.image} src={BannerFinder} srcSet={`${BannerFinder2x} 2x`} alt={t('asFeatured')} />
          <img className={styles.image} src={BannerPopsugar} srcSet={`${BannerPopsugar2x} 2x`} alt={t('asFeatured')} />
        </div>
      </div>
    </div>
  );
};

export default FeaturedBlock;
