import React from 'react';
import { FACEBOOK_LINK, INSTAGRAM_LINK, TIKTOK_LINK } from '../../../constants/constants';
import styles from '../sass/FooterMedia.module.scss';

import FacebookIcon from '../../../assets/icons/facebookCircle.svg';
import InstagramIcon from '../../../assets/icons/instagram.svg';
import TikTokIcon from '../../../assets/icons/tiktok.svg';

const FooterMedia = () => {
  return (
    <ul className={styles.mediaContainer}>
      <li>
        <a aria-label="link" href={FACEBOOK_LINK} target="_blank" rel="noreferrer">
          <img className={styles.mediaIcon} src={FacebookIcon} alt="Facebook" />
        </a>
      </li>
      <li>
        <a aria-label="link" href={INSTAGRAM_LINK} target="_blank" rel="noreferrer">
          <img className={styles.mediaIcon} src={InstagramIcon} alt="Instagram" />
        </a>
      </li>
      <li>
        <a aria-label="link" href={TIKTOK_LINK} target="_blank" rel="noreferrer">
          <img className={styles.mediaIcon} src={TikTokIcon} alt="TikTok" />
        </a>
      </li>
    </ul>
  );
};

export default FooterMedia;
