import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useFormik } from 'formik';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { forgotPasswordValidationSchema } from '../../utils/validation';
import { ForgotPasswordThunk } from '../../store/forgotPassword/slice';
import Page from '../../layout/Page/Page';
import Input from '../../components/Input/Input';
import Button from '../../components/Button/Button';

import '../../styles/authentificationStyles.scss';
import styles from './sass/ForgotPassword.module.scss';

import ResetIcon from '../../assets/icons/signInCircleWhite.svg';
import LockIcon from '../../assets/icons/lock.svg';
import ViewIcon from '../../assets/icons/passwordEye.svg';
import ViewHideIcon from '../../assets/icons/passwordEyeCrossed.svg';

const ForgotPassword = () => {
  const { t } = useTranslation('', { keyPrefix: 'forgotPassword' });
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [searchParams] = useSearchParams();

  const [token] = useState(searchParams.get('token'));
  const [mobile] = useState(JSON.parse(searchParams.get('mobile').toLowerCase()));
  const [passwordIsHidden, setPasswordIsHidden] = useState({ password: true, repeatPassword: true });

  useEffect(() => {
    if (mobile) {
      window.open(`giftoon://resetPassword?token=${token}`, '_self');
    }
  }, [mobile]);

  const { isLoading } = useSelector(({ resetPassword }) => resetPassword, shallowEqual);

  const handleChangePasswordHidden = useCallback((inputName) => {
    setPasswordIsHidden((prevState) => ({ ...prevState, [inputName]: !prevState[inputName] }));
  }, [setPasswordIsHidden]);

  const {
    handleSubmit,
    values: { password, repeatPassword },
    handleChange,
    validateForm,
    errors,
    handleBlur,
  } = useFormik({
    initialValues: {
      password: '',
      repeatPassword: ''
    },
    onSubmit: (currentValues) => validateForm(currentValues).then(() => {
      dispatch(ForgotPasswordThunk({
        password, repeatPassword, token, navigate
      }));
    }),
    validationSchema: forgotPasswordValidationSchema,
    validateOnBlur: false,
    validateOnChange: false
  });

  return (
    <Page withoutFooter loader={isLoading}>
      <section className={`authentificationContainer ${styles.authentificationContainer}`}>
        <h1>{t('resetPassword')}</h1>
        <p className={styles.enterEmail}>{t('enterNewPassword')}</p>
        <div className={styles.passwordInput}>
          <Input
            type={passwordIsHidden.password ? 'password' : 'text'}
            placeholder={t('password')}
            onChange={handleChange}
            onBlur={handleBlur}
            iconLeft={LockIcon}
            iconRight={passwordIsHidden.password ? ViewIcon : ViewHideIcon}
            value={password}
            error={errors.password}
            name="password"
            onRightIconClick={() => handleChangePasswordHidden('password')}
          />
          <Input
            type={passwordIsHidden.repeatPassword ? 'password' : 'text'}
            placeholder={t('repeatPassword')}
            onChange={handleChange}
            onBlur={handleBlur}
            iconLeft={LockIcon}
            iconRight={passwordIsHidden.repeatPassword ? ViewIcon : ViewHideIcon}
            value={repeatPassword}
            error={errors.repeatPassword}
            name="repeatPassword"
            onRightIconClick={() => handleChangePasswordHidden('repeatPassword')}
          />
        </div>
        <div className="buttonContainer">
          <Button onClick={handleSubmit} text={t('reset')} iconRight={ResetIcon} isFilled />
        </div>
      </section>
    </Page>
  );
};

export default ForgotPassword;
