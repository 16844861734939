import React, { useCallback, useEffect, useState } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { clearReceivedGiftoon, ReceivedGiftoonsThunk } from '../../store/receivedGiftoons/slice';
import { clearCoupon, CouponThunk } from '../../store/coupon/slice';
import CouponList from './components/CouponList';
import NoGiftoons from './components/NoGiftoons';
import CouponModal from '../../components/CouponModal/CouponModal';
import Loader from '../../components/Loader/Loader';
import styles from './sass/ProfileWallet.module.scss';

const ProfileWallet = () => {
  const dispatch = useDispatch();
  const {
    receivedGiftoons: {
      isLoading: isLoadingGiftoons,
      data: giftoons,
      next: nextLink
    },
    coupon: {
      isLoading: isLoadingCoupon,
      data: openedCoupon
    }
  } = useSelector((state) => ({ receivedGiftoons: state?.receivedGiftoons, coupon: state?.coupon }), shallowEqual);

  const [couponModalOpened, setCouponModalOpened] = useState(false);

  const handleCloseCouponModal = () => {
    setCouponModalOpened(false);
    dispatch(clearCoupon());
  };

  const getNextGiftoons = () => {
    dispatch(ReceivedGiftoonsThunk({ next: nextLink }));
  };

  const openCouponModal = useCallback((id, brandName) => {
    dispatch(CouponThunk({ id, brandName }));
    setCouponModalOpened(true);
  }, [dispatch, setCouponModalOpened]);

  useEffect(() => {
    getNextGiftoons();
    return () => dispatch(clearReceivedGiftoon());
  }, []);

  return (
    <section className={styles.section}>
      <Loader isOpen={isLoadingGiftoons || isLoadingCoupon} />
      <CouponModal
        onClose={handleCloseCouponModal}
        couponModalOpened={couponModalOpened && !isLoadingCoupon}
        coupon={openedCoupon}
      />
      {giftoons.length > 0 ? (
        <CouponList
          giftoons={giftoons}
          nextLink={nextLink}
          getNextGiftoons={getNextGiftoons}
          setOpenedCoupon={openCouponModal}
        />
      ) : !isLoadingGiftoons && <NoGiftoons /> }
    </section>
  );
};

export default ProfileWallet;
