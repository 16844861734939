import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { GIFTOON_IMAGE_API } from '../../constants/api';
import instance from '../../services/axios';
import getFileFromUrl from '../../utils/getFileFromUrl';
import notify from '../../utils/notify';
import { setImageId } from '../giftFlow/slice';

export const initialState = {
  error: null,
  isLoading: false,
  data: null,
};

export const croppingFace = createAsyncThunk(
  'cropFace/croppingFace',
  async ({ image, callback }, { rejectWithValue, dispatch }) => {
    try {
      const formData = new FormData();
      const file = image instanceof File ? image : await getFileFromUrl(image, 'image.png');
      formData.append('image', file);
      const { data } = await instance.post(GIFTOON_IMAGE_API, formData);
      dispatch(setImageId(data.id));
      callback?.();
      return data;
    } catch (error) {
      notify('error', 'Face is not detected');
      return rejectWithValue(error.message);
    }
  }
);

export const CropFaceSlice = createSlice({
  name: 'cropFace',
  initialState,
  reducers: {
    clearCropFace: () => (initialState)
  },
  extraReducers: (builder) => {
    builder
      .addCase(croppingFace.pending, (state) => ({
        ...state,
        isLoading: true
      }))
      .addCase(croppingFace.fulfilled, (state, action) => {
        state.isLoading = false;
        state.data = action.payload;
      })
      .addCase(croppingFace.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
      });
  },
});

export const { clearCropFace } = CropFaceSlice.actions;
export default CropFaceSlice.reducer;
