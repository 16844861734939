import React, { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useFormik } from 'formik';
import { useNavigate } from 'react-router-dom';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import FacebookLogin from '@greatsumini/react-facebook-login';
import { AuthFacebookThunk } from '../../store/auth/slice';
import { signUpValidationSchema } from '../../utils/validation';
import { SignUpThunk } from '../../store/signUp/slice';
import Page from '../../layout/Page/Page';
import Button from '../../components/Button/Button';
import Line from './components/Line';
import HaveAccountLink from './components/HaveAccountLink';
import Checkbox from '../../components/Checkbox/Checkbox';
import InputForm from './components/InputForm';

import '../../styles/authentificationStyles.scss';
import styles from './sass/SignUp.module.scss';

import SignUpIcon from '../../assets/icons/signInCircleWhite.svg';
import FacebookIcon from '../../assets/icons/facebookSquare.svg';

const SignUp = () => {
  const { t } = useTranslation('', { keyPrefix: 'signUp' });
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const {
    signUp: {
      isLoading: isLoadingSignUp
    },
    auth: {
      isLoading: isLoadingAuth
    }
  } = useSelector((state) => ({ signUp: state?.signUp, auth: state?.auth }), shallowEqual);

  const [passwordIsHidden, setPasswordIsHidden] = useState({ password: true, repeatPassword: true });

  const handleChangePasswordHidden = useCallback((inputName) => {
    setPasswordIsHidden((prevState) => ({ ...prevState, [inputName]: !prevState[inputName] }));
  }, [setPasswordIsHidden]);

  const {
    handleSubmit,
    values,
    handleChange,
    validateForm,
    errors,
    handleBlur,
  } = useFormik({
    initialValues: {
      name: '',
      email: '',
      password: '',
      repeatPassword: '',
      keepMeLoggedIn: false
    },
    onSubmit: (currentValues) => validateForm(currentValues).then(() => {
      dispatch(SignUpThunk({ values, navigate }));
    }),
    validationSchema: signUpValidationSchema,
    validateOnBlur: false,
    validateOnChange: false
  });

  return (
    <Page withoutFooter loader={isLoadingSignUp || isLoadingAuth}>
      <section className="authentificationContainer">
        <h1 className={styles.signUpHeading}>{t('signUp')}</h1>
        <InputForm
          values={values}
          errors={errors}
          handleBlur={handleBlur}
          handleChange={handleChange}
          passwordIsHidden={passwordIsHidden}
          onChangePasswordHidden={handleChangePasswordHidden}
        />
        <div className={styles.checkboxContainer}>
          <Checkbox
            label={t('keepSignedIn')}
            isChecked={values.keepMeLoggedIn}
            onChange={handleChange}
            name="keepMeLoggedIn"
          />
        </div>
        <div className="buttonContainer">
          <Button
            text={t('signUp')}
            isFilled
            iconRight={SignUpIcon}
            onClick={handleSubmit}
            type="submit"
          />
        </div>
        <Line />
        <div className="buttonContainer">
          <FacebookLogin
            appId={process.env.REACT_APP_FACEBOOK_ID}
            fields="name,email"
            scope="user_photos,public_profile,email"
            onSuccess={(facebook) => {
              dispatch(AuthFacebookThunk({ facebook, navigate }));
            }}
            render={({ onClick }) => (
              <Button
                text={t('facebookSignIn')}
                iconLeft={FacebookIcon}
                isFilled
                buttonStyle={styles.blueBtn}
                onClick={onClick}
              />
            )}
          />
        </div>
        <HaveAccountLink />
      </section>
    </Page>
  );
};

export default SignUp;
