import React from 'react';
import { Outlet, Navigate, useLocation } from 'react-router-dom';
import { shallowEqual, useSelector } from 'react-redux';
import { REDIRECT, SIGN_IN } from '../constants/links';

const RequireAuth = () => {
  const location = useLocation();

  const {
    auth: { tokens: { accessToken } },
  } = useSelector(({ auth, user }) => ({ auth, user }), shallowEqual);

  return accessToken ? <Outlet /> : <Navigate to={`${SIGN_IN}${REDIRECT}${location.pathname}${location.search}`} />;
};

export default RequireAuth;
