import React from 'react';
import PropTypes from 'prop-types';
import ButtonList from '../ButtonList/ButtonList';
import styles from './sass/GiveawayFilter.module.scss';

const ShopGiftoonFilter = ({
  categories, activeFilters, onClick
}) => {
  return (
    <div className={styles.giftoonFilterContainer}>
      <ButtonList
        data={categories}
        activeButtons={activeFilters}
        onClick={onClick}
      />
    </div>
  );
};

ShopGiftoonFilter.propTypes = {
  categories: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  activeFilters: PropTypes.arrayOf(PropTypes.string).isRequired,
  onClick: PropTypes.func.isRequired,
};
export default ShopGiftoonFilter;
