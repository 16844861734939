import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { HOMEPAGE } from '../../constants/links';
import NavBurger from './components/NavBurger';
import MobileNav from './components/MobileNav';
import UserButtons from './components/UserButtons';
import AuthButtons from './components/AuthButtons';
import NavigationLinks from './components/NavigationLinks';
import styles from './sass/Header.module.scss';

import GiftoonIcon from '../../assets/icons/giftoonPink.svg';

const Header = ({ transparent, containerStyles, loggedIn }) => {
  const { t } = useTranslation('', { keyPrefix: 'header' });
  const [showMenu, setShowMenu] = useState(false);

  const toggleShowMenu = useCallback(() => {
    setShowMenu((prevState) => !prevState);
  }, []);

  return (
    <header className={`${transparent ? styles.transparent : ''} ${containerStyles}`}>
      <div className={`container ${styles.container}`}>
        <div className={styles.logo}>
          <Link to={HOMEPAGE} className={styles.logoLink}>
            <img src={GiftoonIcon} alt={t('giftoon')} />
          </Link>
        </div>
        <NavigationLinks isLoggedIn={loggedIn} />
        {loggedIn ? <UserButtons /> : <AuthButtons />}
        <NavBurger onClick={toggleShowMenu} isColored={!transparent} />
      </div>
      <MobileNav showMenu={showMenu} loggedIn={loggedIn} />
    </header>
  );
};

Header.propTypes = {
  containerStyles: PropTypes.string,
  transparent: PropTypes.bool.isRequired,
  loggedIn: PropTypes.bool
};

Header.defaultProps = {
  containerStyles: '',
  loggedIn: false
};

export default Header;
