import React from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import styles from '../sass/MessageSent.module.scss';
import Button from '../../../components/Button/Button';

const MessageSent = ({ onClick }) => {
  const { t } = useTranslation('', { keyPrefix: 'contactUs' });

  return (
    <div className={styles.container}>
      <h1 className={styles.heading}>{t('yourMessageWasSent')}</h1>
      <p className={styles.description}>
        {t('weWillGetInTouch1')}
        <br />
        {t('weWillGetInTouch2')}
        <br />
        {t('weWillGetInTouch3')}
      </p>
      <Button buttonStyle={styles.okButton} text={t('ok')} isFilled onClick={onClick} />
    </div>
  );
};

MessageSent.propTypes = {
  onClick: PropTypes.func.isRequired,
};

export default MessageSent;
