import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import instance from '../../services/axios';
import { SHOP_GIVEAWAY_API } from '../../constants/api';

export const initialState = {
  error: null,
  isLoading: false,
  data: {
    giftoonUrl: null,
  },
};

export const SelectPhotoThunk = createAsyncThunk(
  'selectPhoto/selectPhotoThunk',
  async ({ id }, { rejectWithValue }) => {
    try {
      const { data } = await instance.get(`${SHOP_GIVEAWAY_API}${id}/`);
      return data;
    } catch ({ response: { data, status } }) {
      return rejectWithValue({ data, code: status });
    }
  }
);

export const SelectPhotoSlice = createSlice({
  name: 'selectPhoto',
  initialState,
  reducers: {
    clearSelectPhoto: () => (initialState)
  },
  extraReducers: (builder) => {
    builder
      .addCase(SelectPhotoThunk.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(SelectPhotoThunk.fulfilled, (state, action) => {
        state.data.giftoonUrl = action.payload.video;
        state.isLoading = false;
      })
      .addCase(SelectPhotoThunk.rejected, (state, action) => {
        state.error = action.payload;
        state.isLoading = false;
      });
  }
});

export const { clearSelectPhoto } = SelectPhotoSlice.actions;
export default SelectPhotoSlice.reducer;
