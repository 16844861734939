import React, { useEffect } from 'react';
import Page from '../../layout/Page/Page';
import './sass/Competition.scss';

const Competition = () => {
  useEffect(() => {
    const rtscript = document.createElement('script');
    rtscript.type = 'text/javascript';
    rtscript.src = 'https://app.viralsweep.com/vsa-widget-78fbb8-129983.js?sid=129983_831411';
    document.body.appendChild(rtscript);
    return () => document.body.removeChild(rtscript);
  }, []);

  return (
    <Page>
      <div id="vsscript_129983_831411" />
    </Page>
  );
};

export default Competition;
