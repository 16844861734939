import React, { useEffect, useMemo, useCallback } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { ShopBrandsThunk, ShopBrandCategoriesThunk } from '../../store/shopBrands/slice';
import { SHOP_BRANDS_LIMIT } from '../../constants/constants';
import { STEP_SELECT_BRAND } from '../../constants/links';
import filterButtonClick from '../../helpers/filterButtonClick';
import useDebounce from '../../hooks/useDebounce';
import Page from '../../layout/Page/Page';
import ShopBrandFilter from '../../components/ShopBrandFilter/ShopBrandFilter';
import Input from '../../components/Input/Input';
import BrandList from '../../components/BrandList/BrandList';
import styles from './sass/ShopBrands.module.scss';

import SearchIcon from '../../assets/icons/search.svg';
import CrossIcon from '../../assets/icons/cross.svg';

const ShopBrands = () => {
  const { t } = useTranslation('', { keyPrefix: 'shopBrands' });
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();

  const filter = useMemo(() => (
    searchParams.get('filter')
  ), [searchParams]);

  const search = useMemo(() => (
    searchParams.get('search')
  ), [searchParams]);

  const activeFilter = useMemo(() => (
    filter?.split(',') || ['all']
  ), [filter]);

  const {
    shopBrands: {
      data: { categories, brandList, next }
    }
  } = useSelector((state) => ({ shopBrands: state?.shopBrands }), shallowEqual);

  const searchDebounce = useDebounce(search, 500);

  const handleDispatchShopBrandsThunk = () => {
    dispatch(ShopBrandsThunk({
      limit: SHOP_BRANDS_LIMIT,
      search,
      filter: filter ?? [],
    }));
  };

  useEffect(() => {
    dispatch(ShopBrandCategoriesThunk({}));
  }, []);

  useEffect(() => {
    if (categories.length) {
      handleDispatchShopBrandsThunk();
    }
  }, [searchDebounce, filter, categories]);

  const handleFilterButtonClick = useCallback((item) => (
    filterButtonClick(item, activeFilter, searchParams, setSearchParams, filter)
  ), [filter, searchParams, setSearchParams, activeFilter]);

  const handleSearchChange = (input) => {
    if (!input) {
      searchParams.delete('search');
    } else {
      searchParams.set('search', input);
    }
    setSearchParams(searchParams.toString());
  };

  const handeSelectBrand = useCallback((brandName) => {
    navigate(`${STEP_SELECT_BRAND}/${brandName}`);
  }, []);

  return (
    <Page>
      <section className={styles.shopBrandsSection}>
        <h1>{t('shopBrands')}</h1>
        <p>{t('shopBrandsDescription')}</p>
        <div className={styles.couponInput}>
          <Input
            iconLeft={SearchIcon}
            iconRight={CrossIcon}
            onChange={(e) => handleSearchChange(e.target.value)}
            placeholder={t('searchBrands')}
            value={search ?? ''}
            onRightIconClick={() => setSearchParams({ search: '' })}
          />
        </div>
        <ShopBrandFilter
          categories={[{ name: 'all', description: 'All' }, ...categories]}
          activeFilters={activeFilter}
          onClick={handleFilterButtonClick}
        />
        <BrandList
          brandList={brandList}
          next={next}
          thunk={ShopBrandsThunk}
          onSelectBrand={handeSelectBrand}
        />
      </section>
    </Page>
  );
};

export default ShopBrands;
