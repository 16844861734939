const redirectToApp = (url) => {
  const platform = navigator?.userAgentData?.platform || navigator?.platform;
  if (platform.includes('iPhone')) {
    window.location.replace(url);
    setTimeout(() => {
      window.location = `${window.location.href}?appExist=false`;
    }, 25);
  }
};

export default redirectToApp;
