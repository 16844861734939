import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import styles from '../sass/StepSelectBrand.module.scss';
import { BRAND_DESCRIPTION_MAX_HEIGHT } from '../../../constants/constants';

const BrandDescription = ({ description }) => {
  const { t } = useTranslation('', { keyPrefix: 'selectBrands' });
  const [readMoreOpened, setReadMoreOpened] = useState(false);
  const [withReadMore, setWithReadMore] = useState(false);
  const descriptionText = useRef(null);

  useEffect(() => {
    setWithReadMore(descriptionText?.current?.clientHeight >= BRAND_DESCRIPTION_MAX_HEIGHT);
  }, []);

  return (
    <>
      <p ref={descriptionText} className={`${styles.giftDescription} ${readMoreOpened ? styles.readMore : ''}`}>
        {description}
      </p>
      { withReadMore && (
        <span
          role="presentation"
          onClick={() => setReadMoreOpened((prevState) => !prevState)}
          className={`${styles.readMoreButton} ${readMoreOpened ? styles.active : ''}`}
        >
          {t(readMoreOpened ? 'readLess' : 'readMore')}
        </span>
      )}
    </>
  );
};

BrandDescription.propTypes = {
  description: PropTypes.string.isRequired,
};

export default BrandDescription;
