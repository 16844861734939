import { BrowserRouter, Routes, Route } from 'react-router-dom';
import useInterceptor from './services/interceptor';

import MainRoute from './routes/MainRoute';
import GiftFlowRoute from './routes/GiftFlowRoute';
import RequireAuth from './routes/RequireAuth';
import {
  RESET_PASSWORD_SENT,
  RESET_PASSWORD,
  FORGOT_PASSWORD,
  SIGN_IN,
  SIGN_UP,
  VERIFY_EMAIL,
  HOMEPAGE,
  SHOP_BRANDS,
  SHOP_GIFTOONS,
  STEP_SELECT_BRAND,
  STEP_ADD_COUPON,
  STEP_ADD_GIFTOON,
  STEP_SELECT_GIFTOON,
  STEP_ADD_BRAND_LIST,
  STEP_ADD_GIFTOON_LIST,
  STEP_SEND_GIFTOON,
  STEP_SELECT_PHOTO,
  STEP_CHECK_PHOTO,
  STEP_CROP_PHOTO,
  PROFILE,
  GIFT_VIEW,
  CORPORATE,
  CONTACT_US,
  PURCHASE_COMPLETED,
  CHECKOUT,
  PRIVACY_POLICY,
  TERMS_AND_CONDITIONS,
  OUR_WHY,
  HOW_IT_WORKS,
  GIFT_SHARE_VIEW,
  RECEIVED_GIFTOON,
  NON_EXISTING_PAGE,
  COMPETITION
} from './constants/links';
import ErrorPage from './pages/ErrorPage/ErrorPage';
import ResetPassword from './pages/ResetPassword/ResetPassword';
import ForgotPassword from './pages/ForgotPassword/ForgotPassword';
import ResetPasswordSent from './pages/ResetPasswordSent/ResetPasswordSent';
import VerifyEmail from './pages/VerifyEmail/VerifyEmail';
import ShopBrands from './pages/ShopBrands/ShopBrands';
import ShopGiftoons from './pages/ShopGiftoons/ShopGiftoons';
import SignUp from './pages/SignUp/SignUp';
import SignIn from './pages/SignIn/SignIn';
import Home from './pages/Home/Home';
import StepSelectBrand from './pages/StepSelectBrand/StepSelectBrand';
import StepAddBrandList from './pages/StepAddBrandList/StepAddBrandList';
import StepAddGiveawayList from './pages/StepAddGiveawayList/StepAddGiveawayList';
import StepSendGiftoon from './pages/StepSendGiftoon/StepSendGiftoon';
import StepSelectPhoto from './pages/StepSelectPhoto/StepSelectPhoto';
import StepSelectedGiveaway from './pages/StepSelectedGiveaway/StepSelectedGiveaway';
import Profile from './pages/Profile/Profile';
import Corporate from './pages/Corporate/Corporate';
import GiftView from './pages/GiftView/GiftView';
import PurchaseCompleted from './pages/PurchaseCompleted/PurchaseCompleted';
import Checkout from './pages/Checkout/Checkout';
import ContactUs from './pages/ContactUs/ContactUs';
import StepCropPhoto from './pages/StepCropPhoto/StepCropPhoto';
import StepCheckPhoto from './pages/StepCheckPhoto/StepCheckPhoto';
import PrivacyPolicy from './pages/PrivacyPolicy/PrivacyPolicy';
import TermsAndConditions from './pages/TermsAndConditions/TermsAndConditions';
import OurWhy from './pages/OurWhy/OurWhy';
import HowItWorks from './pages/HowItWorks/HowItWorks';
import GiftShareView from './pages/GiftShareView/GiftShareView';
import ReceivedGiftoon from './pages/ReceivedGiftoon/ReceivedGiftoon';
import Competition from './pages/Competition/Competition';
import store from './store/store';
import './styles/globals.scss';

function App() {
  useInterceptor(store);
  return (
    <BrowserRouter>
      <Routes>
        <Route element={<MainRoute />}>

          <Route path={NON_EXISTING_PAGE} element={<ErrorPage />} />

          <Route element={<RequireAuth />}>
            <Route path={PROFILE} element={<Profile />} />
          </Route>

          <Route path={HOMEPAGE} element={<Home />} />
          <Route path={SIGN_IN} element={<SignIn />} />
          <Route path={SIGN_UP} element={<SignUp />} />
          <Route path={RESET_PASSWORD} element={<ResetPassword />} />
          <Route path={FORGOT_PASSWORD} element={<ForgotPassword />} />
          <Route path={RESET_PASSWORD_SENT} element={<ResetPasswordSent />} />
          <Route path={VERIFY_EMAIL} element={<VerifyEmail />} />
          <Route path={SHOP_BRANDS} element={<ShopBrands />} />
          <Route path={SHOP_GIFTOONS} element={<ShopGiftoons />} />

          <Route element={<GiftFlowRoute />}>
            <Route path={`${STEP_SELECT_BRAND}/:id`} element={<StepSelectBrand stepNumber={1} />} />
            <Route path={`${STEP_SELECT_GIFTOON}/:id`} element={<StepSelectedGiveaway stepNumber={1} />} />
            <Route path={STEP_ADD_BRAND_LIST} element={<StepAddBrandList />} />
            <Route path={STEP_ADD_GIFTOON_LIST} element={<StepAddGiveawayList />} />
            <Route path={`${STEP_ADD_COUPON}/:id`} element={<StepSelectBrand stepNumber={2} />} />
            <Route path={`${STEP_ADD_GIFTOON}/:id`} element={<StepSelectedGiveaway stepNumber={2} />} />
            <Route path={STEP_SELECT_PHOTO} element={<StepSelectPhoto />} />
            <Route path={STEP_CHECK_PHOTO} element={<StepCheckPhoto />} />
            <Route path={STEP_CROP_PHOTO} element={<StepCropPhoto />} />
            <Route path={STEP_SEND_GIFTOON} element={<StepSendGiftoon />} />
            <Route path={CHECKOUT} element={<Checkout />} />
          </Route>

          <Route path={`${GIFT_VIEW}/:hash`} element={<GiftView />} />
          <Route path={`${RECEIVED_GIFTOON}/:hash`} element={<ReceivedGiftoon />} />
          <Route path={`${GIFT_SHARE_VIEW}/:hash`} element={<GiftShareView />} />

          <Route path={`${PURCHASE_COMPLETED}/:orderId`} element={<PurchaseCompleted />} />

          <Route path={CORPORATE} element={<Corporate />} />
          <Route path={CONTACT_US} element={<ContactUs />} />

          <Route path={PRIVACY_POLICY} element={<PrivacyPolicy />} />
          <Route path={TERMS_AND_CONDITIONS} element={<TermsAndConditions />} />
          <Route path={OUR_WHY} element={<OurWhy />} />
          <Route path={HOW_IT_WORKS} element={<HowItWorks />} />

          <Route path={COMPETITION} element={<Competition />} />
        </Route>
      </Routes>
    </BrowserRouter>
  );
}

export default App;
