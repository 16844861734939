import React from 'react';
import PropTypes from 'prop-types';
import InfiniteScroll from 'react-infinite-scroll-component';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import secondsToTimeString from '../../helpers/secondsToTimeString';
import GiftoonCard from '../GiftoonCard/GiftoonCard';
import styles from './sass/GiveawayList.module.scss';

import GiftoonSmileIcon from '../../assets/icons/giftoonSmile.svg';

const GiveawayList = ({
  giveawayList, next, thunk, onSelectGiveaway
}) => {
  const { t } = useTranslation('', { keyPrefix: 'shopGiftoons' });
  const dispatch = useDispatch();

  const loadNextGiveaway = () => {
    dispatch(thunk({ url: next }));
  };

  return (
    <InfiniteScroll
      next={loadNextGiveaway}
      hasMore={next}
      dataLength={giveawayList.length}
      className={styles.giftoonList}
      loader={<img className={styles.giftoonSmile} src={GiftoonSmileIcon} alt={t('giftoon')} />}
    >
      {giveawayList?.map(({
        id,
        persons,
        video_length: videoLength,
        preview_image: previewImage,
      }) => (
        <GiftoonCard
          key={id}
          id={id}
          persons={persons}
          time={secondsToTimeString(videoLength)}
          image={previewImage}
          onSelect={onSelectGiveaway}
        />
      ))}
    </InfiniteScroll>
  );
};

GiveawayList.propTypes = {
  giveawayList: PropTypes.arrayOf(PropTypes.shape({})),
  next: PropTypes.string,
  thunk: PropTypes.func.isRequired,
  onSelectGiveaway: PropTypes.func.isRequired,
};

GiveawayList.defaultProps = {
  giveawayList: [],
  next: ''
};

export default GiveawayList;
