import React, { useCallback, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { ShopGiveawayCategoriesThunk, ShopGiveawayThunk } from '../../store/shopGiveaway/slice';
import { SHOP_GIVEAWAY_LIMIT } from '../../constants/constants';
import GiveawayFilter from '../../components/GiveawayFilter/GiveawayFilter';
import GiveawayList from '../../components/GiveawayList/GiveawayList';
import Page from '../../layout/Page/Page';
import filterButtonClick from '../../helpers/filterButtonClick';
import styles from './sass/ShopGiftoons.module.scss';
import { STEP_SELECT_GIFTOON } from '../../constants/links';

const ShopGiftoons = () => {
  const { t } = useTranslation('', { keyPrefix: 'shopGiftoons' });
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();

  const filter = useMemo(() => (
    searchParams.get('filter')
  ), [searchParams]);

  const activeFilter = useMemo(() => (
    filter?.split(',') || ['all']
  ), [filter]);

  const {
    shopGiveaway: {
      data: { categories, giveawayList, next }
    }
  } = useSelector((state) => ({ shopGiveaway: state?.shopGiveaway }), shallowEqual);

  useEffect(() => {
    dispatch(ShopGiveawayCategoriesThunk({}));
  }, []);

  useEffect(() => {
    if (categories.length) {
      dispatch(ShopGiveawayThunk({
        limit: SHOP_GIVEAWAY_LIMIT,
        filter: filter ?? [],
      }));
    }
  }, [filter, categories]);

  const handleFilterButtonClick = useCallback((item) => (
    filterButtonClick(item, activeFilter, searchParams, setSearchParams, filter)
  ), [filter, searchParams, setSearchParams, activeFilter]);

  const handeSelectGiveaway = useCallback((id) => {
    navigate(`${STEP_SELECT_GIFTOON}/${id}`);
  }, []);

  return (
    <Page>
      <section className={styles.shopGiftoonsSection}>
        <h1>{t('chooseGiftoon')}</h1>
        <p>{t('description')}</p>
        <GiveawayFilter
          categories={[{ name: 'all', description: 'All' }, ...categories]}
          activeFilters={activeFilter}
          onClick={handleFilterButtonClick}
        />
        <GiveawayList
          giveawayList={giveawayList}
          next={next}
          thunk={ShopGiveawayThunk}
          onSelectGiveaway={handeSelectGiveaway}
        />
      </section>
    </Page>
  );
};

export default ShopGiftoons;
