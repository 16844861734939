import React, {
  useEffect, useMemo, useState,
} from 'react';
import { useTranslation } from 'react-i18next';
// import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useFormik } from 'formik';
import { checkoutCardValidation } from '../../utils/validation';
import { STEP_SEND_GIFTOON } from '../../constants/links';
import getTaxPrice from '../../helpers/getTaxPrice';
import getFullPrice from '../../helpers/getFullPrice';
import { CheckoutGetUserThunk, CheckoutPinPayChargeThunk, CheckPromoField } from '../../store/checkout/slice';
import notify from '../../utils/notify';
import PinPay from '../../components/PinPay/PinPay';
import ButtonLink from '../../components/ButtonLink/ButtonLink';
// import PayPalButton from '../../components/PayPalButton/PayPalButton';
import SendingPage from '../../layout/SendingPage/SendingPage';
import PriceBlock from './components/PriceBlock';
import GiftoonInfo from './components/GiftoonInfo';
import Button from '../../components/Button/Button';
import styles from './sass/Checkout.module.scss';

import CardIcon from '../../assets/icons/creditCard.svg';
import ArrowLeftIcon from '../../assets/icons/arrowLeftPink.svg';
import LockIcon from '../../assets/icons/lock-black.svg';
import MasterIcon from '../../assets/icons/mastercardLogo.svg';
import VisaIcon from '../../assets/icons/visa.svg';
import AppleIcon from '../../assets/icons/applepay.svg';
import GoogleIcon from '../../assets/icons/googlePay.svg';
import { NOT_FOUND_ANSWER } from '../../constants/api';

/* global Pin */
const Checkout = () => {
  const { t } = useTranslation('', { keyPrefix: 'checkout' });
  // const navigate = useNavigate();
  const dispatch = useDispatch();

  const [verificationCard, setVerificationCard] = useState(false);
  const [promoCodePercentage, setPromoCodePercentage] = useState(null);
  const [promoCodeError, setPromoCodeError] = useState(null);

  const {
    giftReceiver: {
      email,
      sendAt,
      firstName,
      lastName,
      message,
      phone,
      senderName,
      toWhom,
    },
    giftFlow: {
      savedData: {
        price: giftoonPrice,
        brandName,
        giveawayName,
        giveawayId,
        brandId,
        imageId,
        image,
        faceId,
        buyJustCoupon,
        tcnActivationFee,
      }, data: {
        id: giftoonId,
        brand: {
          image_url: ImageUrl
        }
      }
    },
    profile: { data: { id: userId } },
    auth: { tokens: { accessToken } },
    checkout: { isLoading },
    saveCard: { data: { creditCard } },
  } = useSelector((state) => ({
    giftReceiver: state.giftReceiver,
    giftFlow: state.giftFlow,
    profile: state.profile,
    checkout: state.checkout,
    auth: state.auth,
    saveCard: state.saveCard,
  }));
  const taxPrice = useMemo(() => (`${getTaxPrice(giftoonPrice)}`), [giftoonPrice]);
  const totalPrice = useMemo(() => {
    return getFullPrice(giftoonPrice, tcnActivationFee, promoCodePercentage);
  }, [giftoonPrice, taxPrice, tcnActivationFee, promoCodePercentage]);

  const {
    handleSubmit,
    values: {
      cardNumber, expiry, cvc, address, country, city, name, emailField
    },
    handleChange,
    validateForm,
    errors,
    handleBlur,
  } = useFormik({
    initialValues: {
      cardNumber: creditCard,
      expiry: '',
      cvc: '',
      address: '',
      country: '',
      city: '',
      name: '',
      emailField: '',
    },
    onSubmit: (values) => validateForm(values).then(() => {
      setVerificationCard(true);
      const pinApi = new Pin.Api(process.env.REACT_APP_PIN_PAY_PK, process.env.REACT_APP_PIN_PAY_MODE);

      const expiryMonth = values.expiry.split('/')[0];
      const expiryYear = values.expiry.split('/')[1];

      const card = {
        number: values.cardNumber,
        expiry_month: expiryMonth,
        expiry_year: expiryYear,
        cvc: values.cvc,
        name: values.name,
        address_line1: values.address,
        address_city: values.city,
        address_country: values.country,
      };

      pinApi.createCardToken(card).then(
        ({ token }) => {
          setVerificationCard(false);
          dispatch(CheckoutPinPayChargeThunk({ userEmail: emailField, giftoonId, cardToken: token }));
        },
        (error) => {
          setVerificationCard(false);
          const { messages: errorMessages } = error;
          errorMessages.forEach(({ message: errorMessage }) => notify('error', errorMessage));
        }
      ).done();
    }),
    enableReinitialize: true,
    validationSchema: checkoutCardValidation,
    validateOnBlur: false,
    validateOnChange: false
  });

  // const handleCreateOrder = useCallback(() => {}, []);
  // const handleCancel = useCallback(() => notify('error', 'Payment has been canceled'), []);
  // const handleError = useCallback(() => notify('error', 'Something went wrong'), []);
  //
  // const handleApprove = useCallback((data) => {
  //   navigate(`${PURCHASE_COMPLETED}/${data.orderID}`);
  //   window.gtag('event', 'purchase', {
  //     transaction_id: data.orderID,
  //     affiliation: 'Giftoon',
  //     value: +totalPrice,
  //     tax: +taxPrice,
  //     currency: 'AUD',
  //     items: [
  //       {
  //         item_name: `${giveawayName} + ${brandName}`,
  //         item_brand: brandName,
  //         item_category: giveawayName,
  //         currency: 'AUD',
  //         price: +giftoonPrice,
  //         quantity: 1
  //       }]
  //   });
  // }, []);

  const buttonLeft = useMemo(() => (
    <ButtonLink
      buttonStyle={styles.button}
      link={STEP_SEND_GIFTOON}
      text={t('back')}
      iconLeft={ArrowLeftIcon}
    />
  ), []);

  const checkoutShouldBeRendered = useMemo(() => (
    (buyJustCoupon && brandId) || (giveawayId && brandId && imageId && faceId)
  ), [giveawayId, brandId, imageId, faceId]);

  const handlePromo = (promo) => {
    if (promoCodePercentage !== null) {
      setPromoCodePercentage(null);
    } else {
      dispatch(CheckPromoField({ code: promo })).then((res) => {
        if (res?.payload?.id && !res?.payload?.used) {
          setPromoCodeError(null);
          setPromoCodePercentage(res.payload.discount);
        } else if (res?.payload?.detail === NOT_FOUND_ANSWER) {
          setPromoCodeError('invalid promo code');
        }
      });
    }
  };

  const handleResetError = () => {
    setPromoCodeError(null);
  };

  useEffect(() => {
    const rtscript = document.createElement('script');
    rtscript.type = 'text/javascript';
    rtscript.src = 'https://cdn.pinpayments.com/pin.v2.js';
    document.body.appendChild(rtscript);
    return () => document.body.removeChild(rtscript);
  }, []);

  useEffect(() => {
    if (accessToken) {
      dispatch(CheckoutGetUserThunk({ id: userId }));
    }
  }, []);

  return (
    <SendingPage stepName={t('payGiftoon')} buttonLeft={buttonLeft} loader={verificationCard || isLoading}>
      {checkoutShouldBeRendered && (
      <section className={styles.section}>
        <div className={styles.infoContainer}>
          <h2 className={styles.infoHeading}>{t('orderSummary')}</h2>
          <GiftoonInfo
            imageUrl={ImageUrl}
            imageId={imageId}
            giftoonName={giveawayName}
            couponName={brandName}
            image={image}
            firstName={firstName}
            lastName={lastName}
            phone={phone}
            email={email}
            message={message}
            couponPrice={`${giftoonPrice}`}
            date={JSON.parse(sendAt) ? new Date(JSON.parse(sendAt)) : null}
            senderName={senderName}
            toWhom={toWhom}
          />
        </div>
        <div className={styles.checkoutContainer}>
          <h2 className={styles.checkoutHeading}>{t('checkout')}</h2>
          <PriceBlock
            giftoonPrice={`${giftoonPrice}`}
            taxPrice={taxPrice}
            tcnActivationFee={tcnActivationFee}
            totalPrice={totalPrice}
            handleEvent={handlePromo}
            promoCodePercentage={promoCodePercentage}
            promoCodeError={promoCodeError}
            handleResetError={handleResetError}
          />
          <PinPay
            handleChange={handleChange}
            handleBlur={handleBlur}
            errors={errors}
            cardNumber={cardNumber}
            cvc={cvc}
            expiry={expiry}
            name={name}
            address={address}
            city={city}
            country={country}
            emailField={emailField}
          />
          <div className={styles.payButtonsContainer}>
            <Button
              buttonStyle={styles.payButton}
              iconRight={CardIcon}
              text={t('payNow')}
              isFilled
              onClick={handleSubmit}
            />
            <div className={styles.payIcons}>
              <div className={styles.visa}>
                <img src={VisaIcon} alt="visa" />
              </div>
              <div className={styles.apple}>
                <img src={AppleIcon} alt="applePay" />
              </div>
              <div className={styles.mastercard}>
                <img src={MasterIcon} alt="masterCard" />
              </div>
              <div className={styles.google}>
                <img src={GoogleIcon} alt="GooglePay" />
              </div>
            </div>

            {/* <PayPalButton */}
            {/*  onApprove={handleApprove} */}
            {/*  onCreateOrder={handleCreateOrder} */}
            {/*  onCancel={handleCancel} */}
            {/*  onError={handleError} */}
            {/*  price={totalPrice} */}
            {/*  giftoonId={giftoonId} */}
            {/* /> */}
          </div>
          <div className={styles.secureBlock}>
            <img src={LockIcon} alt="icon" />
            Secure Checkout
          </div>
        </div>

      </section>
      )}
    </SendingPage>
  );
};

export default Checkout;
