import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { facebookShareLinkCreator } from '../../../helpers/facebook';
import popupCenter from '../../../helpers/popupCenter';
import Modal from '../../Modal/Modal';
import Button from '../../Button/Button';
import styles from '../sass/ShareGiftoonModal.module.scss';

import FacebookIcon from '../../../assets/icons/facebookCircle.svg';
import CopyIcon from '../../../assets/icons/copyWhite.svg';
import EmailIcon from '../../../assets/icons/email.svg';
import CrossIcon from '../../../assets/icons/circleCross.svg';

const ShareGiftoonModal = ({
  giftoonUrl, modalIsOpened, closeModal, openEmailModal
}) => {
  const { t } = useTranslation('', { keyPrefix: 'giftView' });
  const facebookShareUrl = facebookShareLinkCreator(giftoonUrl, t('giftoonShareText'));

  return (
    <Modal isOpen={modalIsOpened} onClose={closeModal}>
      <div className={styles.modalContainer}>
        <div className={styles.modalHeader}>
          <h3>{t('shareVideo')}</h3>
          <button className={styles.closeButton} onClick={closeModal}>
            <img src={CrossIcon} alt="close" />
          </button>
        </div>
        <Button
          buttonStyle={styles.shareButton}
          onClick={() => popupCenter(facebookShareUrl, t('giftoonShareText'), 700, 500)}
          text={t('facebookShare')}
          iconLeft={FacebookIcon}
          isFilled
        />
        <Button
          buttonStyle={styles.shareButton}
          onClick={() => navigator.clipboard.writeText(giftoonUrl)}
          text={t('copyLink')}
          iconLeft={CopyIcon}
          isFilled
        />
        <Button
          buttonStyle={styles.shareButton}
          onClick={openEmailModal}
          iconLeft={EmailIcon}
          text={t('sendByEmail')}
          isFilled
        />
      </div>
    </Modal>
  );
};

ShareGiftoonModal.propTypes = {
  giftoonUrl: PropTypes.string.isRequired,
  modalIsOpened: PropTypes.bool.isRequired,
  closeModal: PropTypes.func.isRequired,
  openEmailModal: PropTypes.func.isRequired,
};

export default ShareGiftoonModal;
