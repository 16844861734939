import React from 'react';
import PropTypes from 'prop-types';
import { GIFT_VIEW_MODALS as MODALS } from '../../constants/constants';
import ShareGiftoonModal from './components/ShareGiftoonModal';
import EmailShareModal from './components/EmailShareModal';

const GiftViewModals = ({ giftoonUrl, activeModal, setActiveModal }) => {
  return (
    <>
      <ShareGiftoonModal
        giftoonUrl={giftoonUrl}
        modalIsOpened={activeModal === MODALS.SHARE_MODAL}
        closeModal={() => setActiveModal('')}
        openEmailModal={() => setActiveModal(MODALS.EMAIL_MODAL)}
      />
      <EmailShareModal
        giftoonUrl={giftoonUrl}
        modalIsOpened={activeModal === MODALS.EMAIL_MODAL}
        closeModal={() => setActiveModal(MODALS.SHARE_MODAL)}
      />
    </>
  );
};

GiftViewModals.propTypes = {
  giftoonUrl: PropTypes.string.isRequired,
  activeModal: PropTypes.string.isRequired,
  setActiveModal: PropTypes.func.isRequired,
};

export default GiftViewModals;
