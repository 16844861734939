import React from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useFormik } from 'formik';
import { profileMyInfoValidation } from '../../../utils/validation';
import { ProfileUpdateUserDataThunk } from '../../../store/profile/slice';
import Input from '../../../components/Input/Input';
import Button from '../../../components/Button/Button';
import styles from '../sass/MyInfoSection.module.scss';

const MyInfoSection = ({ fullName: defaultFullName, email: defaultEmail }) => {
  const { t } = useTranslation('', { keyPrefix: 'profileSettings' });
  const { id } = useSelector(({ profile }) => profile.data);
  const dispatch = useDispatch();

  const {
    handleSubmit,
    values: {
      fullName, email
    },
    handleChange,
    validateForm,
    errors,
    handleBlur,
    dirty,
  } = useFormik({
    enableReinitialize: true,
    initialValues: {
      fullName: defaultFullName,
      email: defaultEmail
    },
    onSubmit: (values) => validateForm(values).then(() => {
      dispatch(ProfileUpdateUserDataThunk({ fullName, email, id }));
    }),
    validationSchema: profileMyInfoValidation,
    validateOnBlur: false,
    validateOnChange: false
  });

  return (
    <div className={styles.myInfoSection}>
      <h2 className={styles.myInfoHeading}>{t('myInfo')}</h2>
      <div className={styles.inputContainer}>
        <Input
          value={fullName || ''}
          name="fullName"
          label={t('yourName')}
          onChange={handleChange}
          error={errors.fullName}
          onBlur={handleBlur}
        />
        <Input
          value={email || ''}
          name="email"
          label={t('yourEmail')}
          onChange={handleChange}
          error={errors.email}
          onBlur={handleBlur}
        />
      </div>
      <Button onClick={handleSubmit} buttonStyle={styles.saveButton} text={t('saveProfile')} isDisabled={!dirty} />
    </div>
  );
};

MyInfoSection.propTypes = {
  fullName: PropTypes.string,
  email: PropTypes.string,
};

MyInfoSection.defaultProps = {
  fullName: '',
  email: '',
};

export default MyInfoSection;
