import * as Yup from 'yup';

const PASSWORD_REGEX = /^(?=.{8,})/;

export const CONTAIN_EMOJI = new RegExp('(\u00a9|\u00ae|[\u2000-\u3300]|\ud83c[\ud000-\udfff]'
  + '|\ud83d[\ud000-\udfff]|\ud83e[\ud000-\udfff])');

export const loginValidationSchema = Yup.object().shape({
  email: Yup.string().email('Invalid email').required('Required'),
  password: Yup.string().required('Required')
});

export const signUpValidationSchema = Yup.object().shape({
  name: Yup.string().required('Field is required'),
  email: Yup.string().email('Invalid email').required('Field is required'),
  password: Yup
    .string()
    .required('Field is required')
    .matches(
      PASSWORD_REGEX,
      'Must Contain 8 Characters'
    ),
  repeatPassword: Yup
    .string()
    .required('Field is required')
    .matches(
      PASSWORD_REGEX,
      'Must Contain 8 Characters'
    )
    .oneOf([Yup.ref('password')], 'Passwords do not match'),
});

export const strongPasswordValidator = Yup.object().shape({
  password: Yup
    .string()
    .required('Required')
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{8,})/,
      'Must Contain 8 Characters,'
      + ' One Uppercase, One Lowercase,'
      + ' One Number'
    ),
});

export const restorePasswordValidationSchema = Yup.object().shape({
  email: Yup.string().email('Invalid email').required('Required'),
});

export const forgotPasswordValidationSchema = Yup.object().shape({
  password: Yup
    .string()
    .required('Field is required')
    .matches(
      PASSWORD_REGEX,
      'Must Contain 8 Characters'
    ),
  repeatPassword: Yup
    .string()
    .required('Field is required')
    .matches(
      PASSWORD_REGEX,
      'Must Contain 8 Characters'
    )
    .oneOf([Yup.ref('password')], 'Passwords do not match'),
});

export const sendGiftoonValidationSchema = Yup.object().shape({
  receiverFirstName: Yup.string().required('Field is required'),
  receiverLastName: Yup.string().required('Field is required'),
  phone: Yup.string().min(12, 'Invalid phone number').required('Field is required'),
  recipientEmail: Yup.string().trim().email('Invalid email').required('Field is required'),
  isLoggedIn: Yup.boolean(),
  senderEmail: Yup.string()
    .trim()
    .email('Invalid email')
    .when('isLoggedIn', { is: false, then: Yup.string().required('Field is required') }),
  senderName: Yup.string()
    .when('isLoggedIn', { is: false, then: Yup.string().required('Field is require') }),
}, [['phone', 'recipientEmail']]);

export const profileMyInfoValidation = Yup.object().shape({
  fullName: Yup.string().required('Name is required'),
  email: Yup.string().email('Invalid email').required('Email is required')
});

export const checkoutCardValidation = Yup.object().shape({
  cardNumber: Yup.string().required('Card number is required'),
  expiry: Yup.string().required('Card expiry is required'),
  cvc: Yup.string().required('CVC is required'),
  name: Yup.string().required('Name on Card is required'),
  emailField: Yup.string().required('Email is required'),
});

export const creditCardValidation = Yup.object().shape({
  cardNumber: Yup.string().min(19, 'Card number must be longer'),
  cardDate: Yup.string().min(5, 'Card number must be longer'),
});

export const profileChangePasswordValidation = Yup.object().shape({
  currentPassword: Yup.string().required('Field is required'),
  newPassword: Yup
    .string()
    .required('Field is required')
    .min(8, 'Password must contain 8 Characters'),
  confirmNewPassword: Yup
    .string()
    .required('Field is required')
    .min(8, 'Password must contain 8 Characters')
    .oneOf([Yup.ref('newPassword')], 'Passwords do not match'),
});

export const sendEmailValidation = Yup.object().shape({
  url: Yup.string(),
  senderName: Yup.string().required('Field is required'),
  recipientsEmails: Yup.string().email('Invalid email').required('Field is required'),
});

export const contactUsValidation = Yup.object().shape({
  name: Yup.string().required('Field is required'),
  email: Yup.string().email('Invalid email').required('Email is required'),
  message: Yup.string().required('Field is required'),
});

export const checkStringEmoji = (string) => CONTAIN_EMOJI.test(string);
