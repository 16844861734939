import React from 'react';
import PropTypes from 'prop-types';
import { checkStringEmoji } from '../../utils/validation';
import Button from '../Button/Button';
import styles from './sass/ButtonList.module.scss';

const ButtonList = ({
  data, activeButtons, onClick
}) => {
  return (
    <div className={styles.buttonList}>
      {data.map(({ description, name, icon }) => {
        const isActive = activeButtons.some((active) => (name === active));
        return (
          <Button
            iconLeft={!checkStringEmoji(description) ? icon : undefined}
            isFilled={isActive}
            buttonStyle={isActive ? styles.activeButton : ''}
            text={description}
            key={`Button_${description}`}
            onClick={() => onClick(name)}
          />
        );
      })}
    </div>
  );
};

ButtonList.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string,
    })
  ).isRequired,
  activeButtons: PropTypes.arrayOf(PropTypes.string).isRequired,
  onClick: PropTypes.func,
};

ButtonList.defaultProps = {
  onClick: () => {},
};

export default ButtonList;
