import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import instance from '../../services/axios';
import { RECEIVED_GIFTOONS_API } from '../../constants/api';
import { PROFILE_WALLET_LIMIT } from '../../constants/constants';

export const initialState = {
  next: `${RECEIVED_GIFTOONS_API}?limit=${PROFILE_WALLET_LIMIT}`,
  error: null,
  isLoading: false,
  data: [],
};

export const ReceivedGiftoonsThunk = createAsyncThunk(
  'receivedGiftoons/receivedGiftoonsThunk',
  async ({ next }, { rejectWithValue, getState }) => {
    try {
      const { receivedGiftoons: { data: previousGiftoons } } = getState();
      const { data } = await instance.get(next);
      return { results: [...previousGiftoons, ...data.results], next: data?.next };
    } catch ({ response: { data } }) {
      return rejectWithValue(data);
    }
  }
);

export const ReceivedGiftoonsSlice = createSlice({
  name: 'receivedGiftoons',
  initialState,
  reducers: {
    clearReceivedGiftoon: () => (initialState)
  },
  extraReducers: (builder) => {
    builder
      .addCase(ReceivedGiftoonsThunk.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(ReceivedGiftoonsThunk.fulfilled, (state, action) => {
        state.isLoading = false;
        state.data = action.payload.results;
        state.next = action?.payload?.next;
      })
      .addCase(ReceivedGiftoonsThunk.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
      });
  }
});

export const { clearReceivedGiftoon } = ReceivedGiftoonsSlice.actions;
export default ReceivedGiftoonsSlice.reducer;
