import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import PriceLine from './PriceLine';
import PromoCodeLine from './PromoCodeLine';
import styles from '../sass/PriceBlock.module.scss';

const PriceBlock = ({
  giftoonPrice, taxPrice, tcnActivationFee, totalPrice, handleEvent,
  promoCodePercentage, promoCodeError, handleResetError,
}) => {
  const { t } = useTranslation('', { keyPrefix: 'checkout' });

  return (
    <div className={styles.priceBlock}>
      <PriceLine price={giftoonPrice} text={`${t('eGiftCard')} (${t('noFee')})`} />

      <PromoCodeLine
        text={t('enterPromoCode')}
        handleEvent={handleEvent}
        promoCodePercentage={promoCodePercentage}
        promoCodeError={promoCodeError}
        handleResetError={handleResetError}
      />

      <hr className={styles.hr} />
      <PriceLine price={taxPrice} text={t('bankFee')} />
      {tcnActivationFee && <PriceLine price={`${tcnActivationFee}`} text={t('tcnActivationFee')} />}
      {promoCodePercentage && <PriceLine price={promoCodePercentage} text={t('discount')} isPercentage />}
      <hr />
      <PriceLine price={totalPrice} text={t('total')} isTotalPrice />
    </div>
  );
};

PriceBlock.propTypes = {
  giftoonPrice: PropTypes.string.isRequired,
  taxPrice: PropTypes.string.isRequired,
  tcnActivationFee: PropTypes.number,
  totalPrice: PropTypes.string.isRequired,
  handleEvent: PropTypes.func,
  handleResetError: PropTypes.func,
  promoCodePercentage: PropTypes.number,
  promoCodeError: PropTypes.string,
};

PriceBlock.defaultProps = {
  tcnActivationFee: null,
  handleEvent: () => {},
  handleResetError: () => {},
  promoCodePercentage: null,
  promoCodeError: null,
};

export default PriceBlock;
