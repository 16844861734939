import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import ReactPlayer from 'react-player';
import { VIDEO_STATES } from '../../constants/constants';
import styles from './VideoPlayer.module.scss';

import PlayIcon from '../../assets/icons/playVideo.svg';

const VideoPlayer = ({
  customPlayIcon,
  video,
  videoContainerStyles,
  playerStyles,
  repeat,
  controlsShown,
  loop,
  muted,
  autoplay,
  fullscreenDisabled,
  onVideoFinished,
  onLoadedData
}) => {
  const [videoProgress, setVideoProgress] = useState(VIDEO_STATES.NOT_PLAYED);

  const handlePlayVideo = () => {
    setVideoProgress(VIDEO_STATES.PLAYING);
  };

  const handleStopVideo = () => {
    setVideoProgress(VIDEO_STATES.PAUSED);
  };

  useEffect(() => {
    if (repeat) {
      setVideoProgress(VIDEO_STATES.PLAYING);
    }
  }, [repeat]);

  const playerConfig = {
    file: {
      attributes: {
        disablePictureInPicture: true,
        controlsList: 'nodownload noremoteplayback noplaybackrate disablepictureinpicture'
      }
    }
  };

  return (
    <div className={`${styles.videoContainer} ${videoContainerStyles}`}>
      <ReactPlayer
        url={video}
        muted={muted}
        playsinline={autoplay}
        playing={autoplay || videoProgress === VIDEO_STATES.PLAYING}
        controls={controlsShown && videoProgress !== VIDEO_STATES.NOT_PLAYED}
        loop={loop}
        onPlay={handlePlayVideo}
        onPause={handleStopVideo}
        onEnded={onVideoFinished}
        config={playerConfig}
        className={`${styles.reactPlayer} ${fullscreenDisabled ? styles.disableFullscreen : ''} ${playerStyles}`}
        width="100%"
        height="100%"
        onLoadedData={onLoadedData}
      />
      {videoProgress !== VIDEO_STATES.PLAYING && controlsShown && (
        <button className={styles.playButton} onClick={handlePlayVideo}>
          <img
            src={customPlayIcon || PlayIcon}
            alt="play"
          />
        </button>
      )}
    </div>
  );
};

VideoPlayer.propTypes = {
  customPlayIcon: PropTypes.string,
  video: PropTypes.string.isRequired,
  videoContainerStyles: PropTypes.string,
  playerStyles: PropTypes.string,
  repeat: PropTypes.bool,
  controlsShown: PropTypes.bool,
  loop: PropTypes.bool,
  muted: PropTypes.bool,
  autoplay: PropTypes.bool,
  fullscreenDisabled: PropTypes.bool,
  onVideoFinished: PropTypes.func,
  onLoadedData: PropTypes.func,
};

VideoPlayer.defaultProps = {
  customPlayIcon: '',
  videoContainerStyles: '',
  playerStyles: '',
  repeat: false,
  controlsShown: true,
  loop: false,
  muted: false,
  autoplay: false,
  fullscreenDisabled: true,
  onVideoFinished: () => {},
  onLoadedData: () => {},
};

export default VideoPlayer;
