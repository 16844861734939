import React from 'react';
import PropTypes from 'prop-types';
import styles from './sass/Button.module.scss';

const Button = ({
  isFilled, isDisabled, buttonStyle, textStyle, text, iconRight, iconLeft, onClick, type
}) => {
  return (
    <button
      type={type}
      disabled={isDisabled}
      onClick={onClick}
      className={`${isFilled && styles.filled} ${styles.btn} ${buttonStyle}`}
    >
      {iconLeft && <img src={iconLeft} alt="icon" />}
      {text && (
        <span className={textStyle}>
          {text}
        </span>
      )}
      {iconRight && <img src={iconRight} alt="icon" />}
    </button>
  );
};

Button.propTypes = {
  isFilled: PropTypes.bool,
  isDisabled: PropTypes.bool,
  buttonStyle: PropTypes.string,
  textStyle: PropTypes.string,
  text: PropTypes.string,
  iconLeft: PropTypes.string,
  iconRight: PropTypes.string,
  onClick: PropTypes.func,
  type: PropTypes.string
};

Button.defaultProps = {
  isFilled: false,
  isDisabled: false,
  iconLeft: null,
  iconRight: null,
  buttonStyle: '',
  textStyle: '',
  text: '',
  onClick: () => {},
  type: 'button'
};

export default Button;
