import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { PINPAY_VERIFY_TOKEN, USERS_API } from '../../constants/api';
import instance from '../../services/axios';
import notify from '../../utils/notify';
import { CHECKOUT } from '../../constants/links';

export const initialState = {
  error: null,
  isLoading: false,
  isLoadingVerifyToken: false,
  data: {
    email: '',
  },
};

export const PurchaseCompleteThunk = createAsyncThunk(
  'purchase/PurchaseCompleteThunk',
  async (
    { id },
    { rejectWithValue }
  ) => {
    try {
      const result = await instance.get(`${USERS_API}${id}/`, { id });
      return result.data;
    } catch ({ response: { data } }) {
      notify('error', Object.values(data)[0]);
      return rejectWithValue(data);
    }
  }
);

export const PurchaseVerifyTokenThunk = createAsyncThunk(
  'purchase/PurchaseVerifyTokenThunk',
  async (
    { sessionToken, navigate },
    { rejectWithValue }
  ) => {
    try {
      const result = await instance.get(`${PINPAY_VERIFY_TOKEN}?session_token=${sessionToken}`);
      if (result.data.response.success) {
        return result.data;
      }
      notify('error', result.data.response.error_message);
      navigate(CHECKOUT);
      return rejectWithValue(result.data);
    } catch ({ response: { data } }) {
      notify('error', Object.values(data)[0]);
      return rejectWithValue(data);
    }
  }
);

export const PurchaseCompleteSlice = createSlice({
  name: 'purchase',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(PurchaseCompleteThunk.pending, (state) => ({
        ...state,
        isLoading: true
      }))
      .addCase(PurchaseCompleteThunk.fulfilled, (state, action) => {
        state.isLoading = false;
        state.data.email = action.payload.email;
      })
      .addCase(PurchaseCompleteThunk.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
      })

      .addCase(PurchaseVerifyTokenThunk.pending, (state) => ({
        ...state,
        isLoadingVerifyToken: true
      }))
      .addCase(PurchaseVerifyTokenThunk.fulfilled, (state, action) => ({
        ...state,
        isLoadingVerifyToken: false,
        data: {
          ...state.data,
          ...action.payload
        }
      }))
      .addCase(PurchaseVerifyTokenThunk.rejected, (state, action) => {
        state.isLoadingVerifyToken = false;
        state.error = action.payload;
      });
  },
});

export default PurchaseCompleteSlice.reducer;
