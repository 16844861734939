import React from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { SIGN_IN } from '../../../constants/links';
import styles from '../sass/HaveAccountLink.module.scss';

const HaveAccountLink = () => {
  const { t } = useTranslation('', { keyPrefix: 'signUp' });
  return (
    <p className={styles.text}>
      {`${t('alreadyHaveAccount')} - `}
      <Link className={styles.link} to={SIGN_IN}>{t('signIn')}</Link>
    </p>
  );
};

export default HaveAccountLink;
