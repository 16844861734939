import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { HOMEPAGE } from '../../constants/links';
import { PurchaseCompleteThunk, PurchaseVerifyTokenThunk } from '../../store/purchaseComplete/slice';
import getFullPrice from '../../helpers/getFullPrice';
import SendingPage from '../../layout/SendingPage/SendingPage';
import ButtonLink from '../../components/ButtonLink/ButtonLink';
import SendAtText from './components/SendAtText';
import OrderNumberText from './components/OrderNumberText';
import styles from './sass/PurchaseCompleted.module.scss';

import ArrowIcon from '../../assets/icons/arrowLeftWhite.svg';

const PurchaseCompleted = () => {
  const { t } = useTranslation('', { keyPrefix: 'purchaseCompleted' });
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [searchParams] = useSearchParams();

  const [sessionToken] = useState(searchParams.get('session_token'));
  const { orderId: orderNumber } = useParams();
  const { id } = useSelector(({ profile }) => profile.data);
  const {
    purchaseComplete: {
      data: { email },
      isLoading,
      isLoadingVerifyToken,
      error,
    },
    auth: { tokens: { accessToken } },
    giftReceiver: { reportsEmail, sendAt },
    giftFlow: { savedData: { price } }
  } = useSelector((state) => ({
    purchaseComplete: state?.purchaseComplete,
    auth: state?.auth,
    giftReceiver: state?.giftReceiver,
    giftFlow: state?.giftFlow,
  }), shallowEqual);

  const isSendLater = !!JSON.parse(sendAt);

  useEffect(() => {
    if (accessToken) {
      dispatch(PurchaseCompleteThunk({ id }));
    }
  }, []);

  useEffect(() => {
    if (sessionToken) {
      dispatch(PurchaseVerifyTokenThunk({ sessionToken, navigate }));
    }
  }, [sessionToken]);

  return (
    <SendingPage stepName={t('purchaseCompleted')} withoutFooter loader={isLoading || isLoadingVerifyToken}>
      { !error && !isLoadingVerifyToken && (
        <section className={styles.section}>
          <div className={styles.container}>
            <div className={styles.textContainer}>
              <h1 className={styles.heading}>{t(isSendLater ? 'success' : 'giftoonSent')}</h1>
              { isSendLater && (
                <>
                  <SendAtText sendAt={JSON.parse(sendAt)} />
                  <OrderNumberText orderNumber={orderNumber} />
                </>
              )}
              <p className={styles.description}>
                {`${t('emailConfirming')} `}
                <span>{`$${getFullPrice(price)}`}</span>
                {` ${t('purchaseHasBeenSent')} `}
                <span>{reportsEmail || email}</span>
              </p>
            </div>
            { !isSendLater && <OrderNumberText orderNumber={orderNumber} /> }
            <ButtonLink
              buttonStyle={styles.button}
              link={HOMEPAGE}
              text={t('toHomepage')}
              iconLeft={ArrowIcon}
              isFilled
            />
          </div>
        </section>
      )}
    </SendingPage>
  );
};

export default PurchaseCompleted;
