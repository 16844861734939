import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { VerifyEmailThunk } from '../../store/verifyEmail/slice';
import { ResendEmailThunk, setResendButtonActive } from '../../store/resendEmail/slice';
import useTimeoutCallback from '../../hooks/useTimeoutCallback';
import Page from '../../layout/Page/Page';
import Button from '../../components/Button/Button';
import styles from './sass/VerifyEmail.module.scss';
import '../../styles/authentificationStyles.scss';

import EmailIcon from '../../assets/icons/email.svg';

const VerifyEmail = () => {
  const { t } = useTranslation('', { keyPrefix: 'verifyEmail' });
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [searchParams] = useSearchParams();

  const [token] = useState(searchParams.get('token'));
  const [mobile] = useState(JSON.parse(searchParams.get('mobile')?.toLowerCase() || false));

  const {
    verifyEmail: { isLoading: isLoadingVerify },
    resendEmail: { isLoading: isLoadingResend, data: { disabledTimeEnd } }
  } = useSelector(({ verifyEmail, resendEmail }) => ({ verifyEmail, resendEmail }), shallowEqual);

  const handleResendEmail = () => {
    dispatch(ResendEmailThunk());
  };

  const setResendButtonActiveCallback = useCallback(() => {
    dispatch(setResendButtonActive());
  }, []);

  useTimeoutCallback(setResendButtonActiveCallback, disabledTimeEnd - new Date().getTime());

  useEffect(() => {
    if (token) {
      dispatch(VerifyEmailThunk({ token, navigate }));
    }
  }, [token]);

  useEffect(() => {
    if (mobile) {
      window.open(`giftoon://confirm?token=${token}`, '_self');
    }
  }, [mobile]);

  return (
    <Page withoutFooter loader={isLoadingVerify || isLoadingResend}>
      <section className="authentificationContainer">
        <h1 className={styles.verifyEmailTitle}>{t('emailWasSent')}</h1>
        <p className={styles.followInstructionsText}>{t('followInstructions')}</p>
        <div className="buttonContainer">
          <Button
            isDisabled={!!disabledTimeEnd}
            text={t('resendEmail')}
            isFilled
            iconRight={EmailIcon}
            onClick={handleResendEmail}
          />
        </div>
      </section>
    </Page>
  );
};

export default VerifyEmail;
