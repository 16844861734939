import React, { useCallback, useEffect, useMemo } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { SHOP_GIVEAWAY_LIMIT } from '../../constants/constants';
import { SelectGiveawayThunk, SelectGiveawayCategoriesThunk } from '../../store/selectGiveaway/slice';
import { STEP_ADD_GIFTOON, STEP_SELECT_BRAND } from '../../constants/links';
import SendingPage from '../../layout/SendingPage/SendingPage';
import ButtonLink from '../../components/ButtonLink/ButtonLink';
import GiveawayList from '../../components/GiveawayList/GiveawayList';
import GiveawayFilter from '../../components/GiveawayFilter/GiveawayFilter';
import filterButtonClick from '../../helpers/filterButtonClick';
import styles from './sass/StepAddGiveawayList.module.scss';

import ArrowLeftIcon from '../../assets/icons/arrowLeftPink.svg';

const StepAddGiveawayList = () => {
  const { t } = useTranslation('', { keyPrefix: 'addGiftoonList' });
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const dispatch = useDispatch();

  const filter = useMemo(() => (
    searchParams.get('filter')
  ), [searchParams]);

  const activeFilter = useMemo(() => (
    filter?.split(',') || ['all']
  ), [filter]);

  const {
    selectGiveaway: {
      data: { categories, giveawayList, next }
    },
    giftFlow: {
      savedData: {
        brandName,
      }
    }
  } = useSelector(({ selectGiveaway, giftFlow }) => ({ selectGiveaway, giftFlow }), shallowEqual);

  const buttonLeft = useMemo(() => (
    <ButtonLink
      buttonStyle={styles.leftButton}
      text={t('back')}
      iconLeft={ArrowLeftIcon}
      link={`${STEP_SELECT_BRAND}/${brandName}`}
    />
  ), [brandName]);

  useEffect(() => {
    dispatch(SelectGiveawayCategoriesThunk({}));
  }, []);

  useEffect(() => {
    if (categories.length) {
      dispatch(SelectGiveawayThunk({
        limit: SHOP_GIVEAWAY_LIMIT,
        filter: filter ?? [],
      }));
    }
  }, [filter, categories]);

  const handleFilterButtonClick = useCallback((item) => (
    filterButtonClick(item, activeFilter, searchParams, setSearchParams, filter)
  ), [filter, searchParams, setSearchParams, activeFilter]);

  const handeSelectGiveaway = useCallback((id) => {
    navigate(`${STEP_ADD_GIFTOON}/${id}`);
  }, []);

  return (
    <SendingPage stepName={t('selectGiftoon')} stepNumber={2} buttonLeft={buttonLeft}>
      <section className={styles.section}>
        <h2 className={styles.heading}>{t('selectGiftoon')}</h2>
        <p className={styles.description}>{t('description')}</p>
        <GiveawayFilter
          categories={[{ name: 'all', description: 'All' }, ...categories]}
          activeFilters={activeFilter}
          onClick={handleFilterButtonClick}
        />
        <div className={styles.cardContainer}>
          <GiveawayList
            giveawayList={giveawayList}
            next={next}
            thunk={SelectGiveawayThunk}
            onSelectGiveaway={handeSelectGiveaway}
          />
        </div>
      </section>
    </SendingPage>
  );
};

export default StepAddGiveawayList;
