import React, { useEffect, useMemo } from 'react';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import {
  CHECKOUT,
  HOMEPAGE, SHOP_BRANDS, SHOP_GIFTOONS,
  STEP_ADD_BRAND_LIST, STEP_ADD_COUPON, STEP_ADD_GIFTOON, STEP_ADD_GIFTOON_LIST,
  STEP_CHECK_PHOTO, STEP_CROP_PHOTO, STEP_SELECT_PHOTO, STEP_SEND_GIFTOON
} from '../constants/links';

const MainRoute = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const {
    brandId, giveawayId, price, imageId, faceId, buyJustCoupon
  } = useSelector((state) => state.giftFlow.savedData) || {};

  const fullGiftoonCondition = useMemo(() => (
    price && brandId && giveawayId && imageId && faceId
  ), [price, brandId, giveawayId, imageId, faceId]);

  const justCouponCondition = useMemo(() => (
    buyJustCoupon && price && brandId
  ), [buyJustCoupon, price, brandId]);

  const rules = useMemo(() => ({
    [STEP_ADD_GIFTOON_LIST]: { // step 2
      condition: brandId,
      prevPage: () => SHOP_BRANDS
    },
    [STEP_ADD_GIFTOON]: { // step 2.1
      condition: brandId,
      prevPage: () => STEP_ADD_GIFTOON_LIST
    },

    [STEP_ADD_BRAND_LIST]: { // step 2
      condition: giveawayId,
      prevPage: () => SHOP_GIFTOONS
    },
    [STEP_ADD_COUPON]: { // step 2.1
      condition: giveawayId,
      prevPage: () => STEP_ADD_BRAND_LIST
    },

    [STEP_SELECT_PHOTO]: { // step 3
      condition: price && brandId && giveawayId,
      prevPage: () => {
        if (giveawayId) {
          return STEP_ADD_BRAND_LIST;
        }
        if (brandId) {
          return STEP_ADD_GIFTOON_LIST;
        }
        return HOMEPAGE;
      }
    },
    [STEP_CHECK_PHOTO]: { // step 3.1
      condition: price && brandId && giveawayId && imageId,
      prevPage: () => STEP_SELECT_PHOTO,
    },
    [STEP_CROP_PHOTO]: { // step 3.2
      condition: price && brandId && giveawayId && imageId,
      prevPage: () => STEP_CHECK_PHOTO,
    },

    [STEP_SEND_GIFTOON]: {
      condition: fullGiftoonCondition || justCouponCondition,
      prevPage: () => STEP_CHECK_PHOTO
    },

    [CHECKOUT]: {
      condition: fullGiftoonCondition || justCouponCondition,
      prevPage: () => HOMEPAGE,
    }
  }), [brandId, giveawayId, price, imageId, faceId]);

  useEffect(() => {
    if (rules[location.pathname] && !rules[location.pathname].condition) {
      navigate(rules[location.pathname].prevPage());
    }
  }, [location.pathname, rules]);

  return <Outlet />;
};

export default MainRoute;
