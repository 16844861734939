import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { VIDEO_STATES } from '../../constants/constants';
import { GiftViewThunk } from '../../store/giftView/slice';
import { APPLICATION_REDIRECT_URL, GIFT_SHARE_VIEW } from '../../constants/links';
import redirectToApp from '../../helpers/redirectToApp';
import ErrorChecker from '../../hoc/ErrorChecker/ErrorChecker';
import Page from '../../layout/Page/Page';
import GiftViewModals from '../../components/GiftViewModals/GiftViewModals';
import styles from './sass/GiftView.module.scss';

import CouponContent from './components/CouponContent';
import CouponDescription from './components/CouponDescription';

const GiftView = () => {
  const { t } = useTranslation('', { keyPrefix: 'giftView' });
  const { hash } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  const [appExist] = useState(searchParams.get('appExist'));
  const [videoProgress, setVideoProgress] = useState(VIDEO_STATES.NOT_PLAYED);
  const [videoLoaded, setVideoLoaded] = useState(false);
  const [activeModal, setActiveModal] = useState('');

  const {
    isLoading,
    data: {
      giftoon,
      giveaway,
      brand_price: brandPrice,
      brand,
      public_hash_value: publicHash,
      message,
      receiver_first_name: receiverName,
      sender_name: senderName
    },
    error
  } = useSelector(({ giftView }) => giftView);

  const withGiftoon = useMemo(() => !!giveaway?.title, [giveaway]);
  const loading = useMemo(() => isLoading || (withGiftoon && !videoLoaded), [isLoading, videoLoaded]);
  const renderPage = useMemo(() => (
    !isLoading && brand?.product_name && (!withGiftoon || giftoon)
  ), [isLoading, withGiftoon, giftoon, brand]);

  const firstLine = useMemo(() => (
    withGiftoon ? `${t('enjoy')} ${giveaway?.title}🎉` : `${receiverName}, ${t('youReceived')}`
  ), [withGiftoon, giveaway]);

  const secondLine = useMemo(() => (
    withGiftoon ? `${t('starring')} ${receiverName}` : `$${brandPrice}`
  ), [withGiftoon, giveaway]);

  const thirdLine = useMemo(() => (
    withGiftoon
      ? `${t('plus')} ${brand?.product_name} $${brandPrice}💰`
      : `${t('of')} ${brand?.product_name} ${t('creditFrom')} ${senderName}`
  ), [withGiftoon, brand, brandPrice]);

  useEffect(() => {
    if (!appExist) {
      redirectToApp(`${APPLICATION_REDIRECT_URL}receive?token=${hash}`);
    }
    dispatch(GiftViewThunk({ hash, navigate }));
  }, []);

  return (
    <ErrorChecker error={error}>
      <Page loader={loading} withoutFooter pageContainerStyle={styles.pageContainer}>
        {renderPage && (
          <section className={styles.section}>
            <div className={styles.container}>
              <CouponContent
                withGiftoon={withGiftoon}
                giftoon={giftoon}
                giveaway={giveaway}
                brand={brand}
                videoProgress={videoProgress}
                setVideoProgress={setVideoProgress}
                setVideoLoaded={setVideoLoaded}
                setActiveModal={setActiveModal}
              />
              <CouponDescription
                firstLine={firstLine}
                secondLine={secondLine}
                thirdName={thirdLine}
                message={message}
                hash={hash}
              />
            </div>
          </section>
        )}
        <GiftViewModals
          giftoonUrl={`${window.location.origin}${GIFT_SHARE_VIEW}/${publicHash}`}
          activeModal={activeModal}
          setActiveModal={setActiveModal}
        />
      </Page>
    </ErrorChecker>
  );
};

export default GiftView;
