import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import InfiniteScroll from 'react-infinite-scroll-component';
import Modal from '../Modal/Modal';
import Button from '../Button/Button';
import styles from './sass/ImageGallery.module.scss';

import CrossIcon from '../../assets/icons/whiteCross.svg';
import GiftoonSmileIcon from '../../assets/icons/giftoonSmile.svg';

const ImageGallery = ({
  data, isOpen, onClose, onSelect, onNext, isLoading, hasMore
}) => {
  const onClickImage = useCallback((item) => {
    onSelect(item);
    onClose();
  }, [onSelect, onClose]);
  return (
    <Modal onClose={onClose} isOpen={isOpen}>
      <div className={styles.modal}>
        <Button
          iconLeft={CrossIcon}
          onClick={onClose}
          buttonStyle={`${styles.button} ${styles.crossButton}`}
        />
        <div id="infiniteScrollImageGallery" className={styles.modalContent}>
          <InfiniteScroll
            next={onNext}
            hasMore={hasMore}
            dataLength={data?.length || 0}
            className={styles.imagesList}
            scrollableTarget="infiniteScrollImageGallery"
            showLoader={isLoading}
            loader={<img className={styles.giftoonSmile} src={GiftoonSmileIcon} alt="Loader" />}
          >
            {data?.map(({
              id, src, altText, origin
            }) => (
              <button key={id} className={styles.imagesListItem} onClick={() => onClickImage(origin)}>
                <img src={src} alt={altText || id} />
              </button>
            ))}
          </InfiniteScroll>
        </div>
      </div>
    </Modal>
  );
};

ImageGallery.propTypes = {
  data: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string,
    altText: PropTypes.string,
    src: PropTypes.string,
    item: PropTypes.shape({})
  })).isRequired,
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onSelect: PropTypes.func.isRequired,
  onNext: PropTypes.func,
  isLoading: PropTypes.bool,
  hasMore: PropTypes.bool,
};

ImageGallery.defaultProps = {
  onNext: undefined,
  isLoading: false,
  hasMore: false,
};

export default ImageGallery;
