import React from 'react';
import Page from '../../layout/Page/Page';
import { HELP_EMAIL } from '../../constants/constants';
import '../../styles/contentPages.scss';
import styles from './sass/OurWhy.module.scss';

import OurWhyImage from '../../assets/images/ourWhy.jpg';

const OurWhy = () => {
  return (
    <Page>
      <section className={`contentSection ${styles.section}`}>
        <h1>Our Why</h1>
        <div className={styles.contentContainer}>
          <div className={styles.textContainer}>
            <p>
              We’re on a mission to create exciting and innovative digital gift card experiences that inspire smiles in
              the moments that matter.
            </p>
            <br />
            <p>
              Digital gift cards are convenient, but let&apos;s face it, up until now, digital gift cards have been
              boring. So we are changing that.
            </p>
            <br />
            <p>
              We’re all about heightening the gifting experience, increasing connection through a greater care factor
              and humour. Because no matter who you are, laughter is the best medicine.
            </p>
            <br />
            <p>
              We are currently searching for a worthy cause to contribute towards. Specifically, we wish to focus on
              supporting mental health, and removing plastic from our oceans.
            </p>
            <br />
            <p>
              Feel free to make suggestions via&nbsp;
              <a href={`mailto:${HELP_EMAIL}`}>{HELP_EMAIL}</a>
              .
            </p>
          </div>
          <div className={styles.imageContainer}>
            <img src={OurWhyImage} alt="Our Why" />
          </div>
        </div>
      </section>
    </Page>
  );
};

export default OurWhy;
