import {
  useCallback, useEffect, useMemo, useState
} from 'react';

const useCropImage = ({
  position, scale, rotate, wrapperRef, faceMaskRef
}) => {
  const [sizeCropWrapper, setSizeCropWrapper] = useState({ width: 0, height: 0 });
  const [sizeFaceMask, setSizeFaceMask] = useState({ width: 0, height: 0 });

  const resizeInvisibleImage = useCallback(() => {
    const size = wrapperRef.current?.getBoundingClientRect();
    setSizeCropWrapper({
      width: size?.width,
      height: size?.height
    });
  }, []);
  
  useEffect(() => {
    setTimeout(() => resizeInvisibleImage(), 500);

    window.addEventListener('resize', resizeInvisibleImage);
    return () => window.removeEventListener('resize', resizeInvisibleImage);
  }, []);

  useEffect(() => {
    const size = faceMaskRef.current?.getBoundingClientRect();
    setSizeFaceMask({ width: size?.width, height: size?.height });
  }, []);

  const invisibleCropPosition = useMemo(() => ({
    top: -sizeCropWrapper.height,
    left: -sizeCropWrapper.width,
    height: sizeCropWrapper.height,
    width: sizeCropWrapper.width,
  }), [sizeCropWrapper]);

  const invisibleCropImage = useMemo(() => ({
    top: position.y - (sizeCropWrapper.height - sizeFaceMask.height) / 2,
    left: position.x - (sizeCropWrapper.width - sizeFaceMask.width) / 2,
    transform: `scale(${scale}) rotate(${rotate}deg)`,
  }), [position, sizeCropWrapper, sizeFaceMask, scale, rotate]);

  const visibleCropImage = useMemo(() => ({
    top: position.y,
    left: position.x,
    transform: `scale(${scale}) rotate(${rotate}deg)`,
  }), [position, scale, rotate]);

  return {
    faceMaskRef,
    invisibleCropPosition,
    invisibleCropImage,
    visibleCropImage
  };
};

export default useCropImage;
