import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { PROFILE_TABS } from '../../constants/constants';
import Page from '../../layout/Page/Page';
import ProfileSettings from '../ProfileSettings/ProfileSettings';
import ProfileSentGiftoons from '../ProfileSentGiftoons/ProfileSentGiftoons';
import ProfileWallet from '../ProfileWallet/ProfileWallet';
import Tabs from './components/Tabs';
import styles from './sass/Profile.module.scss';

const Profile = () => {
  const [activeTab, setActiveTab] = useState(PROFILE_TABS.SETTINGS);
  const { t } = useTranslation('', { keyPrefix: 'profile' });

  const PROFILE_COMPONENT_MAP = {
    [PROFILE_TABS.SETTINGS]: ProfileSettings,
    [PROFILE_TABS.SENT_GIFTOONS]: ProfileSentGiftoons,
    [PROFILE_TABS.WALLET]: ProfileWallet,
  };

  const ProfileComponent = PROFILE_COMPONENT_MAP[activeTab];

  return (
    <Page>
      <section className={styles.section}>
        <h1 className={styles.profileHeading}>{t('myProfile')}</h1>
        <Tabs activeTab={activeTab} onClickTab={setActiveTab} tabs={Object.values(PROFILE_TABS)} />
        <ProfileComponent />
      </section>
    </Page>
  );
};

export default Profile;
