import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import Modal from '../Modal/Modal';
import Button from '../Button/Button';
import styles from './sass/CouponModal.module.scss';

import CrossIcon from '../../assets/icons/closeButton.svg';

const CouponModal = ({ onClose, couponModalOpened, coupon }) => {
  const {
    imageUrl, brandName, balance, barcodeUrl, cardNumber, pin, certificateUrl
  } = coupon;

  const { t } = useTranslation('', { keyPrefix: 'wallet' });

  return (
    <Modal onClose={onClose} isOpen={couponModalOpened}>
      <div className={styles.container}>
        <div className={styles.headingContainer}>
          <h1>{t('couponInfo')}</h1>
          <Button iconLeft={CrossIcon} buttonStyle={styles.crossButton} onClick={onClose} />
        </div>
        <img className={styles.couponImage} src={imageUrl} alt="coupon" />
        <h2>{`${brandName} - ${t('digitalDelivery')}`}</h2>
        <h3>{`$${balance}`}</h3>
        <div className={styles.infoBlock}>
          <div>
            <h4>{t('cardNumber')}</h4>
            <p>{cardNumber}</p>
          </div>
          <div>
            <h4>{t('pin')}</h4>
            <p>{pin}</p>
          </div>
        </div>
        {barcodeUrl && (<img className={styles.barcodeImage} src={barcodeUrl} alt="barcode" />)}
        <a className={styles.couponLink} href={certificateUrl} target="_blank" rel="noreferrer">
          <Button buttonStyle={styles.buttonLink} textStyle={styles.buttonText} isFilled text={t('useCoupon')} />
        </a>
      </div>
    </Modal>
  );
};

CouponModal.propTypes = {
  onClose: PropTypes.func.isRequired,
  couponModalOpened: PropTypes.bool,
  coupon: PropTypes.shape({
    id: PropTypes.number,
    imageUrl: PropTypes.string,
    brandName: PropTypes.string,
    balance: PropTypes.number,
    barcodeUrl: PropTypes.string,
    cardNumber: PropTypes.string,
    pin: PropTypes.string,
    certificateUrl: PropTypes.string
  }),
};

CouponModal.defaultProps = {
  couponModalOpened: false,
  coupon: null
};

export default CouponModal;
