import React, { useCallback } from 'react';
import { useFormik } from 'formik';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { contactUsValidation } from '../../utils/validation';
import { clearContactUs, ContactUsThunk } from '../../store/contactUs/slice';
import Page from '../../layout/Page/Page';
import MessageSent from './components/MessageSent';
import styles from './sass/ContactUs.module.scss';

import InputForm from './components/InputForm';

const ContactUs = () => {
  const dispatch = useDispatch();

  const {
    contactUs: {
      isLoading,
      data
    },
  } = useSelector((state) => ({
    contactUs: state?.contactUs,
  }), shallowEqual);

  const {
    handleSubmit,
    values: { name, email, message },
    handleChange,
    validateForm,
    errors,
    handleBlur,
    resetForm
  } = useFormik({
    initialValues: { name: '', email: '', message: '', },
    onSubmit: (values) => validateForm(values).then(() => dispatch(ContactUsThunk(values))),
    validationSchema: contactUsValidation,
    validateOnBlur: false,
    validateOnChange: false
  });

  const handleClickOk = useCallback(() => {
    dispatch(clearContactUs());
    resetForm();
  }, [dispatch],);

  return (
    <Page loader={isLoading}>
      <section className={styles.section}>
        <div className={styles.container}>
          { !data?.response ? (
            <InputForm
              handleBlur={handleBlur}
              handleSubmit={handleSubmit}
              handleChange={handleChange}
              name={name}
              message={message}
              errors={errors}
              email={email}
            />
          ) : (
            <MessageSent onClick={handleClickOk} />
          )}
        </div>
      </section>
    </Page>
  );
};

export default ContactUs;
