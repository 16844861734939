import React from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { SIGN_UP } from '../../../constants/links';
import styles from '../sass/CreateAccountLink.module.scss';

const CreateAccountLink = () => {
  const { t } = useTranslation('', { keyPrefix: 'signIn' });
  return (
    <p className={styles.text}>
      {`${t('dontHaveAccount')} `}
      <Link className={styles.link} to={SIGN_UP}>{t('createAccount')}</Link>
    </p>
  );
};

export default CreateAccountLink;
