import React from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import Checkbox from '../../../components/Checkbox/Checkbox';
import styles from '../sass/AfterInputSection.module.scss';
import { RESET_PASSWORD } from '../../../constants/links';

const AfterInputSection = ({ keepMeLoggedIn, handleChange }) => {
  const { t } = useTranslation('', { keyPrefix: 'signIn' });
  return (
    <div className={styles.afterInput}>
      <Checkbox label={t('keepSignedIn')} isChecked={keepMeLoggedIn} onChange={handleChange} name="keepMeLoggedIn" />
      <Link className={styles.link} to={RESET_PASSWORD}>{t('forgotPassword')}</Link>
    </div>
  );
};

AfterInputSection.propTypes = {
  keepMeLoggedIn: PropTypes.bool,
  handleChange: PropTypes.func
};

AfterInputSection.defaultProps = {
  keepMeLoggedIn: false,
  handleChange: () => {}
};

export default AfterInputSection;
