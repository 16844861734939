import React from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { HOMEPAGE } from '../../../constants/links';
import styles from '../sass/SendingHeader.module.scss';

import GiftoonIcon from '../../../assets/icons/giftoonPink.svg';

const SendingHeader = ({ heading, stepNumber }) => {
  const { t } = useTranslation('', { keyPrefix: 'sendingPage' });

  return (
    <div className={styles.section}>
      <div className={`container ${styles.container}`}>
        <Link to={HOMEPAGE} className={styles.logo}>
          <img src={GiftoonIcon} alt={t('giftoon')} />
        </Link>
        <div className={styles.title}>
          <h2>{heading}</h2>
        </div>
        <div className={styles.linkButton} />
      </div>
      <div className={styles.progressLineContainer}>
        <hr style={{ width: `${stepNumber * 25}%` }} />
      </div>
    </div>
  );
};

SendingHeader.propTypes = {
  heading: PropTypes.string.isRequired,
  stepNumber: PropTypes.number
};

SendingHeader.defaultProps = {
  stepNumber: 4
};

export default SendingHeader;
