import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import styles from './sass/BrandCard.module.scss';

const BrandCard = ({
  image, brandName, priceHigh, priceLow, currency, onSelect
}) => {
  const priceLine = useMemo(() => (
    priceHigh === priceLow
      ? `${currency} $${priceLow}`
      : `${currency} $${priceLow} - ${currency} $${priceHigh}`
  ), [currency, priceHigh]);

  return (
    <div className={styles.cardContainer}>
      <div className={styles.cardLink}>
        <img src={image} alt={brandName} onClick={() => onSelect(brandName)} role="presentation" />
      </div>
      <div className={styles.cardDescription}>
        <h2>{brandName}</h2>
        <p>{priceLine}</p>
      </div>
    </div>
  );
};

BrandCard.propTypes = {
  image: PropTypes.oneOfType([PropTypes.string, PropTypes.shape({})]).isRequired,
  brandName: PropTypes.string.isRequired,
  priceHigh: PropTypes.number.isRequired,
  priceLow: PropTypes.number.isRequired,
  currency: PropTypes.string.isRequired,
  onSelect: PropTypes.func.isRequired,
};

export default BrandCard;
