import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import randomIntFromInterval from '../../helpers/global';
import { SIGN_UP_API } from '../../constants/api';
import { VERIFY_EMAIL } from '../../constants/links';
import instance from '../../services/axios';
import notify from '../../utils/notify';
import { setEmail } from '../resendEmail/slice';

export const initialState = {
  error: null,
  isLoading: false,
  data: true,
};

export const SignUpThunk = createAsyncThunk(
  'signUp/signUpThunk',
  async ({
    values: {
      name, email, password, repeatPassword
    }, navigate
  }, { rejectWithValue, dispatch }) => {
    const userName = `${name.replaceAll(/\s/g, '_')}_${randomIntFromInterval(11111, 99999)}`;
    const values = {
      username: userName,
      full_name: name,
      email,
      password,
      confirm_password: repeatPassword
    };

    try {
      const result = await instance.post(SIGN_UP_API, values, { withoutAuth: true });
      dispatch(setEmail(email));
      navigate(VERIFY_EMAIL);
      return result.data;
    } catch ({ response: { data } }) {
      notify('error', Object.values(data)[0]);
      return rejectWithValue(data);
    }
  }
);

export const SignUpSlice = createSlice({
  name: 'signUp',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(SignUpThunk.pending, (state) => ({
        ...state,
        isLoading: true
      }))
      .addCase(SignUpThunk.fulfilled, (state, action) => {
        state.isLoading = false;
        state.data = action.payload;
      })
      .addCase(SignUpThunk.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
      });
  },
});

export default SignUpSlice.reducer;
