import React from 'react';
import PropTypes from 'prop-types';
import downloadFile from '../../helpers/downloadFile';
import styles from './sass/VideoModal.module.scss';

import RefreshIcon from '../../assets/icons/refresh.svg';
import PlaneIcon from '../../assets/icons/planePink.svg';
import DownloadIcon from '../../assets/icons/downloadPink.svg';

const VideoModal = ({
  isOpen, restartVideo, openShareModal, videoUrl, giftoonName
}) => {
  return isOpen && (
    <div className={styles.videoModal}>
      <button onClick={restartVideo} className={styles.videoButton}><img src={RefreshIcon} alt="Restart" /></button>
      <button onClick={openShareModal} className={styles.videoButton}><img src={PlaneIcon} alt="Share" /></button>
      <button onClick={() => downloadFile(videoUrl, giftoonName)} className={styles.videoButton}>
        <img src={DownloadIcon} alt="Download" />
      </button>
    </div>
  );
};

VideoModal.propTypes = {
  isOpen: PropTypes.bool,
  restartVideo: PropTypes.func.isRequired,
  openShareModal: PropTypes.func.isRequired,
  videoUrl: PropTypes.string.isRequired,
  giftoonName: PropTypes.string,
};

VideoModal.defaultProps = {
  isOpen: false,
  giftoonName: 'giftoon',
};

export default VideoModal;
