import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import instance from '../../services/axios';
import { RESET_PASSWORD_API } from '../../constants/api';
import { RESET_PASSWORD_SENT } from '../../constants/links';
import notify from '../../utils/notify';

export const initialState = {
  error: null,
  isLoading: false,
  data: true,
};

export const ResetPasswordThunk = createAsyncThunk(
  'resetPassword/ResetPasswordThunk',
  async ({
    email, navigate
  }, { rejectWithValue }) => {
    try {
      const result = await instance.post(RESET_PASSWORD_API, { email }, { withoutAuth: true });
      navigate(RESET_PASSWORD_SENT);

      return result.data;
    } catch ({ response: { data, status } }) {
      notify('error', Object.values(data)[0]);
      return rejectWithValue({ data, code: status });
    }
  }
);

export const ResetPasswordSlice = createSlice({
  name: 'resetPassword',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(ResetPasswordThunk.pending, (state) => ({
        ...state,
        isLoading: true
      }))
      .addCase(ResetPasswordThunk.fulfilled, (state, action) => {
        state.isLoading = false;
        state.data = action.payload;
      })
      .addCase(ResetPasswordThunk.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
      });
  },
});

export default ResetPasswordSlice.reducer;
