import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { SHOP_BRANDS_API, SHOP_BRANDS_CATEGORY_API } from '../../constants/api';
import { HOME_BRANDS_LIMIT } from '../../constants/constants';
import { HOME_SLIDER } from '../../constants/links';
import instance from '../../services/axios';

export const initialState = {
  error: null,
  isLoading: false,
  data: {
    brandList: [],
    categories: []
  }
};

export const HomeBrandsThunk = createAsyncThunk(
  'homeBrands/homeBrandsThunk',
  async ({ rejectWithValue }) => {
    try {
      const result = await instance.get(
        `${SHOP_BRANDS_API}${HOME_SLIDER}&limit=${HOME_BRANDS_LIMIT}`,
        {},
        { withoutAuth: true }
      );
      return result.data;
    } catch ({ response: { data, status } }) {
      return rejectWithValue({ data, code: status });
    }
  }
);

export const HomeBrandsCategoryThunk = createAsyncThunk(
  'homeBrands/homeBrandsCategoryThunk',
  async ({ rejectWithValue }) => {
    try {
      const { data: { results } } = await instance.get(
        `${SHOP_BRANDS_CATEGORY_API}`, 
        {},
        { withoutAuth: true }
      );
      return results;
    } catch ({ response: { data, status } }) {
      return rejectWithValue({ data, code: status });
    }
  }
);

export const HomeBrandsSlice = createSlice({
  name: 'homeBrands',
  initialState,
  extraReducers: (builder) => {
    builder
      .addCase(HomeBrandsThunk.pending, (state) => ({
        ...state,
        isLoading: true
      }))
      .addCase(HomeBrandsThunk.fulfilled, (state, action) => {
        state.isLoading = false;
        state.data.brandList = action.payload;
      })
      .addCase(HomeBrandsThunk.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
      })
      .addCase(HomeBrandsCategoryThunk.pending, (state) => ({
        ...state,
        isLoading: true
      }))
      .addCase(HomeBrandsCategoryThunk.fulfilled, (state, action) => {
        state.isLoading = false;
        state.data.categories = action.payload;
      })
      .addCase(HomeBrandsCategoryThunk.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
      });
  }
});

export default HomeBrandsSlice.reducer;
