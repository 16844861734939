import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  firstName: '',
  lastName: '',
  email: '',
  sendAt: '',
  phone: '',
  senderName: '',
  reportsEmail: '',
  toWhom: '',
  message: '',
};

export const GiftReceiver = createSlice({
  name: 'giftReceiver',
  initialState,
  reducers: {
    clearGiftReceiver: () => initialState,
    setReceiver: (state, action) => {
      state.firstName = action.payload.firstName;
      state.lastName = action.payload.lastName;
      state.email = action.payload.email;
      state.sendAt = action.payload.sendAt;
      state.phone = action.payload.phone;
      state.senderName = action.payload.senderName;
      state.reportsEmail = action.payload.reportsEmail;
      state.toWhom = action.payload.toWhom;
      state.message = action.payload.message;
    }
  }
});

export const { setReceiver, clearGiftReceiver } = GiftReceiver.actions;
export default GiftReceiver.reducer;
