import React from 'react';
import PropTypes from 'prop-types';
import styles from '../sass/NavBurger.module.scss';

const NavBurger = ({ onClick, isColored }) => {
  return (
    <button className={styles.burger} onClick={onClick}>
      <div className={`${styles.burgerLine} ${isColored && styles.coloredLine}`} />
      <div className={`${styles.burgerLine} ${isColored && styles.coloredLine}`} />
      <div className={`${styles.burgerLine} ${isColored && styles.coloredLine}`} />
    </button>
  );
};

NavBurger.propTypes = {
  onClick: PropTypes.func.isRequired,
  isColored: PropTypes.bool.isRequired,
};

export default NavBurger;
