import React, { useLayoutEffect } from 'react';
import { Outlet, useLocation } from 'react-router-dom';

const MainRoute = () => {
  const location = useLocation();

  useLayoutEffect(() => {
    window.scrollTo(0, 0);
  }, [location.pathname]);

  return <Outlet />;
};

export default MainRoute;
