import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { IMAGE_EXTENSIONS, SELECT_PHOTO_MODALS as MODALS } from '../../../constants/constants';
import FacebookImageSelector from '../../../components/FacebookImageSelector/FacebookImageSelector';
import Button from '../../../components/Button/Button';
import FileInput from '../../../components/FileInput/FileInput';
import styles from '../sass/StepSelectPhoto.module.scss';

import CameraIcon from '../../../assets/icons/cameraDarkPurple.svg';
import FacebookIcon from '../../../assets/icons/facebookCircleDarkPurple.svg';
import PictureIcon from '../../../assets/icons/pictureDarkPurple.svg';

const ButtonList = ({
  accessToken,
  isGuest,
  displayedModal,
  setDisplayedModal,
  onFacebookSetImage,
  onOpenWebcamModal,
  onSetFolderImage,
}) => {
  const { t } = useTranslation('', { keyPrefix: 'selectPhoto' });

  const handleFacebookClick = useCallback((onClick) => (
    accessToken || isGuest ? onClick : () => setDisplayedModal(MODALS.WITHOUT_AUTH)
  ), [accessToken, setDisplayedModal, isGuest]);

  const handleClickBrowsePhoto = useCallback((e) => {
    if (!accessToken && !isGuest) {
      e.preventDefault();
      setDisplayedModal(MODALS.WITHOUT_AUTH);
    }
  }, [accessToken, setDisplayedModal, isGuest]);

  return (
    <>
      <FacebookImageSelector
        onSelectImage={onFacebookSetImage}
        displayedModal={displayedModal}
        setDisplayedModal={setDisplayedModal}
        renderButton={({ onClick }) => (
          <Button
            buttonStyle={styles.photoButton}
            iconLeft={FacebookIcon}
            text={t('facebook')}
            onClick={handleFacebookClick(onClick)}
          />
        )}
      />
      <Button
        buttonStyle={styles.photoButton}
        iconLeft={CameraIcon}
        text={t('webcam')}
        onClick={onOpenWebcamModal}
      />
      <FileInput
        inputStyle={styles.input}
        id="imageGiftoon"
        name="imageGiftoon"
        icon={PictureIcon}
        text={t('browsePhotos')}
        acceptedFiles={IMAGE_EXTENSIONS}
        onChange={onSetFolderImage}
        onClick={handleClickBrowsePhoto}
      />
    </>
  );
};

ButtonList.propTypes = {
  accessToken: PropTypes.string,
  isGuest: PropTypes.bool.isRequired,
  displayedModal: PropTypes.string,
  setDisplayedModal: PropTypes.func.isRequired,
  onFacebookSetImage: PropTypes.func.isRequired,
  onOpenWebcamModal: PropTypes.func.isRequired,
  onSetFolderImage: PropTypes.func.isRequired,
};

ButtonList.defaultProps = {
  accessToken: null,
  displayedModal: null,
};

export default ButtonList;
