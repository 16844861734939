import { combineReducers } from '@reduxjs/toolkit';
import translates from './translates/slice';
import signUp from './signUp/slice';
import verifyEmail from './verifyEmail/slice';
import resetPassword from './resetPassword/slice';
import forgotPassword from './forgotPassword/slice';
import signIn from './signIn/slice';
import auth from './auth/slice';
import shopBrands from './shopBrands/slice';
import facebook from './facebook/slice';
import facebookImages from './facebookImages/slice';
import shopGiveaway from './shopGiveaway/slice';
import homeBrands from './homeBrands/slice';
import profile from './profile/slice';
import cropFace from './cropFace/slice';
import cropFaceVerify from './cropFaceVerify/slice';
import selectBrands from './selectBrands/slice';
import selectGiveaway from './selectGiveaway/slice';
import sentGiftoons from './sentGiftoons/slice';
import brandSelectAmount from './brandSelectAmount/slice';
import receivedGiftoons from './receivedGiftoons/slice';
import stepSelectedGiveaway from './stepSelectedGiveaway/slice';
import purchaseComplete from './purchaseComplete/slice';
import coupon from './coupon/slice';
import giftFlow from './giftFlow/slice';
import giftReceiver from './giftReceiver/slice';
import giftView from './giftView/slice';
import receivedGiftoon from './receivedGiftoon/slice';
import checkout from './checkout/slice';
import selectPhoto from './selectPhoto/slice';
import resendEmail from './resendEmail/slice';
import saveCard from './saveCard/slice';
import contactUs from './contactUs/slice';
import deleteAccount from './deleteAccount/slice';

export default combineReducers({
  translates,
  signUp,
  verifyEmail,
  resetPassword,
  forgotPassword,
  signIn,
  auth,
  shopBrands,
  facebook,
  facebookImages,
  shopGiveaway,
  homeBrands,
  profile,
  cropFace,
  cropFaceVerify,
  selectBrands,
  selectGiveaway,
  sentGiftoons,
  brandSelectAmount,
  receivedGiftoons,
  stepSelectedGiveaway,
  purchaseComplete,
  coupon,
  giftFlow,
  giftReceiver,
  giftView,
  receivedGiftoon,
  resendEmail,
  checkout,
  selectPhoto,
  saveCard,
  contactUs,
  deleteAccount,
});
