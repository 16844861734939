import React from 'react';
import PropTypes from 'prop-types';
import styles from '../sass/Profile.module.scss';
import Tab from './Tab';

const Tabs = ({ activeTab, tabs, onClickTab }) => {
  return (
    <div className={styles.profileTabs}>
      {tabs.map((title) => (
        <Tab key={`ProfileTab_${title}`} title={title} onClick={onClickTab} isActive={activeTab === title} />
      ))}
    </div>
  );
};

Tabs.propTypes = {
  activeTab: PropTypes.string.isRequired,
  onClickTab: PropTypes.func.isRequired,
  tabs: PropTypes.arrayOf(PropTypes.string).isRequired,
};

export default Tabs;
