import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { PHONE_PREFIX } from '../../../constants/constants';
import InputTextArea from '../../../components/InputTextArea/InputTextArea';
import Input from '../../../components/Input/Input';
import MaskInput from '../../../components/MaskInput/MaskInput';
import styles from '../sass/RecipientInputForm.module.scss';

import PhoneIcon from '../../../assets/icons/phone.svg';
import MessageIcon from '../../../assets/icons/message.svg';

const RecipientInputForm = ({
  isGift, handleChange, handleBlur, errors, receiverFirstName, receiverLastName, phone, recipientEmail, message
}) => {
  const { t } = useTranslation('', { keyPrefix: 'sendGiftoon' });

  return (
    <div className={styles.formContainer}>
      <Input
        onChange={handleChange}
        onBlur={handleBlur}
        placeholder={t(isGift ? 'name' : 'yourName')}
        error={errors.receiverFirstName}
        value={receiverFirstName}
        name="receiverFirstName"
      />
      <Input
        onChange={handleChange}
        onBlur={handleBlur}
        placeholder={t(isGift ? 'surname' : 'yourSurname')}
        error={errors.receiverLastName}
        value={receiverLastName}
        name="receiverLastName"
      />
      <MaskInput
        mask={`${PHONE_PREFIX}999999999`}
        onChange={handleChange}
        onBlur={handleBlur}
        placeholder={`${PHONE_PREFIX}412345678`}
        maskPlaceholder=""
        inputStyle={styles.phoneNumber}
        iconLeft={PhoneIcon}
        error={errors.phone}
        value={phone}
        name="phone"
      />
      <Input
        onChange={handleChange}
        onBlur={handleBlur}
        placeholder={t(isGift ? 'email' : 'yourEmail')}
        iconLeft={MessageIcon}
        error={errors.recipientEmail}
        value={recipientEmail}
        name="recipientEmail"
      />
      <InputTextArea
        onChange={handleChange}
        onBlur={handleBlur}
        placeholder={t('message')}
        error={errors.message}
        value={message}
        name="message"
      />
    </div>
  );
};

RecipientInputForm.propTypes = {
  isGift: PropTypes.bool.isRequired,
  handleChange: PropTypes.func.isRequired,
  handleBlur: PropTypes.func.isRequired,
  errors: PropTypes.shape({
    receiverFirstName: PropTypes.string,
    receiverLastName: PropTypes.string,
    recipientEmail: PropTypes.string,
    phone: PropTypes.string,
    message: PropTypes.string,
  }).isRequired,
  receiverFirstName: PropTypes.string.isRequired,
  receiverLastName: PropTypes.string.isRequired,
  recipientEmail: PropTypes.string.isRequired,
  phone: PropTypes.string.isRequired,
  message: PropTypes.string.isRequired
};

export default RecipientInputForm;
