import React from 'react';
import PropTypes from 'prop-types';
import styles from '../sass/HowItWorksStep.module.scss';

const HowItWorksStep = ({ heading, stepNumber, description }) => (
  <div className={styles.container}>
    <h1 className={styles.heading}>{heading}</h1>
    <div className={styles.circle}>
      <p className={styles.number}>{`${stepNumber}.`}</p>
    </div>
    <p className={styles.description}>{description}</p>
  </div>
);

HowItWorksStep.propTypes = {
  heading: PropTypes.string.isRequired,
  stepNumber: PropTypes.number.isRequired,
  description: PropTypes.string.isRequired
};

export default HowItWorksStep;
