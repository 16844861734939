import React from 'react';
import { useTranslation } from 'react-i18next';

import styles from '../sass/NoGiftoons.module.scss';
import Emoji from '../../../assets/images/bigSadEmoji.jpg';
import Emoji2x from '../../../assets/images/bigSadEmoji2x.jpg';

const NoGiftoons = () => {
  const { t } = useTranslation('', { keyPrefix: 'wallet' });

  return (
    <section className={styles.section}>
      <div className={styles.container}>
        <img src={Emoji} alt="sad" srcSet={`${Emoji2x} 2x`} />
        <h2>{t('noCoupons')}</h2>
      </div>
    </section>
  );
};

export default NoGiftoons;
