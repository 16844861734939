import React, {
  useCallback, useEffect, useMemo, useState
} from 'react';
import { useTranslation } from 'react-i18next';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { croppingFace } from '../../store/cropFace/slice';
import { clearSelectPhoto, SelectPhotoThunk } from '../../store/selectPhoto/slice';
import { STEP_ADD_COUPON, STEP_ADD_GIFTOON, STEP_CHECK_PHOTO } from '../../constants/links';
import { FLOW_TYPES, SELECT_PHOTO_MODALS as MODALS } from '../../constants/constants';
import Button from '../../components/Button/Button';
import WebcamModal from './components/WebcamModal';
import SendingPage from '../../layout/SendingPage/SendingPage';
import VideoPlayer from '../../components/VideoPlayer/VideoPlayer';
import ButtonList from './components/ButtonList';
import WithoutAuthModal from './components/WithoutAuthModal';
import styles from './sass/StepSelectPhoto.module.scss';

import ArrowLeftIcon from '../../assets/icons/arrowLeftPink.svg';

const StepSelectPhoto = () => {
  const { t } = useTranslation('', { keyPrefix: 'selectPhoto' });
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [displayedModal, setDisplayedModal] = useState(null);
  const [videoLoaded, setVideoLoaded] = useState(false);

  const {
    cropFace: { isLoading: isLoadingCropFace },
    auth: { tokens: { accessToken } },
    giftFlow: { savedData: { giveawayId, brandName, flowType } },
    selectPhoto: { data: { giftoonUrl }, isLoading: isLoadingSelectPhoto },
  } = useSelector(({
    cropFace, auth, giftFlow, selectPhoto
  }) => ({
    cropFace, auth, giftFlow, selectPhoto
  }), shallowEqual);

  const [isGuest, setIsGuest] = useState(!!accessToken);

  const handleCloseModal = useCallback(() => setDisplayedModal(null), [setDisplayedModal]);

  const handleContinueAsGuest = () => {
    setIsGuest(true);
    setDisplayedModal(null);
  };

  const handleSetImage = useCallback((data) => {
    dispatch(croppingFace({ image: data, callback: () => navigate(STEP_CHECK_PHOTO) }));
  }, []);

  const handleSetFolderImage = useCallback((e) => {
    handleSetImage(e.target.files[0]);
  }, [handleSetImage]);

  const handleFacebookSetImage = useCallback((data) => {
    handleSetImage(data.images?.[0].source);
  }, [handleSetImage]);

  const handleOpenWebcamModal = useCallback(() => {
    if (accessToken || isGuest) {
      setDisplayedModal(MODALS.WEBCAM);
    } else {
      setDisplayedModal(MODALS.WITHOUT_AUTH);
    }
  }, [accessToken, setDisplayedModal, isGuest]);

  const buttonLeft = useMemo(() => (
    <Button
      buttonStyle={styles.footerButton}
      text={t('back')}
      iconLeft={ArrowLeftIcon}
      onClick={() => navigate(
        flowType === FLOW_TYPES.BRAND
          ? `${STEP_ADD_GIFTOON}/${giveawayId}`
          : `${STEP_ADD_COUPON}/${brandName}`
      )}
    />
  ), [brandName, giveawayId]);

  useEffect(() => {
    dispatch(SelectPhotoThunk({ id: giveawayId }));
    return () => {
      dispatch(clearSelectPhoto());
    };
  }, []);

  return (
    <SendingPage
      stepNumber={3}
      stepName={t('addFace')}
      buttonLeft={buttonLeft}
      loader={isLoadingCropFace || isLoadingSelectPhoto || !videoLoaded}
    >
      {giftoonUrl && (
        <section className={styles.section}>
          <VideoPlayer
            videoContainerStyles={styles.videoContainer}
            video={giftoonUrl}
            onLoadedData={() => setVideoLoaded(true)}
          />
          <div className={styles.textContainer}>
            <h2 className={styles.heading}>{t('startWithPhoto')}</h2>
            <p className={styles.description}>{t('description')}</p>
            <div className={styles.buttonContainer}>
              <ButtonList
                displayedModal={displayedModal}
                accessToken={accessToken}
                isGuest={isGuest}
                setDisplayedModal={setDisplayedModal}
                onFacebookSetImage={handleFacebookSetImage}
                onOpenWebcamModal={handleOpenWebcamModal}
                onSetFolderImage={handleSetFolderImage}
              />
            </div>
          </div>
        </section>
      )}
      <WebcamModal
        closeModal={handleCloseModal}
        onTakeImage={handleSetImage}
        isOpen={displayedModal === MODALS.WEBCAM}
      />
      <WithoutAuthModal
        closeModal={handleCloseModal}
        onContinueAsGuest={handleContinueAsGuest}
        isOpen={displayedModal === MODALS.WITHOUT_AUTH}
      />
    </SendingPage>
  );
};

export default StepSelectPhoto;
