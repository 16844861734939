import React, { useCallback, useMemo, useState } from 'react';
import { useFormik } from 'formik';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { STEP_CHECK_PHOTO, STEP_SELECT_BRAND } from '../../constants/links';
import { GIFTOON_TO_WHOM, SEND_GIFTOON_MODALS as MODALS } from '../../constants/constants';
import { sendGiftoonValidationSchema } from '../../utils/validation';
import { sendGiftFlowAsync } from '../../store/giftFlow/slice';
import Button from '../../components/Button/Button';
import ButtonLink from '../../components/ButtonLink/ButtonLink';
import RecipientInputForm from './components/RecipientInputForm';
import SenderInputForm from './components/SenderInputForm';
import ModalWindows from './components/ModalWindows';
import SendingPage from '../../layout/SendingPage/SendingPage';
import RadioButton from '../../components/RadioButton/RadioButton';
import styles from './sass/StepSendGiftoon.module.scss';

import ArrowIcon from '../../assets/icons/arrowLeftPink.svg';
import PlaneIcon from '../../assets/icons/plane.svg';
import ClockIcon from '../../assets/icons/clockPink.svg';
import GiftoonPreview from './components/GiftoonPreview';

const StepSendGiftoon = () => {
  const { t } = useTranslation('', { keyPrefix: 'sendGiftoon' });
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const giftReceiver = useSelector((state) => state.giftReceiver);
  const {
    giftFlow: { isLoading, data, savedData: { buyJustCoupon } },
    auth: { tokens: { accessToken } }
  } = useSelector((state) => ({ auth: state?.auth, giftFlow: state?.giftFlow }), shallowEqual);
  const [videoLoaded, setVideoLoaded] = useState(false);
  const [activeModal, setActiveModal] = useState('');
  const [date, setDate] = useState(
    giftReceiver?.sendAt && JSON.parse(giftReceiver?.sendAt) ? new Date(JSON.parse(giftReceiver?.sendAt)) : null
  );

  const {
    handleSubmit,
    values: {
      receiverFirstName, receiverLastName, phone, recipientEmail, message, senderName, senderEmail, toWhom
    },
    handleChange,
    validateForm,
    errors,
    handleBlur
  } = useFormik({
    initialValues: {
      receiverFirstName: giftReceiver?.firstName || '',
      receiverLastName: giftReceiver?.lastName || '',
      phone: giftReceiver?.phone || '',
      recipientEmail: giftReceiver?.email || '',
      message: giftReceiver?.message || '',
      senderName: giftReceiver?.senderName || '',
      senderEmail: giftReceiver?.reportsEmail || '',
      toWhom: !accessToken ? GIFTOON_TO_WHOM.GIFT : giftReceiver?.toWhom || GIFTOON_TO_WHOM.GIFT,
      isLoggedIn: !!accessToken
    },
    onSubmit: (values) => validateForm(values).then(() => {
      dispatch(sendGiftFlowAsync({
        receiverFirstName,
        receiverLastName,
        email: values.recipientEmail.trim(),
        sendAt: JSON.stringify(date),
        phone: values.phone,
        senderName: values.senderName,
        reportsEmail: values.senderEmail.trim(),
        toWhom: values.toWhom,
        message: values.message,
        navigate
      }));
    }),
    enableReinitialize: true,
    validationSchema: sendGiftoonValidationSchema,
    validateOnBlur: false,
    validateOnChange: false
  });

  const checkRadioButtonValue = useCallback((value) => (
    toWhom === value
  ), [toWhom]);

  const handleClickSendLater = useCallback(() => {
    validateForm().then((result) => {
      return !Object.keys(result).length && setActiveModal(MODALS.SENDING_TIME);
    });
  }, [validateForm]);

  const handleSendNow = useCallback(() => {
    setDate(null);
    handleSubmit();
  }, [setDate, handleSubmit]);

  const buttonLeft = useMemo(() => (
    <ButtonLink
      link={buyJustCoupon ? `${STEP_SELECT_BRAND}/${data?.brand?.product_name}` : STEP_CHECK_PHOTO}
      buttonStyle={styles.linkButton}
      text={t('back')}
      iconLeft={ArrowIcon}
    />
  ), [buyJustCoupon, data]);

  const buttonRight = useMemo(() => (
    <Button
      buttonStyle={styles.linkButton}
      text={t('sendNow')}
      iconRight={PlaneIcon}
      isFilled
      onClick={handleSendNow}
    />
  ), [handleSendNow]);

  const buttonRightExtra = useMemo(() => (
    <Button
      buttonStyle={styles.timeButton}
      text={t('sendLater')}
      iconLeft={ClockIcon}
      onClick={handleClickSendLater}
    />
  ), []);

  const loading = useMemo(() => (
    isLoading || (!buyJustCoupon && !videoLoaded)
  ), [isLoading, buyJustCoupon, videoLoaded]);

  const renderPage = useMemo(() => (
    data?.giftoon || buyJustCoupon
  ), [data, buyJustCoupon]);

  return (
    <SendingPage
      stepName={t('sendGiftoon')}
      stepNumber={4}
      buttonLeft={buttonLeft}
      buttonRight={buttonRight}
      buttonRightExtra={buttonRightExtra}
      loader={loading}
    >
      <section className={styles.section}>
        {renderPage && (
          <>
            <GiftoonPreview
              giftoon={data?.giftoon}
              brand={data?.brand}
              buyJustCoupon={buyJustCoupon}
              setVideoLoaded={setVideoLoaded}
            />
            <div className={styles.form}>
              <h1 className={styles.heading}>{t('recipientsDetails')}</h1>
              <div className={styles.buyOptionsContainer}>
                <RadioButton
                  name="toWhom"
                  label={t('buyAsGift')}
                  value={GIFTOON_TO_WHOM.GIFT}
                  onChange={handleChange}
                  isChecked={checkRadioButtonValue(GIFTOON_TO_WHOM.GIFT)}
                />
                {!!accessToken && (
                <RadioButton
                  name="toWhom"
                  label={t('buyForMyself')}
                  value={GIFTOON_TO_WHOM.MYSELF}
                  onChange={handleChange}
                  isChecked={checkRadioButtonValue(GIFTOON_TO_WHOM.MYSELF)}
                  disabled={!accessToken}
                />
                )}
              </div>
              <RecipientInputForm
                isGift={checkRadioButtonValue(GIFTOON_TO_WHOM.GIFT)}
                handleChange={handleChange}
                handleBlur={handleBlur}
                errors={errors}
                receiverFirstName={receiverFirstName}
                receiverLastName={receiverLastName}
                recipientEmail={recipientEmail}
                phone={phone}
                message={message}
              />
              <SenderInputForm
                isGift={checkRadioButtonValue(GIFTOON_TO_WHOM.GIFT)}
                handleChange={handleChange}
                handleBlur={handleBlur}
                errors={errors}
                senderName={senderName}
                senderEmail={senderEmail}
              />
            </div>
          </>
        )}
        
      </section>
      <ModalWindows
        date={date}
        activeModal={activeModal}
        setActiveModal={setActiveModal}
        setDate={setDate}
        handleSubmit={handleSubmit}
      />
    </SendingPage>
  );
};

export default StepSendGiftoon;
