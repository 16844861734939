import React, { useCallback, useEffect, useRef } from 'react';

const useImagePinch = ({
  onChangePosition, onScale, scale, onRotate, scaleConfig, rotateConfig, wrapperRef
}) => {
  const wheelTimeout = useRef();
  const prevPos = React.useRef({ x: 0, y: 0 });
  const prevTouch = React.useRef([]);
  const [imageSize, setImageSize] = React.useState({ width: 0, height: 0 });

  const positionCalculate = useCallback((x, y) => {
    const size = wrapperRef?.current?.getBoundingClientRect();

    const realImageWidth = imageSize.width * scale;
    const realImageHeight = imageSize.height * scale;
    const zeroX = -(imageSize.width - realImageWidth) / 2;
    const zeroY = -(imageSize.height - realImageHeight) / 2;

    const visibleSpace = 10 * scale;

    const leftSide = zeroX - realImageWidth + visibleSpace;
    const topSide = zeroY - realImageHeight + visibleSpace;
    const rightSide = zeroX + size.width - visibleSpace;
    const bottomSide = zeroY + size.height - visibleSpace;

    const newX = Math.min(Math.max(x, leftSide), rightSide);
    const newY = Math.min(Math.max(y, topSide), bottomSide);

    return { x: newX, y: newY };
  }, [imageSize, scale, wrapperRef]);

  const onLoadImage = useCallback((e) => {
    const size = wrapperRef?.current?.getBoundingClientRect();
    const { height, width } = e.target;
    const defaultScale = Math.min(size.width / width, size.height / height) + 0.05;
    const defaultPosition = {
      x: -(width - width * defaultScale) / 2,
      y: -(height - height * defaultScale) / 2,
    };

    setImageSize({ height, width });
    onScale(defaultScale);
    onChangePosition(defaultPosition);
  }, []);

  const handleStartMove = useCallback((e) => {
    prevPos.current = { x: e.pageX, y: e.pageY };
  }, []);

  const handleMove = useCallback((e) => {
    if (e.buttons === 1) {
      const moveX = e.pageX - prevPos.current.x;
      const moveY = e.pageY - prevPos.current.y;
      prevPos.current = { x: e.pageX, y: e.pageY };

      onChangePosition(({ x, y }) => positionCalculate(x + moveX, y + moveY));
    }
  }, [positionCalculate]);

  const handleStartTouch = useCallback((e) => {
    if (e.touches?.length) {
      const [touch] = e.touches;
      prevPos.current = { x: touch.pageX, y: touch.pageY };
    }
    if (e.touches?.length === 2) {
      prevTouch.current = [...e.touches];
    }
  }, []);

  const handleTouch = useCallback((e) => {
    if (e.touches?.length) {
      const [touch] = e.touches;
      const moveX = touch.pageX - prevPos.current.x;
      const moveY = touch.pageY - prevPos.current.y;
      prevPos.current = { x: touch.pageX, y: touch.pageY };
      onChangePosition(({ x, y }) => positionCalculate(x + moveX, y + moveY));
    }
    if (e.touches?.length === 2) {
      const [touch1, touch2] = e.touches;
      const [prevTouch1, prevTouch2] = prevTouch.current;
      if (onScale) {
        const prevDistance = Math.sqrt(
          (prevTouch1.pageX - prevTouch2.pageX) ** 2 + (prevTouch1.pageY - prevTouch2.pageY) ** 2,
        );
        const distance = Math.sqrt((touch1.pageX - touch2.pageX) ** 2 + (touch1.pageY - touch2.pageY) ** 2);
        const moveScale = distance / prevDistance;
        onScale((prevScale) => Math.min(Math.max(prevScale * moveScale, scaleConfig.min), scaleConfig.max));
      }
      if (onRotate) {
        const prevAngle = Math.atan2(prevTouch1.pageY - prevTouch2.pageY, prevTouch1.pageX - prevTouch2.pageX);
        const angle = Math.atan2(touch1.pageY - touch2.pageY, touch1.pageX - touch2.pageX);
        const moveRotate = (angle - prevAngle) * (180 / Math.PI);
        onRotate((prevRotate) => Math.min(Math.max(prevRotate + moveRotate, rotateConfig.min), rotateConfig.max));
      }
      prevTouch.current = [...e.touches];
    }
  }, [positionCalculate]);

  const handleWheel = useCallback((e) => {
    if (onScale) {
      onScale((prevScale) => Math.min(Math.max(prevScale - e.deltaY * 0.005, scaleConfig.min), scaleConfig.max));
    }
  }, []);

  const onWheel = useCallback((e) => {
    handleWheel(e);

    clearTimeout(wheelTimeout.current);
    wheelTimeout.current = setTimeout(() => {
      wheelTimeout.current = false;
    }, 300);
  }, []);

  useEffect(() => {
    // disable wheel on page is wheeling in crop face
    const cancelWheel = (e) => wheelTimeout.current && e.preventDefault();
    document.body.addEventListener('wheel', cancelWheel, { passive: false });
    return () => document.body.removeEventListener('wheel', cancelWheel);
  }, []);

  return {
    handleStartMove,
    handleMove,
    handleStartTouch,
    handleTouch,
    onWheel,
    onLoadImage,
  };
};

export default useImagePinch;
