import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { DateTime } from 'luxon';
import styles from '../sass/SentGiftoonsInfoRow.module.scss';

const SentGiftoonsInfoRow = ({
  giftoonTitle, brandTitle, giftoonImage, brandImage, receiverName, email, phone, sendDate, brandPrice
}) => {
  const { t } = useTranslation('', { keyPrefix: 'profileSentGiftoons' });
  const date = useMemo(() => {
    const newDate = DateTime.fromISO(sendDate);
    return newDate.isValid ? newDate.toFormat('dd/LL/yyyy') : '';
  }, [sendDate]);

  const withGiftoon = useMemo(() => !!giftoonTitle, [giftoonTitle]);

  return (
    <div className={styles.container}>
      <div className={styles.itemSection}>
        {withGiftoon && <img className={styles.itemImage} src={giftoonImage} alt="eCard" />}
        <p className={styles.itemText}>{withGiftoon ? giftoonTitle : t('withoutGiftoon')}</p>
      </div>
      <div className={styles.itemSection}>
        <img className={styles.itemImage} src={brandImage} alt="coupon" />
        <p className={styles.itemText}>{`${brandTitle} ($${brandPrice})`}</p>
      </div>
      <div className={styles.recipientSection}>
        <div className={styles.textContainer}>
          <h3 className={styles.recipientName}>{receiverName}</h3>
          <p className={styles.recipientEmail}>{email || phone}</p>
        </div>
      </div>
      <div className={styles.dateSection}>
        <p className={styles.date}>{date}</p>
      </div>
    </div>
  );
};

SentGiftoonsInfoRow.propTypes = {
  giftoonTitle: PropTypes.string.isRequired,
  brandTitle: PropTypes.string.isRequired,
  giftoonImage: PropTypes.string,
  brandImage: PropTypes.string.isRequired,
  receiverName: PropTypes.string,
  email: PropTypes.string,
  phone: PropTypes.string,
  sendDate: PropTypes.string.isRequired,
  brandPrice: PropTypes.number.isRequired,
};

SentGiftoonsInfoRow.defaultProps = {
  receiverName: '',
  email: '',
  phone: '',
  giftoonImage: '',
};

export default SentGiftoonsInfoRow;
