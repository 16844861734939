import React from 'react';
import { useTranslation } from 'react-i18next';
import styles from '../sass/Line.module.scss';

const Line = () => {
  const { t } = useTranslation('', { keyPrefix: 'signIn' });
  return (
    <div className={styles.line}>
      <span>{t('or')}</span>
    </div>
  );
};

export default Line;
