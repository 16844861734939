import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import instance from '../../services/axios';
import { SHOP_GIVEAWAY_API } from '../../constants/api';

export const initialState = {
  error: null,
  isLoading: false,
  data: {
    video: '',
    title: '',
    description: '',
    videoLength: null,
    persons: null,
  },
};

export const StepSelectedGiveawayThunk = createAsyncThunk(
  'stepSelectedGiveaway/stepSelectedGiveawayThunk',
  async ({ id }, { rejectWithValue }) => {
    try {
      const { data } = await instance.get(`${SHOP_GIVEAWAY_API}${id}/`);
      return data;
    } catch ({ response: { data, status } }) {
      return rejectWithValue({ data, code: status });
    }
  }
);

export const StepSelectedGiveawaySlice = createSlice({
  name: 'stepSelectedGiveaway',
  initialState,
  reducers: {
    clearStepSelectedGiveaway: () => (initialState)
  },
  extraReducers: (builder) => {
    builder
      .addCase(StepSelectedGiveawayThunk.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(StepSelectedGiveawayThunk.fulfilled, (state, action) => {
        state.isLoading = false;
        state.data.video = action.payload.video;
        state.data.title = action.payload.title;
        state.data.description = action.payload.description;
        state.data.videoLength = action.payload.video_length;
        state.data.persons = action.payload.persons;
      })
      .addCase(StepSelectedGiveawayThunk.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
      });
  }
});

export const { clearStepSelectedGiveaway } = StepSelectedGiveawaySlice.actions;
export default StepSelectedGiveawaySlice.reducer;
