import React from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import Input from '../../../components/Input/Input';
import styles from '../sass/SignUp.module.scss';

import UserIcon from '../../../assets/icons/user.svg';
import LetterIcon from '../../../assets/icons/message.svg';
import LockIcon from '../../../assets/icons/lock.svg';
import EyeIcon from '../../../assets/icons/passwordEye.svg';
import EyeCrossedIcon from '../../../assets/icons/passwordEyeCrossed.svg';

const InputForm = ({
  values,
  errors,
  handleBlur,
  handleChange,
  passwordIsHidden,
  onChangePasswordHidden
}) => {
  const { t } = useTranslation('', { keyPrefix: 'signUp' });

  const {
    name, email, password, repeatPassword
  } = values;

  return (
    <div className={styles.inputSection}>
      <Input
        type="text"
        placeholder={t('name')}
        iconLeft={UserIcon}
        onChange={handleChange}
        onBlur={handleBlur}
        value={name}
        error={errors.name}
        name="name"
      />
      <Input
        type="text"
        placeholder={t('email')}
        iconLeft={LetterIcon}
        onChange={handleChange}
        onBlur={handleBlur}
        value={email}
        error={errors.email}
        name="email"
      />
      <Input
        type={passwordIsHidden.password ? 'password' : 'text'}
        placeholder={t('password')}
        onChange={handleChange}
        onBlur={handleBlur}
        iconLeft={LockIcon}
        iconRight={passwordIsHidden.password ? EyeCrossedIcon : EyeIcon}
        value={password}
        error={errors.password}
        name="password"
        onRightIconClick={() => onChangePasswordHidden('password')}
      />
      <Input
        type={passwordIsHidden.repeatPassword ? 'password' : 'text'}
        placeholder={t('repeatPassword')}
        onChange={handleChange}
        onBlur={handleBlur}
        iconLeft={LockIcon}
        iconRight={passwordIsHidden.repeatPassword ? EyeCrossedIcon : EyeIcon}
        value={repeatPassword}
        error={errors.repeatPassword}
        name="repeatPassword"
        onRightIconClick={() => onChangePasswordHidden('repeatPassword')}
      />
    </div>
  );
};

InputForm.propTypes = {
  values: PropTypes.shape({
    name: PropTypes.string.isRequired,
    email: PropTypes.string.isRequired,
    password: PropTypes.string.isRequired,
    repeatPassword: PropTypes.string.isRequired,
  }).isRequired,
  errors: PropTypes.shape({
    name: PropTypes.string,
    email: PropTypes.string,
    password: PropTypes.string,
    repeatPassword: PropTypes.string
  }).isRequired,
  handleBlur: PropTypes.func.isRequired,
  handleChange: PropTypes.func.isRequired,
  onChangePasswordHidden: PropTypes.func.isRequired,
  passwordIsHidden: PropTypes.shape({ password: PropTypes.bool, repeatPassword: PropTypes.bool }).isRequired,
};

export default InputForm;
