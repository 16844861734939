import React from 'react';
import { useTranslation } from 'react-i18next';
import ButtonLink from '../../../components/ButtonLink/ButtonLink';
import { SHOP_BRANDS } from '../../../constants/links';
import styles from '../sass/InformationBlock.module.scss';

const InformationBlock = () => {
  const { t } = useTranslation('', { keyPrefix: 'home' });

  return (
    <div className={`${styles.block}`}>
      <div className={`container ${styles.container}`}>
        <div className={styles.wrapper}>
          <h2 className={styles.heading}>{t('shippingForSpecialPeople')}</h2>
          <p className={styles.description}>{t('withEGiftCards')}</p>
          <p className={styles.description}>{t('spoilLovedOnes')}</p>
          <ButtonLink link={SHOP_BRANDS} buttonStyle={styles.button} text={t('checkItOut')} isFilled />
        </div>
      </div>
    </div>
  );
};

export default InformationBlock;
