import React, {
  useCallback, useMemo, useState
} from 'react';
import { useTranslation } from 'react-i18next';
import { useFormik } from 'formik';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import FacebookLogin from '@greatsumini/react-facebook-login';
import { loginValidationSchema } from '../../utils/validation';
import { HOMEPAGE, VERIFY_EMAIL } from '../../constants/links';
import { SignInThunk } from '../../store/signIn/slice';
import { AuthFacebookThunk } from '../../store/auth/slice';
import { ResendEmailThunk, setResendButtonActive } from '../../store/resendEmail/slice';
import useTimeoutCallback from '../../hooks/useTimeoutCallback';
import Page from '../../layout/Page/Page';
import Button from '../../components/Button/Button';
import Input from '../../components/Input/Input';
import AfterInputSection from './components/AfterInputSection';
import Line from './components/Line';
import CreateAccountLink from './components/CreateAccountLink';
import ResendEmailText from './components/ResendEmailText';

import '../../styles/authentificationStyles.scss';
import styles from './sass/SignIn.module.scss';

import CancelIcon from '../../assets/icons/cancelIcon.svg';
import FacebookIcon from '../../assets/icons/facebookSquare.svg';
import SignInIcon from '../../assets/icons/signInCircleWhite.svg';
import LetterIcon from '../../assets/icons/message.svg';
import LockIcon from '../../assets/icons/lock.svg';
import EyeIcon from '../../assets/icons/passwordEye.svg';
import EyeCrossedIcon from '../../assets/icons/passwordEyeCrossed.svg';

const SignIn = () => {
  const { t } = useTranslation('', { keyPrefix: 'signIn' });
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  const [passwordIsHidden, setPasswordIsHidden] = useState(true);

  const redirectUrl = useMemo(() => location.search.replace('?redirect=', ''), []);

  const {
    signIn: {
      isLoading: isLoadingSignIn,
      error,
    },
    auth: {
      isLoading: isLoadingAuth
    },
    resendEmail: {
      isLoading: isLoadingResend,
      data: {
        disabledTimeEnd,
      }
    }
  } = useSelector((state) => ({
    signIn: state?.signIn, auth: state?.auth, resendEmail: state?.resendEmail
  }), shallowEqual);

  const {
    handleSubmit,
    values: { email, password, keepMeLoggedIn },
    handleChange,
    validateForm,
    errors,
    handleBlur
  } = useFormik({
    initialValues: { email: '', password: '', keepMeLoggedIn: false },
    onSubmit: (values) => validateForm(values).then(() => dispatch(SignInThunk({ values, navigate, redirectUrl }))),
    validationSchema: loginValidationSchema,
    validateOnBlur: false,
    validateOnChange: false
  });

  const handleResentButtonClick = () => {
    if (!disabledTimeEnd) {
      dispatch(ResendEmailThunk()).then(() => navigate(VERIFY_EMAIL));
    }
  };

  const userIsNotActive = useMemo(() => error?.data[0] === 'User is not active', [error]);

  const handleChangePasswordHidden = useCallback(() => {
    setPasswordIsHidden((prevState) => (!prevState));
  }, [setPasswordIsHidden]);

  const setResendButtonActiveCallback = useCallback(() => {
    dispatch(setResendButtonActive());
  }, []);

  useTimeoutCallback(setResendButtonActiveCallback, disabledTimeEnd - new Date().getTime());

  return (
    <Page withoutFooter loader={isLoadingSignIn || isLoadingAuth || isLoadingResend}>
      <section className={`authentificationContainer ${styles.section}`}>
        <button onClick={() => navigate(HOMEPAGE)} className={styles.cancelButton}>
          <img src={CancelIcon} alt="cancel" />
        </button>
        <h1 className={styles.signInHeading}>{t('signIn')}</h1>
        <div className={styles.inputSection}>
          <Input
            type="text"
            placeholder={t('email')}
            iconLeft={LetterIcon}
            onChange={handleChange}
            onBlur={handleBlur}
            value={email}
            error={errors.email}
            name="email"
          />
          <Input
            type={passwordIsHidden ? 'password' : 'text'}
            placeholder={t('password')}
            onChange={handleChange}
            onBlur={handleBlur}
            iconLeft={LockIcon}
            iconRight={passwordIsHidden ? EyeCrossedIcon : EyeIcon}
            value={password}
            error={errors.password}
            name="password"
            onRightIconClick={handleChangePasswordHidden}
          />
        </div>
        <AfterInputSection handleChange={handleChange} keepMeLoggedIn={keepMeLoggedIn} />
        <div className={`buttonContainer ${styles.signInButtonContainer}`}>
          <Button
            text={t('signIn')}
            isFilled
            iconRight={SignInIcon}
            onClick={handleSubmit}
            type="submit"
          />
        </div>
        { userIsNotActive && <ResendEmailText onResendClick={handleResentButtonClick} /> }
        <Line />
        <div className={styles.alternativeLogIn}>
          <div className="buttonContainer">
            <Button
              text={t('continueAsAGuest')}
              isFilled
              onClick={() => navigate(HOMEPAGE)}
            />
          </div>
          <div className="buttonContainer">
            <FacebookLogin
              appId={process.env.REACT_APP_FACEBOOK_ID}
              fields="name,email"
              scope="user_photos,public_profile,email"
              onSuccess={(facebook) => {
                dispatch(AuthFacebookThunk({ facebook, navigate }));
              }}
              render={({ onClick }) => (
                <Button
                  text={t('facebookSignIn')}
                  iconLeft={FacebookIcon}
                  isFilled
                  buttonStyle={styles.blueBtn}
                  onClick={onClick}
                />
              )}
            />
          </div>
        </div>
        <CreateAccountLink />
      </section>
    </Page>
  );
};

export default SignIn;
