export const MAIN_API_URL = process.env.REACT_APP_API_URL;

export const REFRESH_TOKEN_API = `${MAIN_API_URL}/users/refresh-token/`;

export const SIGN_UP_API = `${MAIN_API_URL}/users/signup/`;

export const VERIFY_EMAIL_API = `${MAIN_API_URL}/users/signup/confirm/`;

export const SIGN_IN_API = `${MAIN_API_URL}/users/login/`;
export const FACEBOOK_LOGIN_API = `${MAIN_API_URL}/users/facebook-login/`;

export const CONTACT_US = `${MAIN_API_URL}/users/contact-us/`;

export const RESET_PASSWORD_API = `${MAIN_API_URL}/users/password/`;
export const RESET_PASSWORD_CONFIRM_API = `${MAIN_API_URL}/users/password/confirm/`;
export const SAVE_CARD_API = `${MAIN_API_URL}/users/save-card/`;

export const SHOP_BRANDS_API = `${MAIN_API_URL}/shop/brands/`;
export const SHOP_DISCOUNTS_API = `${MAIN_API_URL}/shop/discounts/`;
export const SHOP_BRANDS_CATEGORY_API = `${MAIN_API_URL}/shop/brands/categories/`;

export const SHOP_GIVEAWAY_API = `${MAIN_API_URL}/shop/giveaways/`;
export const SHOP_GIVEAWAY_CATEGORY_API = `${MAIN_API_URL}/shop/giveaways/categories/`;

export const USERS_API = '/users/';
export const RESEND_EMAIL_API = `${MAIN_API_URL}/users/signup/resend/`;
export const PINPAY_CHARGE = '/users/pinpay/charge/';
export const PINPAY_VERIFY_TOKEN = '/users/pinpay/verify_token/';

export const GIFTOONS_API = `${MAIN_API_URL}/giftoons/`;
export const GIFTOONS_SHARE_EMAIL_API = `${MAIN_API_URL}/giftoons/share_giftoon/`;
export const GIFTOONS_SEND_API = `${MAIN_API_URL}/giftoons/send/`;
export const GIFTOON_IMAGE_API = `${MAIN_API_URL}/giftoons/image/`;
export const GIFTOON_IMAGE_VERIFY_API = `${MAIN_API_URL}/giftoons/verify-image/`;
export const RECEIVED_GIFTOONS_API = `${MAIN_API_URL}/giftoons/get_received_giftoons/`;
export const ENCRYPT_GIFTOON_API = `${MAIN_API_URL}/giftoons/encrypt_giftoon/`;
export const PUBLIC = '/public';
export const PRIVATE = '/private';

export const GET_SENT_GIFTOONS_API = `${MAIN_API_URL}/giftoons/get_sent_giftoons/`;

export const GET_CARD_API = `${MAIN_API_URL}/giftoons/get_card/`;

export const NOT_FOUND_ANSWER = 'Not found.';
/* Client Errors */
export const BAD_REQUEST = 400;
export const UNAUTHORIZED = 401;
export const NOT_FOUND = 404;
export const FORBIDDEN = 403;
export const VALIDATION_ERROR = 422;

/* Successfully */
export const OK = 200;
export const CREATED = 201;
export const DELETED = 204;
