import React from 'react';
import PropTypes from 'prop-types';
import { NavLink } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import {
  SHOP_BRANDS, PROFILE, SIGN_IN, SIGN_UP, HOW_IT_WORKS
} from '../../../constants/links';
import useLogout from '../../../hooks/useLogout';
import ButtonLink from '../../../components/ButtonLink/ButtonLink';
import styles from '../sass/MobileNav.module.scss';

import SignInIcon from '../../../assets/icons/signInCirclePink.svg';
import SignOutIcon from '../../../assets/icons/signOutCircleWhite.svg';

const MobileNav = ({ showMenu, loggedIn }) => {
  const { t } = useTranslation('', { keyPrefix: 'header' });
  const logout = useLogout();

  return (
    <div className={`${styles.mobileNav} ${showMenu && styles.active}`}>
      <nav>
        <li>
          <NavLink to={SHOP_BRANDS} className={({ isActive }) => (isActive ? styles.active : '')}>
            {t('buyGiftCards')}
          </NavLink>
        </li>
        <li>
          <NavLink to={HOW_IT_WORKS} className={({ isActive }) => (isActive ? styles.active : '')}>
            {t('about')}
          </NavLink>
        </li>
        {loggedIn && (
          <li>
            <NavLink to={PROFILE} className={({ isActive }) => (isActive ? styles.active : '')}>
              {t('myAccount')}
            </NavLink>
          </li>
        )}
      </nav>
      <div className={styles.buttonsContainer}>
        {loggedIn ? (
          <ButtonLink
            text={t('logout')}
            iconLeft={SignOutIcon}
            link={SIGN_IN}
            buttonStyle={styles.logInButton}
            onClick={logout}
            isFilled
          />
        ) : (
          <>
            <ButtonLink text={t('signIn')} iconLeft={SignInIcon} link={SIGN_IN} buttonStyle={styles.logInButton} />
            <ButtonLink text={t('signUp')} link={SIGN_UP} buttonStyle={styles.logInButton} isFilled />
          </>
        )}
      </div>
    </div>
  );
};

MobileNav.propTypes = {
  showMenu: PropTypes.bool,
  loggedIn: PropTypes.bool
};

MobileNav.defaultProps = {
  showMenu: false,
  loggedIn: false
};

export default MobileNav;
