import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import styles from './sass/ButtonLink.module.scss';

const ButtonLink = ({
  isFilled, buttonStyle, text, iconRight, iconLeft, onClick, type, link
}) => {
  return (
    <Link
      type={type}
      onClick={onClick}
      className={`${isFilled && styles.filled} ${styles.link} ${buttonStyle}`}
      to={link}
    >
      {iconLeft && <img src={iconLeft} alt="icon" /> }
      {text && <span>{text}</span>}
      {iconRight && <img src={iconRight} alt="icon" /> }
    </Link>
  );
};

ButtonLink.propTypes = {
  isFilled: PropTypes.bool,
  buttonStyle: PropTypes.string,
  text: PropTypes.string.isRequired,
  iconLeft: PropTypes.string,
  iconRight: PropTypes.string,
  onClick: PropTypes.func,
  type: PropTypes.string,
  link: PropTypes.string.isRequired
};

ButtonLink.defaultProps = {
  isFilled: false,
  iconLeft: null,
  iconRight: null,
  buttonStyle: '',
  onClick: () => {},
  type: 'button'
};

export default ButtonLink;
