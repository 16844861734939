import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { DeleteAccountThunk } from '../../../store/deleteAccount/slice';
import Button from '../../../components/Button/Button';
import Modal from '../../../components/Modal/Modal';
import styles from '../sass/DeleteAccountSection.module.scss';

import CloseButtonIcon from '../../../assets/icons/closeButton.svg';
import SadSmileIcon from '../../../assets/images/bigSadEmoji.jpg';

const DeleteAccountSection = () => {
  const { t } = useTranslation('', { keyPrefix: 'profileSettings' });
  const dispatch = useDispatch();
  const [modalOpened, setModalOpened] = useState(false);

  const handleDeleteAccount = () => {
    dispatch(DeleteAccountThunk());
  };

  return (
    <>
      <Button onClick={() => setModalOpened(true)} buttonStyle={styles.button} text={t('deleteAccount')} />
      <Modal isOpen={modalOpened} onClose={() => setModalOpened(false)} containerStyles={styles.modalWrapper}>
        <div className={styles.modalContainer}>
          <Button
            buttonStyle={styles.closeButton}
            iconLeft={CloseButtonIcon}
            onClick={() => setModalOpened(false)}
          />
          <div className={styles.textBlock}>
            <img className={styles.icon} src={SadSmileIcon} alt="sad emoji" />
            <h1>{t('areYouSure')}</h1>
            <p>{t('thisWillEraseAccount')}</p>
          </div>
          <div className={styles.buttonContainer}>
            <Button onClick={() => setModalOpened(false)} buttonStyle={styles.modalButton} text={t('cancel')} />
            <Button onClick={handleDeleteAccount} buttonStyle={styles.modalButton} text={t('delete')} isFilled />
          </div>
        </div>
      </Modal>
    </>
  );
};

export default DeleteAccountSection;
