import React from 'react';
import PropTypes from 'prop-types';
import useImagePinch from './useImagePinch';
import useCropImage from './useCropImage';
import styles from './sass/CropFace.module.scss';

import FaceMaskImage from '../../assets/icons/faceMask.svg';
import { ReactComponent as FaceMask } from '../../assets/icons/faceMaskClipPath.svg';

const CropFace = ({
  scale,
  rotate,
  onScale,
  onRotate,
  scaleConfig,
  rotateConfig,
  className,
  position,
  onChangePosition,
  cropRef,
  image
}) => {
  const wrapperRef = React.useRef(null);
  const faceMaskRef = React.useRef(null);

  const {
    handleStartMove,
    handleMove,
    handleStartTouch,
    handleTouch,
    onWheel,
    onLoadImage,
  } = useImagePinch({
    onChangePosition,
    onScale,
    onRotate,
    scale,
    scaleConfig,
    rotateConfig,
    wrapperRef,
  });

  const {
    invisibleCropPosition,
    invisibleCropImage,
    visibleCropImage
  } = useCropImage({
    position, scale, rotate, wrapperRef, faceMaskRef
  });

  return (
    <div
      ref={wrapperRef}
      id="cropFace"
      role="presentation"
      className={`${styles.cropFace} ${className}`}
      onMouseDown={handleStartMove}
      onMouseMove={handleMove}
      onTouchStart={handleStartTouch}
      onTouchMove={handleTouch}
      onWheel={onWheel}
    >
      <div className={styles.invisibleCrop} style={invisibleCropPosition}>
        <div ref={cropRef} className={styles.invisibleCrop__cropping}>
          <FaceMask ref={faceMaskRef} />
          <img
            src={image}
            alt="face"
            className={styles.cropFace__image}
            draggable={false}
            style={invisibleCropImage}
          />
        </div>
      </div>
      <div className={styles.visibleCrop}>
        <img
          src={image}
          alt="face"
          className={styles.cropFace__image}
          draggable={false}
          style={visibleCropImage}
          onLoad={onLoadImage}
        />
        <img src={FaceMaskImage} alt="face mask" className={styles.visibleCrop__mask} draggable={false} />
      </div>
    </div>
  );
};

CropFace.propTypes = {
  image: PropTypes.string.isRequired,
  position: PropTypes.shape({
    x: PropTypes.number,
    y: PropTypes.number,
  }).isRequired,
  cropRef: PropTypes.shape({ current: PropTypes.instanceOf(Element) }),
  onChangePosition: PropTypes.func.isRequired,
  onRotate: PropTypes.func,
  onScale: PropTypes.func,
  scale: PropTypes.number,
  rotate: PropTypes.number,
  scaleConfig: PropTypes.shape({
    min: PropTypes.number,
    max: PropTypes.number,
  }),
  rotateConfig: PropTypes.shape({
    min: PropTypes.number,
    max: PropTypes.number,
  }),
  className: PropTypes.string,
};

CropFace.defaultProps = {
  cropRef: null,
  onRotate: undefined,
  onScale: undefined,
  scale: 1,
  rotate: 0,
  scaleConfig: {
    min: 0.01,
    max: 5,
  },
  rotateConfig: {
    min: -180,
    max: 180,
  },
  className: '',
};

export default React.memo(CropFace);
