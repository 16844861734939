import React from 'react';
import Webcam from 'react-webcam';
import PropTypes from 'prop-types';
import { WEBCAM_IMAGE_FORMAT } from '../../../constants/constants';
import Button from '../../../components/Button/Button';
import Modal from '../../../components/Modal/Modal';
import styles from '../sass/WebcamModal.module.scss';

import CameraIcon from '../../../assets/icons/cameraDarkPurple.svg';
import CrossIcon from '../../../assets/icons/whiteCross.svg';

const WebcamModal = ({ closeModal, onTakeImage, isOpen }) => {
  const takeImage = (screenshot) => {
    onTakeImage(screenshot);
    closeModal();
  };

  const videoConstraints = {
    facingMode: 'user'
  };

  return (
    isOpen && (
    <Modal isOpen={isOpen} onClose={closeModal}>
      <div className={styles.webcamWindow}>
        <Webcam
          audio={false}
          screenshotFormat={WEBCAM_IMAGE_FORMAT}
          videoConstraints={videoConstraints}
        >
          {({ getScreenshot }) => (
            <div>
              <Button
                iconLeft={CameraIcon}
                buttonStyle={`${styles.button} ${styles.cameraButton}`}
                onClick={() => takeImage(getScreenshot())}
              />
              <Button
                iconLeft={CrossIcon}
                buttonStyle={`${styles.button} ${styles.crossButton}`}
                onClick={closeModal}
              />
            </div>
          )}
        </Webcam>
      </div>
    </Modal>
    )
  );
};

WebcamModal.propTypes = {
  closeModal: PropTypes.func.isRequired,
  onTakeImage: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired
};

export default WebcamModal;
