import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import styles from './sass/Slider.module.scss';

import Plus from '../../assets/icons/plus.svg';
import Minus from '../../assets/icons/minus.svg';

const Slider = ({
  onChange, value, min, max, step, title, leftIcon, rightIcon, className
}) => {
  const handleOnChange = useCallback((e) => {
    onChange(Number(e.target.value));
  }, [onChange]);

  const onClickNextStep = useCallback(() => {
    onChange((oldValue) => Math.min(Math.max(oldValue + step, min), max));
  }, []);

  const onClickBackStep = useCallback(() => {
    onChange((oldValue) => Math.min(Math.max(oldValue - step, min), max));
  }, []);

  return (
    <div className={`${styles.wrapper} ${className}`}>
      <div className={styles.title}>
        <button onClick={onClickBackStep}>
          <img src={leftIcon} alt="minus" />
        </button>
        <span>{title}</span>
        <button onClick={onClickNextStep}>
          <img src={rightIcon} alt="plus" />
        </button>
      </div>
      <input
        type="range"
        className={styles.slider}
        min={min}
        max={max}
        value={value}
        onChange={handleOnChange}
        step={step}
      />
    </div>
  );
};

Slider.propTypes = {
  onChange: PropTypes.func.isRequired,
  value: PropTypes.number,
  min: PropTypes.number,
  max: PropTypes.number,
  step: PropTypes.number,
  title: PropTypes.string,
  leftIcon: PropTypes.string,
  rightIcon: PropTypes.string,
  className: PropTypes.string,
};

Slider.defaultProps = {
  value: undefined,
  min: 0,
  max: 100,
  step: 1,
  title: '',
  leftIcon: Minus,
  rightIcon: Plus,
  className: '',
};

export default Slider;
