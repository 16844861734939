import React from 'react';
import { useTranslation } from 'react-i18next';
import { useFormik } from 'formik';
import { useNavigate } from 'react-router-dom';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { restorePasswordValidationSchema } from '../../utils/validation';
import { ResetPasswordThunk } from '../../store/resetPassword/slice';
import Page from '../../layout/Page/Page';
import Input from '../../components/Input/Input';
import Button from '../../components/Button/Button';

import '../../styles/authentificationStyles.scss';
import styles from './sass/ResetPassword.module.scss';

import ResetIcon from '../../assets/icons/signInCircleWhite.svg';
import LetterIcon from '../../assets/icons/message.svg';

const ResetPassword = () => {
  const { t } = useTranslation('', { keyPrefix: 'resetPassword' });
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { isLoading } = useSelector(({ resetPassword }) => resetPassword, shallowEqual);

  const {
    handleSubmit,
    values: { email },
    handleChange,
    validateForm,
    errors,
    handleBlur,
  } = useFormik({
    initialValues: {
      email: ''
    },
    onSubmit: (currentValues) => validateForm(currentValues).then(() => {
      dispatch(ResetPasswordThunk({ email: currentValues.email, navigate }));
    }),
    validationSchema: restorePasswordValidationSchema,
    validateOnBlur: false,
    validateOnChange: false
  });

  return (
    <Page withoutFooter loader={isLoading}>
      <section className={`authentificationContainer ${styles.authentificationContainer}`}>
        <h1>{t('resetPassword')}</h1>
        <p className={styles.enterEmail}>{t('enterEmail')}</p>
        <div className={styles.emailInput}>
          <Input
            type="text"
            placeholder={t('email')}
            iconLeft={LetterIcon}
            onChange={handleChange}
            onBlur={handleBlur}
            value={email}
            error={errors.email}
            name="email"
          />
        </div>
        <div className="buttonContainer">
          <Button onClick={handleSubmit} text={t('reset')} iconRight={ResetIcon} isFilled />
        </div>
        <p className={styles.note}>
          <span>{`${t('note')}:`}</span>
          {` ${t('checkSpam')}`}
        </p>
      </section>
    </Page>
  );
};

export default ResetPassword;
