import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import instance from '../../services/axios';
import { RESET_PASSWORD_CONFIRM_API } from '../../constants/api';
import { setTokens } from '../auth/slice';
import { setId } from '../profile/slice';
import notify from '../../utils/notify';

export const initialState = {
  error: null,
  isLoading: false,
  data: true,
};

export const ForgotPasswordThunk = createAsyncThunk(
  'forgotPassword/ForgotPasswordThunk',
  async ({
    password, repeatPassword, token, navigate
  }, { rejectWithValue, dispatch }) => {
    try {
      const result = await instance.post(
        RESET_PASSWORD_CONFIRM_API,
        { token, confirm_password: repeatPassword, password },
        { withoutAuth: true }
      );
      const {
        token_data: { refresh_token: refreshToken, access_token: accessToken },
        user_data: { id }
      } = result.data;

      localStorage.setItem('accessToken', accessToken);
      localStorage.setItem('refreshToken', refreshToken);
      localStorage.setItem('id', id);

      dispatch(setTokens({ accessToken, refreshToken }));
      dispatch(setId(id));
      navigate('/');

      return result.data;
    } catch (error) {
      notify('error', 'Invalid token');
      return rejectWithValue(error.message);
    }
  }
);

export const ForgotPasswordThunkSlice = createSlice({
  name: 'forgotPassword',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(ForgotPasswordThunk.pending, (state) => ({
        ...state,
        isLoading: true
      }))
      .addCase(ForgotPasswordThunk.fulfilled, (state, action) => {
        state.isLoading = false;
        state.data = action.payload;
      })
      .addCase(ForgotPasswordThunk.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
      });
  },
});

export default ForgotPasswordThunkSlice.reducer;
