import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import styles from './sass/Input.module.scss';

const Input = ({
  type,
  placeholder,
  autocomplete,
  inputStyle,
  iconLeft,
  iconRight,
  onClick,
  onChange,
  onBlur,
  onRightIconClick,
  onLeftIconClick,
  value,
  name,
  label,
  error,
  isDisabled,
}) => {
  const [focused, setFocused] = useState(false);

  const handleBlur = useCallback((e) => {
    onBlur(e);
    setFocused(false);
  }, [onBlur]);

  return (
    <label className={styles.label} htmlFor={name}>
      {label}
      <div
        onClick={onClick}
        role="presentation"
        className={`${styles.inputField} ${error && styles.error} ${focused && styles.focused}`}
      >
        {iconLeft && (
          <img
            className={styles.iconLeft}
            src={iconLeft}
            onClick={onLeftIconClick}
            alt="icon"
            role="presentation"
          />
        )}
        <input
          autoComplete={autocomplete}
          disabled={isDisabled}
          type={type}
          className={`
            ${styles.inputText} 
            ${iconRight ? '' : styles.disableMarginRight} 
            ${iconLeft ? '' : styles.disableMarginLeft}
            ${inputStyle} 
            `}
          placeholder={placeholder}
          onChange={onChange}
          value={value}
          id={name}
          name={name}
          onBlur={(e) => handleBlur(e)}
          onFocus={() => setFocused(true)}
        />
        {iconRight && (
          <img
            className={styles.iconRight}
            src={iconRight}
            onClick={onRightIconClick}
            alt="icon"
            role="presentation"
          />
        )}
      </div>
      {error && <span className={styles.errorMessage}>{error}</span>}
    </label>
  );
};

Input.propTypes = {
  type: PropTypes.string,
  placeholder: PropTypes.string,
  autocomplete: PropTypes.string,
  inputStyle: PropTypes.string,
  iconLeft: PropTypes.string,
  iconRight: PropTypes.string,
  value: PropTypes.string.isRequired,
  name: PropTypes.string,
  label: PropTypes.string,
  error: PropTypes.string,
  onClick: PropTypes.func,
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  onRightIconClick: PropTypes.func,
  onLeftIconClick: PropTypes.func,
  isDisabled: PropTypes.bool
};

Input.defaultProps = {
  type: 'text',
  placeholder: '',
  autocomplete: 'on',
  inputStyle: '',
  name: '',
  label: '',
  error: '',
  iconLeft: null,
  iconRight: null,
  onClick: () => {},
  onChange: () => {},
  onBlur: () => {},
  onRightIconClick: () => {},
  onLeftIconClick: () => {},
  isDisabled: false
};

export default Input;
