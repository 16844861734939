import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import instance from '../../services/axios';
import { SHOP_BRANDS_API } from '../../constants/api';
import { PRODUCT_NAME } from '../../constants/links';

export const initialState = {
  error: null,
  isLoading: false,
  data: [
    {
      id: null,
      price_high: null,
      price_low: null,
      activation_fee: null,
      high_resolution_image: '',
      image_url: '',
      product_name: '',
      marketingdescription: '',
      termsconditions: '',
      expiration_date: '',
    }
  ]
};

export const BrandSelectAmountThunk = createAsyncThunk(
  'brandSelectAmount/brandSelectAmountThunk',
  async ({ productName }, { rejectWithValue }) => {
    try {
      const { data } = await instance.get(`${SHOP_BRANDS_API}${PRODUCT_NAME}${productName}`);
      return data;
    } catch ({ response: { data, status } }) {
      return rejectWithValue({ data, code: status });
    }
  }
);

export const BrandSelectAmountSlice = createSlice({
  name: 'brandSelectAmount',
  initialState,
  reducers: {
    clearSelectAmount: () => (initialState)
  },
  extraReducers: (builder) => {
    builder
      .addCase(BrandSelectAmountThunk.pending, (state) => ({
        ...state,
        isLoading: true,
      }))
      .addCase(BrandSelectAmountThunk.fulfilled, (state, action) => {
        state.isLoading = false;
        state.data = action.payload;
      })
      .addCase(BrandSelectAmountThunk.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
      });
  }
});

export const { clearSelectAmount } = BrandSelectAmountSlice.actions;
export default BrandSelectAmountSlice.reducer;
