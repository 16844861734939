import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { NavLink } from 'react-router-dom';
import { HOW_IT_WORKS, PROFILE, SHOP_BRANDS } from '../../../constants/links';
import styles from '../sass/Header.module.scss';

const NavigationLinks = ({ isLoggedIn }) => {
  const { t } = useTranslation('', { keyPrefix: 'header' });
  return (
    <nav>
      <li>
        <NavLink to={SHOP_BRANDS} className={({ isActive }) => (isActive ? styles.active : '')}>
          {t('buyGiftCards')}
        </NavLink>
      </li>
      <li>
        <NavLink to={HOW_IT_WORKS} className={({ isActive }) => (isActive ? styles.active : '')}>
          {t('about')}
        </NavLink>
      </li>
      {isLoggedIn && (
        <li>
          <NavLink to={PROFILE} className={({ isActive }) => (isActive ? styles.active : '')}>
            {t('myAccount')}
          </NavLink>
        </li>
      )}
    </nav>
  );
};

NavigationLinks.propTypes = {
  isLoggedIn: PropTypes.bool.isRequired,
};

export default NavigationLinks;
