import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import instance from '../../services/axios';
import { SAVE_CARD_API } from '../../constants/api';
import notify from '../../utils/notify';

export const initialState = {
  error: null,
  isLoadingSaveCard: false,
  isLoadingGetCard: false,
  data: {
    creditCard: '',
  },
};

export const SaveCardThunk = createAsyncThunk(
  'saveCard/saveCardThunk',
  async ({ cardNumber }, { rejectWithValue }) => {
    try {
      const { data } = await instance.post(SAVE_CARD_API, { card_number: cardNumber });
      notify('success', 'Your card has been saved successfully!');
      return data;
    } catch ({ response: { data, status } }) {
      notify('error', Object.values(data)[0]);
      return rejectWithValue({ data, code: status });
    }
  }
);

export const GetCardThunk = createAsyncThunk(
  'saveCard/getCardThunk',
  async (_, { rejectWithValue }) => {
    try {
      const { data } = await instance.get(SAVE_CARD_API);
      return data;
    } catch ({ response: { data, status } }) {
      return rejectWithValue({ data, code: status });
    }
  }
);

export const SaveCardSlice = createSlice({
  name: 'saveCard',
  initialState,
  extraReducers: (builder) => {
    builder
      .addCase(SaveCardThunk.pending, (state) => {
        state.isLoadingSaveCard = true;
      })
      .addCase(SaveCardThunk.fulfilled, (state) => {
        state.isLoadingSaveCard = false;
      })
      .addCase(SaveCardThunk.rejected, (state, action) => {
        state.isLoadingSaveCard = false;
        state.error = action.payload;
      })
      .addCase(GetCardThunk.pending, (state) => {
        state.isLoadingGetCard = true;
      })
      .addCase(GetCardThunk.fulfilled, (state, action) => {
        state.isLoadingGetCard = false;
        state.data.creditCard = action.payload.card_number;
      })
      .addCase(GetCardThunk.rejected, (state, action) => {
        state.isLoadingGetCard = false;
        state.error = action.payload;
      });
  }
});

export default SaveCardSlice.reducer;
