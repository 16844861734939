import React, { useCallback, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { STEP_ADD_COUPON, STEP_SELECT_GIFTOON } from '../../constants/links';
import { SelectBrandsCategoriesThunk, SelectBrandsThunk } from '../../store/selectBrands/slice';
import { SHOP_BRANDS_LIMIT } from '../../constants/constants';
import ButtonLink from '../../components/ButtonLink/ButtonLink';
import SendingPage from '../../layout/SendingPage/SendingPage';
import Input from '../../components/Input/Input';
import useDebounce from '../../hooks/useDebounce';
import filterButtonClick from '../../helpers/filterButtonClick';
import BrandList from '../../components/BrandList/BrandList';
import ShopBrandFilter from '../../components/ShopBrandFilter/ShopBrandFilter';
import styles from './sass/StepAddBrands.module.scss';

import ArrowLeftIcon from '../../assets/icons/arrowLeftPink.svg';
import SearchIcon from '../../assets/icons/search.svg';
import CrossIcon from '../../assets/icons/cross.svg';

const StepAddBrandList = () => {
  const { t } = useTranslation('', { keyPrefix: 'addBrandList' });
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();

  const filter = useMemo(() => (
    searchParams.get('filter')
  ), [searchParams]);

  const search = useMemo(() => (
    searchParams.get('search')
  ), [searchParams]);

  const activeFilter = useMemo(() => (
    filter?.split(',') || ['all']
  ), [filter]);

  const {
    selectBrands: {
      data: {
        categories, brandList, next,
      }
    },
    giftFlow: {
      savedData: {
        giveawayId,
      }
    }
  } = useSelector(({ selectBrands, giftFlow }) => ({ selectBrands, giftFlow }));

  const searchDebounce = useDebounce(search, 500);

  const handleDispatchShopBrandsThunk = () => {
    dispatch(SelectBrandsThunk({
      limit: SHOP_BRANDS_LIMIT,
      search,
      filter: filter ?? [],
    }));
  };

  useEffect(() => {
    dispatch(SelectBrandsCategoriesThunk({}));
  }, []);

  useEffect(() => {
    if (categories.length) {
      handleDispatchShopBrandsThunk();
    }
  }, [searchDebounce, filter, categories]);

  const handleFilterButtonClick = useCallback((item) => (
    filterButtonClick(item, activeFilter, searchParams, setSearchParams, filter)
  ), [filter, searchParams, setSearchParams, activeFilter]);

  const handleSearchChange = (input) => {
    if (!input) {
      searchParams.delete('search');
    } else {
      searchParams.set('search', input);
    }
    setSearchParams(searchParams.toString());
  };

  const buttonLeft = useMemo(() => (
    <ButtonLink
      buttonStyle={styles.leftButton}
      text={t('back')}
      iconLeft={ArrowLeftIcon}
      link={`${STEP_SELECT_GIFTOON}/${giveawayId}`}
    />
  ), []);

  const handeSelectBrand = useCallback((id) => {
    navigate(`${STEP_ADD_COUPON}/${id}`);
  }, []);

  useEffect(() => {
    dispatch(SelectBrandsCategoriesThunk({}));
  }, []);

  return (
    <SendingPage stepName={t('addBrand')} stepNumber={2} buttonLeft={buttonLeft}>
      <section className={`container ${styles.section}`}>
        <h2>{t('addBrand')}</h2>
        <div className={styles.couponInput}>
          <Input
            iconLeft={SearchIcon}
            iconRight={CrossIcon}
            onChange={(e) => handleSearchChange(e.target.value)}
            placeholder={t('searchBrands')}
            value={search ?? ''}
            onRightIconClick={() => setSearchParams({ search: '' })}
          />
        </div>
        <ShopBrandFilter
          categories={[{ name: 'all', description: 'All' }, ...categories]}
          activeFilters={activeFilter}
          onClick={handleFilterButtonClick}
        />
        <BrandList
          brandList={brandList}
          next={next}
          thunk={SelectBrandsThunk}
          onSelectBrand={handeSelectBrand}
        />
      </section>
    </SendingPage>
  );
};

export default StepAddBrandList;
