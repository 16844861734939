import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import instance from '../../services/axios';
import { CONTACT_US } from '../../constants/api';

export const initialState = {
  error: null,
  isLoading: false,
  data: {},
};

export const ContactUsThunk = createAsyncThunk(
  'contactUs/contactUsThunk',
  async ({ name, email, message }, { rejectWithValue }) => {
    try {
      const { data } = await instance.post(CONTACT_US, { name, email, message }, { wihoutAuth: true });
      return data;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const ContactUsSlice = createSlice({
  name: 'contactUs',
  initialState,
  reducers: {
    clearContactUs: () => initialState
  },
  extraReducers: (builder) => {
    builder
      .addCase(ContactUsThunk.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(ContactUsThunk.fulfilled, (state, action) => {
        state.isLoading = false;
        state.data = action.payload;
      })
      .addCase(ContactUsThunk.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
      });
  }
});

export const { clearContactUs } = ContactUsSlice.actions;
export default ContactUsSlice.reducer;
