import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { GiftShareViewThunk } from '../../store/giftView/slice';
import { GIFT_VIEW_MODALS as MODALS, VIDEO_STATES } from '../../constants/constants';
import ErrorChecker from '../../hoc/ErrorChecker/ErrorChecker';
import Page from '../../layout/Page/Page';
import VideoPlayer from '../../components/VideoPlayer/VideoPlayer';
import VideoModal from '../../components/VideoModal/VideoModal';
import GiftViewModals from '../../components/GiftViewModals/GiftViewModals';
import styles from './sass/GiftShareView.module.scss';

const GiftShareView = () => {
  const dispatch = useDispatch();
  const { hash } = useParams();

  const [videoProgress, setVideoProgress] = useState(VIDEO_STATES.NOT_PLAYED);
  const [openedModal, setOpenedModal] = useState('');
  const [videoLoaded, setVideoLoaded] = useState(false);

  const { data: { video: giftoon }, error } = useSelector(({ giftView }) => giftView);

  useEffect(() => {
    dispatch(GiftShareViewThunk({ hash }));
  }, []);

  return (
    <ErrorChecker error={error}>
      <Page withoutFooter pageContainerStyle={styles.pageContainer} loader={!videoLoaded}>
        <section className={styles.section}>
          <div className={styles.videoContainer}>
            <VideoPlayer
              videoContainerStyles={styles.video}
              playerStyles={styles.video}
              video={giftoon}
              onLoadedData={() => setVideoLoaded(true)}
              repeat={videoProgress === VIDEO_STATES.REPEATING}
              controlsShown={videoProgress !== VIDEO_STATES.FINISHED}
              onVideoFinished={() => setVideoProgress(VIDEO_STATES.FINISHED)}
            />
            <VideoModal
              isOpen={videoProgress === VIDEO_STATES.FINISHED}
              restartVideo={() => setVideoProgress(VIDEO_STATES.REPEATING)}
              openShareModal={() => setOpenedModal(MODALS.SHARE_MODAL)}
            />
          </div>
          <GiftViewModals
            giftoonUrl={window.location.href}
            activeModal={openedModal}
            setActiveModal={setOpenedModal}
          />
        </section>
      </Page>
    </ErrorChecker>
  );
};

export default GiftShareView;
