import React, { useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import FacebookLogin from '@greatsumini/react-facebook-login';
import { SELECT_PHOTO_MODALS as MODALS } from '../../constants/constants';
import { setFacebookToken } from '../../store/facebook/slice';
import { getFacebookImages } from '../../store/facebookImages/slice';
import ImageGallery from '../ImageGallery/ImageGallery';

const FacebookImageSelector = ({
  onSelectImage, renderButton, setDisplayedModal, displayedModal
}) => {
  const dispatch = useDispatch();

  const {
    facebook: {
      accessToken: facebookToken,
      dateExpiresIn,
    },
    facebookImages: {
      data,
      paging,
      isLoading
    }
  } = useSelector((state) => ({
    facebook: state.facebook?.data,
    facebookImages: state?.facebookImages
  }), shallowEqual) || {};

  const onCloseFacebookModal = useCallback(() => {
    setDisplayedModal(null);
  }, []);

  const handleSetFacebookImage = useCallback((token) => {
    if (token) {
      dispatch(setFacebookToken(token));
      dispatch(getFacebookImages({ token }));
      setDisplayedModal(MODALS.IMAGE_GALLERY);
    }
  }, [setDisplayedModal, dispatch]);

  const onClickFacebook = useCallback((token) => (handleSetFacebookImage(token)), [handleSetFacebookImage]);

  const onNext = useCallback(() => {
    dispatch(getFacebookImages({ isNext: true }));
  }, []);

  const dataGallery = useMemo(() => data?.map((item) => ({
    src: item.images[0].source,
    id: item.id,
    altText: item.alt_text,
    origin: item
  })) || [], [data]);

  return (
    <>
      <ImageGallery
        onClose={onCloseFacebookModal}
        isOpen={displayedModal === MODALS.IMAGE_GALLERY}
        data={dataGallery}
        onSelect={onSelectImage}
        hasMore={!!paging?.next}
        onNext={onNext}
        isLoading={isLoading}
      />
      {facebookToken && dateExpiresIn >= new Date().getTime() ? (
        renderButton({ onClick: onClickFacebook })
      ) : (
        <FacebookLogin
          appId={process.env.REACT_APP_FACEBOOK_ID}
          render={({ onClick }) => renderButton({ onClick })}
          initParams={{
            version: 'v15.0',
          }}
          loginOptions={{
            return_scopes: true,
          }}
          onSuccess={handleSetFacebookImage}
          fields="name,email,picture"
          scope="public_profile,user_photos,email"
        />
      )}
    </>
  );
};

FacebookImageSelector.propTypes = {
  onSelectImage: PropTypes.func.isRequired,
  renderButton: PropTypes.func.isRequired,
  setDisplayedModal: PropTypes.func.isRequired,
  displayedModal: PropTypes.string,
};

FacebookImageSelector.defaultProps = {
  displayedModal: ''
};

export default FacebookImageSelector;
