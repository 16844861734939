import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import styles from '../sass/StepSelectBrand.module.scss';

const BrandTerms = ({ setRetailModalOpened }) => {
  const { t } = useTranslation('', { keyPrefix: 'selectBrands' });

  return (
    <span
      role="presentation"
      onClick={() => setRetailModalOpened(true)}
      className={styles.terms}
    >
      {t('retailerGiftCard')}
    </span>
  );
};

BrandTerms.propTypes = {
  setRetailModalOpened: PropTypes.func.isRequired
};

export default BrandTerms;
