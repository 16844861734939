const filterButtonClick = (item, activeFilter, searchParams, setSearchParams, filter) => {
  if (item === 'all') {
    searchParams.delete('filter');
    setSearchParams(searchParams.toString());
  } else if (filter && filter.includes(item)) {
    const newFilter = activeFilter.filter((activeItem) => activeItem !== item);
    if (!newFilter.length) {
      searchParams.delete('filter');
    } else {
      searchParams.set('filter', newFilter?.join(','));
    }
    setSearchParams(searchParams.toString());
  } else {
    const addedFilterItem = `${filter},${item}`;
    searchParams.set('filter', `${!filter ? item : addedFilterItem}`);
    setSearchParams(searchParams.toString());
  }
};

export default filterButtonClick;
