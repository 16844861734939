import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import instance from '../../services/axios';
import { SHOP_BRANDS_CATEGORY_API } from '../../constants/api';
import { SHOP_BRANDS_LIMIT } from '../../constants/constants';
import { getBrandsRequest } from '../../helpers/requests';

export const initialState = {
  error: null,
  isLoadingShopBrands: false,
  isLoadingCategories: false,
  data: {
    limit: SHOP_BRANDS_LIMIT,
    categories: [],
    brandList: [],
    next: null
  },
};

export const ShopBrandsThunk = createAsyncThunk(
  'shopBrands/shopBrandsThunk',
  async ({
    url, limit, search, filter = []
  }, { rejectWithValue, getState }) => {
    try {
      const { shopBrands: { data: { brandList, categories } } } = getState();

      const data = await getBrandsRequest(categories, filter, url, limit, search);

      return {
        brandList: url ? [...brandList, ...data.brands] : data.brands, next: data.brands.length ? data.next : null
      };
    } catch ({ response: { data, status } }) {
      return rejectWithValue({ data, code: status });
    }
  }
);

export const ShopBrandCategoriesThunk = createAsyncThunk(
  'shopBrands/shopBrandsCategoriesThunk',
  async ({ rejectWithValue }) => {
    try {
      const result = await instance.get(
        SHOP_BRANDS_CATEGORY_API,
        {},
        { withoutAuth: true }
      );
      return result.data.results;
    } catch ({ response: { data, status } }) {
      return rejectWithValue({ data, code: status });
    }
  }
);

export const ShopBrandsSlice = createSlice({
  name: 'shopBrands',
  initialState,
  extraReducers: (builder) => {
    builder
      .addCase(ShopBrandsThunk.pending, (state) => ({
        ...state,
        isLoadingShopBrands: true
      }))
      .addCase(ShopBrandsThunk.fulfilled, (state, action) => ({
        ...state,
        isLoadingShopBrands: false,
        data: {
          ...state.data,
          brandList: action.payload.brandList,
          next: action.payload.next,
        }
      }))
      .addCase(ShopBrandsThunk.rejected, (state, action) => {
        state.isLoadingShopBrands = false;
        state.error = action.payload;
      })
      .addCase(ShopBrandCategoriesThunk.pending, (state) => ({
        ...state,
        isLoadingCategories: true
      }))
      .addCase(ShopBrandCategoriesThunk.fulfilled, (state, action) => {
        state.isLoadingCategories = false;
        state.data.categories = action.payload;
      })
      .addCase(ShopBrandCategoriesThunk.rejected, (state, action) => {
        state.isLoadingCategories = false;
        state.error = action.payload;
      });
  },
});

export default ShopBrandsSlice.reducer;
