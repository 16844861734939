import React, {
  useCallback, useEffect, useMemo, useState 
} from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { clearCropFaceVerify } from '../../store/cropFaceVerify/slice';
import { disableFlowLoading, processGiftFlowAsync, setFaceId } from '../../store/giftFlow/slice';
import { STEP_CROP_PHOTO, STEP_SELECT_PHOTO, STEP_SEND_GIFTOON } from '../../constants/links';
import SendingPage from '../../layout/SendingPage/SendingPage';
import ImageSlider from '../../components/ImageSlider/ImageSlider';
import ButtonLink from '../../components/ButtonLink/ButtonLink';
import Button from '../../components/Button/Button';
import styles from './sass/StepCheckPhoto.module.scss';

import ArrowLeftIcon from '../../assets/icons/arrowLeftPink.svg';
import ArrowRightIcon from '../../assets/icons/arrowRightWhite.svg';
import Edit from '../../assets/icons/edit.svg';

const StepCheckPhoto = () => {
  const { t } = useTranslation('', { keyPrefix: 'checkPhoto' });
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const {
    cropFace: { data, isLoading: isLoadingCropFace },
    cropFaceVerify: { data: faceVerify, isLoading: isLoadingVerify },
    giftFlow: { isLoading: isLoadingGiftFlow }
  } = useSelector(({ cropFaceVerify, cropFace, giftFlow }) => ({ cropFaceVerify, cropFace, giftFlow }), shallowEqual);

  const [currentFace, setCurrentFace] = useState(0);

  const faces = useMemo(() => (faceVerify?.image
    ? [faceVerify]
    : data?.faces || []), [data, faceVerify]);

  const facesImages = useMemo(() => faces.map(({ image }) => image), [faces]);

  const goToNextStep = useCallback(() => {
    const selectedFace = faces[currentFace];
    dispatch(setFaceId(selectedFace.id));
    dispatch(processGiftFlowAsync({ faceId: selectedFace.id, navigate: () => navigate(STEP_SEND_GIFTOON) }));
  }, [currentFace, faces, data]);

  const buttonLeft = useMemo(() => (
    <ButtonLink
      buttonStyle={styles.button}
      text={t('back')}
      iconLeft={ArrowLeftIcon}
      link={STEP_SELECT_PHOTO}
      onClick={() => dispatch(clearCropFaceVerify())}
    />
  ), []);

  const buttonRight = useMemo(() => (
    <Button
      buttonStyle={styles.button}
      onClick={goToNextStep}
      text={t('nextStep')}
      iconRight={ArrowRightIcon}
      isFilled
    />
  ), [goToNextStep]);
  
  useEffect(() => {
    dispatch(disableFlowLoading());
  }, []);

  return (
    <SendingPage
      stepNumber={3}
      stepName={t('addFace')}
      buttonLeft={buttonLeft}
      buttonRight={buttonRight}
      loader={isLoadingCropFace || isLoadingVerify || isLoadingGiftFlow}
    >
      <section className={styles.section}>
        <div className={styles.wrapper}>
          <ImageSlider onChange={setCurrentFace} images={facesImages} />
          <div className={styles.info}>
            <h2 className={styles.header}>
              {`${t('lookinGood')}${facesImages.length > 1 ? t('selectTheRightFace') : ''}`}
            </h2>
            <ButtonLink text={t('editFace')} iconLeft={Edit} buttonStyle={styles.button} link={STEP_CROP_PHOTO} />
          </div>
        </div>
      </section>
    </SendingPage>
  );
};

export default StepCheckPhoto;
