import React from 'react';
import Page from '../../layout/Page/Page';
import '../../styles/contentPages.scss';

const TermsAndConditions = () => {
  return (
    <Page>
      <section className="contentSection">
        <div className="contentContainer">
          <h1>Terms and Conditions</h1>
          <p>
            <span className="highlightedText">Please read the following Terms of Sale carefully.</span>
            &nbsp;In your purchase of products from Giftoon, you agree to be bound under the Terms of Sale effective
            at the time of your purchase and all terms incorporated by
            reference. Should you not agree to all of these terms, do not make any purchases from Giftoon . App Happy
            Pty Ltd (“Giftoon ”, “we” or “us”) make products available for purchase via our websites, mobile
            applications (“App”) and other products and services (collectively, the “Services”). Your purchase of any
            products through these is governed by these Terms of Sale (“Terms”) and your use of the Services is governed
            by our Terms of Service Terms and Conditions (available via the Service). We reserve the right to change or
            modify these Terms at any time at our sole discretion. Should we make changes to these Terms, notice will be
            provided such as through sending you a notification, providing notice through the Services or by updating
            the “Last Updated” date at the top of these Terms. We strongly advise you to review these Terms each time
            you make a purchase from Giftoon so as to ensure that you understand the terms and conditions applicable to
            your purchase.
            <br />
            Should you have any enquiries relating to these Terms, please contact us at hello@giftoon.com.
          </p>

          <h2>Availability and Pricing</h2>
          <p className="highlightedText">
            A variety of non-reloadable digital Gift Cards, codes and/or vouchers (collectively, “Gift Cards”) are
            offered for purchase through our Services. All Gift Cards are subject to availability and we reserve the
            right to impose quantity limits on any order, reject all or any part of an order and to discontinue products
            without notice, even if you have already placed your order. All prices are subject to change without notice
            and the price charged will be the price set out on the Service at the time of your order. The minimum price
            of a Gift Card sold via Giftoon is $5 and the maximum price of a Gift Card sold is $1000 (subject to each
            Retailer). If you purchase multiple Gift Cards on a single day, the total amount that you purchase on all
            the cards cannot exceed $1,000. Gift Cards purchased through Giftoon are available for use within Australia
            and may be available for use in or sending to recipients in other countries. Please consult the Giftoon Gift
            Card wall via the Service and each Retailer’s (as defined below) terms and conditions for the use of Gift
            Cards outside of Australia.
          </p>

          <h2>Gift Cards</h2>
          <p className="highlightedText">
            From time to time we may offer other special promotions intended to provide you an incentive to purchase
            Retailer Gift Cards and/or to encourage you to get others to do so. The applicable rules will be posted on
            the Site in or near the description of each such promotion and/or competition. We reserve the right to
            interpret these rules in our sole discretion, and you hereby agree to our interpretation.
            <br />
            The expiration date for a Gift Card is displayed on or on the back of the Gift Card, in your digital wallet.
            Please refer to the expiry date to ensure you redeem your Gift Card in the allocated time frame. Giftoon is
            not responsible for users to redeem their Gift Card before the expiration date.
            <br />
            Unless otherwise stated by the applicable Retailer, Gift Cards are not redeemable for cash.
            <br />
            Each Gift Card purchased by you will be listed as a separate Gift Card. By way of reference, in the event
            that you purchase two Gift Cards from the same Retailer, your wallet will reflect two Gift Cards, as opposed
            to one Gift Card with the value of the two Gift Cards purchased. Giftoon may, in its sole discretion and
            under guidance by relevant laws, from time to time implement a limit on the value of Gift Cards that may be
            stored in the Giftoon wallet at any one time.
            Giftoon is not responsible if a Gift Card is lost, stolen, destroyed, or used without permission. Giftoon is
            not responsible if any Gift Card is rendered unsuitable for redemption through no fault of Giftoon . If you
            have lost or misplaced your Gift Card and the balance has not been spent or changed from the original
            amount, email us at hello@Giftoon.com, and we will send you a replacement provided we can confirm the
            balance is unused.
          </p>

          <h2>Retailer Terms and Liability</h2>
          <p className="highlightedText">
            Gift Cards that you purchase through our Services are issued by participating third-party retailers
            (“Retailers”). Your purchase of each Gift Card is governed by certain terms and conditions established by
            the issuing Retailer in addition to these Terms. Retailers set expiration dates for Gift Cards in addition
            to other restrictions and requirements. Please review all applicable Retailers’ terms and conditions prior
            to the purchase of any Gift Card. If you have questions relating to the applicable Retailer terms, please
            contact the issuing Retailer. As the issuers of Gift Cards, Retailers (and not Giftoon) are fully
            responsible for all aspects of their Gift Card programs. Retailers are responsible for all liabilities and
            costs suffered by you or any other party caused – directly or indirectly – by the Retailer or their products
            or services. We have no obligation to resolve any dispute between you and any Retailer. Giftoon endeavours
            to work with reputable Retailers, however Giftoon holds no liability for the sale of products or services
            provided to you by a Retailer. In purchasing a Gift Card from an issuing Retailer, you waive and release
            Giftoon and its subsidiaries, affiliates, partners, officers, directors, employees and agents from any
            liabilities arising from or related to any act or omission of a Retailer. Following the purchase of a Gift
            Card, Giftoon shall not be responsible or liable to you in the event a Retailer discontinues its Gift Cards
            or is unable to or refuses to redeem a Gift Card (including for reasons of their insolvency, external
            administration or forced closure), Neither Giftoon nor any Retailer is responsible for lost or stolen Gift
            Cards. If you lose your mobile device, please contact hello@giftoon.com immediately so we can put a hold on
            your account.
          </p>

          <h2>Payment Options</h2>
          <p className="highlightedText">
            Accepted forms of payment are credit cards (a “Card”) for example Visa, MasterCard, American Express and
            ApplePay. Unless indicated otherwise, the purchase of Gift Cards will be fully billed at the time the Gift
            Card is issued to you. Giftoon uses a third-party PCI compliant payment processor and we do not hold any
            financial information. If your payment cannot be verified, is invalid or otherwise unacceptable, your order
            may be suspended or cancelled automatically. Card processing fees may apply even if the Card has expired or
            changed by the time Giftoon submits the charges. Any problems encountered in the processing of your order
            are not the responsibility of Giftoon.
          </p>

          <h2>Taxes</h2>
          <p className="highlightedText">
            Gift Cards are free of GST. GST or other taxes are not accounted for until the cards are used to purchase
            goods. These may be charged separately by the applicable Retailer at the time of purchase.
          </p>

          <h2>Gift Card Activation</h2>
          <p className="highlightedText">
            All Gift Cards sold via our Services are already activated at time of purchase. Activated Gift Cards will be
            available within approximately 30 seconds however additional time may be required for certain activations.
            If your Gift Card has not been activated within five minutes, please contact us at hello@giftoon.com.
            In rare cases it may take up to 24 hours for activation to occur, we appreciate your patience.
          </p>

          <h2>Cancellations; Returns</h2>
          <p className="highlightedText">
            Please choose carefully as we do not offer exchange or refund on Gift Cards.
            A re-issue of faulty or damaged Gift Cards is not available unless the fault or damage to the Gift Card is
            proven to be a result of the production or issuing process or otherwise due to the fault of us or our agents
            or contractors or where otherwise required by law. If you seek a return or refund please contact
            hello@giftoon.com with the reason for your request and proof of purchase for us to review. If any value on a
            Gift Card has been spent we are unable to process a return or refund unless required to by law. Giftoon is
            not responsible for the refund of purchase price or return of any products purchased from Retailers.
          </p>

          <h2>Lost or Stolen Gift Cards</h2>
          <p className="highlightedText">
            Treat your Gift Card like cash. Lost or stolen Gift Cards will not be replaced or refunded. The bearer of
            the Gift Card will be taken to be the owner of the Gift Card. If you lose your mobile device please contact
            us immediately so we can put a hold on your account. If someone has partially or fully redeemed your Gift
            Card(s) while your mobile device is lost or stolen, Giftoon shall not be liable.
          </p>

          <h2>Your Gift Card, your responsibility</h2>
          <p className="highlightedText">
            You are responsible for the use and safety of your Gift Card. You are liable for all transactions on your
            Gift Card, except to the extent to which there has been negligence by us. Protect your Gift Card information
            by viewing it in privacy to ensure no one views and records your Gift Card information. We strongly
            encourage all users to have a passcode on your mobile device.
          </p>

          <h2>Errors</h2>
          <p className="highlightedText">
            Giftoon attempts to be as accurate as possible however sometimes errors may occur. We do not represent or
            warrant that any description, Gift Card, Retailer or pricing information displayed on our Service is
            accurate, complete, reliable, current or error-free. In the event of an error, we will endeavour to correct
            it as quickly as possible and revise your order accordingly, or to cancel the order and refund any charged
            amount.
          </p>

          <h2>Excluded Users and Territories</h2>
          <p className="highlightedText">
            You are not permitted to purchase products or services from Giftoon if you are:
            Located in, under the control of, a national or resident of any country to which Australia has embargoed
            goods or services; or
            <br />
            Identified as a “Specially Designated National”; or
            <br />
            Placed on any Australian export control list.
          </p>

          <h2>Contact Us</h2>
          <p className="highlightedText">
            Should you have any questions or concerns regarding these Terms, our Services or require assistance please
            contact us at hello@giftoon.com.
          </p>
        </div>
      </section>
    </Page>
  );
};

export default TermsAndConditions;
