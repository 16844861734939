import React from 'react';
import PropTypes from 'prop-types';
import styles from './sass/ImageSlider.module.scss';

import { ReactComponent as ArrowPrevDisable } from '../../assets/icons/arrowPrevDisable.svg';
import { ReactComponent as ArrowPrevActive } from '../../assets/icons/arrowPrevActive.svg';

const PrevArrow = ({
  className, style, onClick, currentSlide
}) => (
  <button className={`${className} ${styles.arrow}`} onClick={onClick} style={style}>
    {currentSlide <= 0 ? <ArrowPrevDisable /> : <ArrowPrevActive />}
  </button>
);

PrevArrow.propTypes = {
  className: PropTypes.string,
  style: PropTypes.shape({}),
  onClick: PropTypes.func,
  currentSlide: PropTypes.number,
};

PrevArrow.defaultProps = {
  className: '',
  style: undefined,
  onClick: undefined,
  currentSlide: 0,
};

export default PrevArrow;
