import React from 'react';
import PropTypes from 'prop-types';
import VideoPlayer from '../../../components/VideoPlayer/VideoPlayer';
import VideoModal from '../../../components/VideoModal/VideoModal';
import { GIFT_VIEW_MODALS as MODALS, VIDEO_STATES } from '../../../constants/constants';
import styles from '../sass/CouponContent.module.scss';

const CouponContent = ({
  withGiftoon, giftoon, giveaway, brand, videoProgress, setVideoProgress, setVideoLoaded, setActiveModal
}) => {
  return (
    <div className={styles.container}>
      {withGiftoon ? (
        <div className={styles.videoWrapper}>
          <VideoPlayer
            videoContainerStyles={styles.videoPlayerContainer}
            playerStyles={styles.videoPlayer}
            video={giftoon}
            repeat={videoProgress === VIDEO_STATES.REPEATING}
            controlsShown={videoProgress !== VIDEO_STATES.FINISHED}
            onVideoFinished={() => setVideoProgress(VIDEO_STATES.FINISHED)}
            onLoadedData={() => setVideoLoaded(true)}
          />
          <VideoModal
            isOpen={videoProgress === VIDEO_STATES.FINISHED}
            restartVideo={() => setVideoProgress(VIDEO_STATES.REPEATING)}
            openShareModal={() => setActiveModal(MODALS.SHARE_MODAL)}
            videoUrl={giftoon}
            giftoonName={giveaway?.title}
          />
        </div>
      ) : (
        <img
          className={styles.brandImage}
          alt={brand.product_name}
          src={brand.high_resolution_image || brand.image_url}
        />
      )}
    </div>
  );
};

CouponContent.propTypes = {
  withGiftoon: PropTypes.bool.isRequired,
  giftoon: PropTypes.string,
  giveaway: PropTypes.shape({ title: PropTypes.string }),
  brand: PropTypes.shape({
    image_url: PropTypes.string,
    high_resolution_image: PropTypes.string,
    product_name: PropTypes.string,
  }).isRequired,
  videoProgress: PropTypes.string.isRequired,
  setVideoProgress: PropTypes.func.isRequired,
  setVideoLoaded: PropTypes.func.isRequired,
  setActiveModal: PropTypes.func.isRequired
};

CouponContent.defaultProps = {
  giftoon: '',
  giveaway: null,
};

export default CouponContent;
