import React from 'react';
import { useTranslation } from 'react-i18next';
import { useFormik } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import { profileChangePasswordValidation } from '../../../utils/validation';
import { ProfileChangePasswordThunk } from '../../../store/profile/slice';
import PasswordInputForm from './PasswordInputForm';
import Button from '../../../components/Button/Button';
import styles from '../sass/ChangePasswordSection.module.scss';

const ChangePasswordSection = () => {
  const { t } = useTranslation('', { keyPrefix: 'profileSettings' });
  const { id } = useSelector(({ profile }) => profile.data);
  const dispatch = useDispatch();

  const {
    handleSubmit,
    values: {
      currentPassword, newPassword, confirmNewPassword
    },
    handleChange,
    validateForm,
    errors,
    handleBlur,
    dirty,
  } = useFormik({
    initialValues: {
      currentPassword: '', newPassword: '', confirmNewPassword: ''
    },
    onSubmit: (values, { resetForm }) => validateForm(values).then(() => {
      dispatch(ProfileChangePasswordThunk({ id }));
      resetForm({ values: '' });
    }),
    validationSchema: profileChangePasswordValidation,
    validateOnBlur: false,
    validateOnChange: false
  });
  
  return (
    <div className={styles.changePasswordSection}>
      <h2 className={styles.changePasswordHeading}>{t('changePassword')}</h2>
      <PasswordInputForm
        currentPassword={currentPassword}
        newPassword={newPassword}
        confirmNewPassword={confirmNewPassword}
        errors={errors}
        handleChange={handleChange}
        handleBlur={handleBlur}
      />
      <p className={styles.passwordWarning}>{t('minCharactersLong')}</p>
      <Button onClick={handleSubmit} buttonStyle={styles.saveButton} text={t('savePassword')} isDisabled={!dirty} />
    </div>
  );
};

export default ChangePasswordSection;
