import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import styles from '../sass/Profile.module.scss';

const Tab = ({ title, onClick, isActive }) => {
  const { t } = useTranslation('', { keyPrefix: 'profile' });

  return (
    <div
      className={`${styles.tab} ${isActive ? styles.activeTab : ''}`}
      role="presentation"
      onClick={() => onClick(title)}
    >
      <h2 className={styles.tabHeading}>{t(title)}</h2>
    </div>
  );
};

Tab.propTypes = {
  title: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
  isActive: PropTypes.bool
};

Tab.defaultProps = {
  isActive: false
};

export default Tab;
