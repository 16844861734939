import React from 'react';
import { useTranslation } from 'react-i18next';
import { SHOP_BRANDS } from '../../../constants/links';
import ButtonLink from '../../../components/ButtonLink/ButtonLink';
import styles from '../sass/BannerBlock.module.scss';

import HomeBanner from '../../../assets/images/homeBanner.png';
import HomeBannerCircle from '../../../assets/images/homeBannerCircle.png';
import Line from '../../../assets/images/lineWhite.png';

const BannerBlock = () => {
  const { t } = useTranslation('', { keyPrefix: 'home' });

  return (
    <div className={styles.slide}>
      <div className={`container ${styles.container}`}>
        <img className={styles.homeBannerCircle} src={HomeBannerCircle} alt={t('giftoon')} />
        <div className={styles.left}>
          <h1>
            {t('homeBannerHeading')}
            <span>
              {t('homeBannerHeading2')}
              <img src={Line} alt="line" className={styles.line} />
            </span>
          </h1>
          <p className={styles.customText}>{t('homeBannerDescription')}</p>
          <ButtonLink
            link={SHOP_BRANDS}
            buttonStyle={styles.button}
            text={t('shopNow')}
            isFilled
          />
        </div>
        <img className={styles.homeBanner} src={HomeBanner} alt={t('giftoon')} />
      </div>
    </div>
  );
};

export default BannerBlock;
