import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import styles from '../sass/BrandsSlider.module.scss';

const BrandSliderItem = ({
  image, heading, currency, priceLow, priceHigh, link
}) => {
  const priceLine = useMemo(() => (
    priceLow === priceHigh ? `${currency} $${priceLow}` : `${currency} $${priceLow} - ${currency} $${priceHigh}`
  ), [currency, priceLow, priceHigh]);
  
  return (
    <div className={styles.sliderItem}>
      <div className={styles.itemContainer}>
        <Link className={styles.cardLink} to={link}>
          <img className={styles.sliderImage} src={image} alt="brand" />
        </Link>
        <div className={styles.itemContent}>
          <h4>{heading}</h4>
          <span>{priceLine}</span>
        </div>
      </div>
    </div>
  );
};

BrandSliderItem.propTypes = {
  image: PropTypes.oneOfType([PropTypes.string, PropTypes.shape({})]).isRequired,
  heading: PropTypes.string.isRequired,
  currency: PropTypes.string.isRequired,
  priceLow: PropTypes.number.isRequired,
  priceHigh: PropTypes.number.isRequired,
  link: PropTypes.string.isRequired
};

export default BrandSliderItem;
