import React from 'react';
import { useTranslation } from 'react-i18next';
import { SHOP_GIFTOONS } from '../../constants/links';
import { HELP_EMAIL } from '../../constants/constants';
import Page from '../../layout/Page/Page';
import ButtonLink from '../../components/ButtonLink/ButtonLink';
import StepRow from './components/StepRow';
import styles from './sass/HowItWorks.module.scss';

import StepOneImage from '../../assets/images/howItWorks1.jpg';
import StepOneImage2x from '../../assets/images/howItWorks1x2.jpg';
import StepTwoImage from '../../assets/images/howItWorks2.jpg';
import StepTwoImage2x from '../../assets/images/howItWorks2x2.jpg';
import StepThreeImage from '../../assets/images/howItWorks3.jpg';
import StepThreeImage2x from '../../assets/images/howItWorks3x2.jpg';
import StepFourImage from '../../assets/images/howItWorks4.jpg';
import StepFourImage2x from '../../assets/images/howItWorks4x2.jpg';
import ArrowIcon from '../../assets/icons/arrowRightPink.svg';

const HowItWorks = () => {
  const { t } = useTranslation('', { keyPrefix: 'howItWorks' });

  return (
    <Page pageContainerStyle={styles.pageContainer}>
      <section className={styles.section}>
        <div className={styles.stepsContainer}>
          <StepRow
            stepNumber="01"
            heading={t('stepOneHeading')}
            description={<p>{t('stepOneDescription')}</p>}
            image={StepOneImage}
            image2x={StepOneImage2x}
          />
          <StepRow
            stepNumber="02"
            heading={t('stepTwoHeading')}
            description={(
              <>
                <p>{t('stepTwoDescription1')}</p>
                <p>{t('stepTwoDescription2')}</p>
                <p className={styles.smallText}>{t('stepTwoDescription3')}</p>
              </>
            )}
            image={StepTwoImage}
            image2x={StepTwoImage2x}
          />
          <StepRow
            stepNumber="03"
            heading={t('stepThreeHeading')}
            description={<p>{t('stepThreeDescription')}</p>}
            image={StepThreeImage}
            image2x={StepThreeImage2x}
          />
          <StepRow
            stepNumber="04"
            heading={t('stepFourHeading')}
            description={(
              <>
                <p>{t('stepFourDescription1')}</p>
                <p>{t('stepFourDescription2')}</p>
                <p className={styles.smallText}>
                  {t('stepFourDescription3')}
                  <a href={`mailto:${HELP_EMAIL}`}>{t('stepFourDescription4')}</a>
                  {t('stepFourDescription5')}
                </p>
              </>
            )}
            image={StepFourImage}
            image2x={StepFourImage2x}
          />
        </div>
        <ButtonLink
          link={SHOP_GIFTOONS}
          buttonStyle={styles.button}
          text={t('selectAGiftoon')}
          iconRight={ArrowIcon}
        />
      </section>
    </Page>
  );
};

export default HowItWorks;
