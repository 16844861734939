import React from 'react';
import PropTypes from 'prop-types';
import useOutsideClick from '../../hooks/useOutsideClick';
import styles from './sass/Modal.module.scss';

const Modal = ({
  children, onClose, isOpen, containerStyles
}) => {
  const ref = useOutsideClick(onClose, false);

  return (
    isOpen && (
      <div className={styles.modal}>
        <div className={containerStyles} ref={ref}>
          {children}
        </div>
      </div>
    )
  );
};

Modal.propTypes = {
  children: PropTypes.node.isRequired,
  onClose: PropTypes.func,
  isOpen: PropTypes.bool,
  containerStyles: PropTypes.string,
};

Modal.defaultProps = {
  onClose: () => {},
  isOpen: false,
  containerStyles: '',
};

export default Modal;
