import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import styles from '../sass/OrderNumberText.module.scss';
import CopyImage from '../../../assets/icons/copy.svg';

const OrderNumberText = ({ orderNumber }) => {
  const { t } = useTranslation('', { keyPrefix: 'purchaseCompleted' });

  return (
    <>
      <p className={styles.order}>{t('orderNumber')}</p>
      <p className={styles.orderNumber}>
        {orderNumber}
        <button onClick={() => navigator.clipboard.writeText(`${orderNumber}`)}>
          <img src={CopyImage} alt="copy" />
        </button>
      </p>
    </>
  );
};

OrderNumberText.propTypes = {
  orderNumber: PropTypes.string.isRequired
};

export default OrderNumberText;
