import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import Input from '../../../components/Input/Input';
import styles from '../sass/PasswordInputForm.module.scss';

const PasswordInputForm = ({
  currentPassword, newPassword, confirmNewPassword, handleBlur, handleChange, errors
}) => {
  const { t } = useTranslation('', { keyPrefix: 'profileSettings' });
  return (
    <div className={styles.inputForm}>
      <Input
        type="password"
        autocomplete="off"
        name="currentPassword"
        label={t('currentPassword')}
        error={errors.currentPassword}
        value={currentPassword}
        onChange={handleChange}
        onBlur={handleBlur}
      />
      <Input
        type="password"
        autocomplete="new-password"
        name="newPassword"
        label={t('newPassword')}
        error={errors.newPassword}
        value={newPassword}
        onChange={handleChange}
        onBlur={handleBlur}
      />
      <Input
        type="password"
        autocomplete="new-password"
        name="confirmNewPassword"
        label={t('confirmNewPassword')}
        error={errors.confirmNewPassword}
        value={confirmNewPassword}
        onChange={handleChange}
        onBlur={handleBlur}
      />
    </div>
  );
};

PasswordInputForm.propTypes = {
  currentPassword: PropTypes.string.isRequired,
  confirmNewPassword: PropTypes.string.isRequired,
  newPassword: PropTypes.string.isRequired,
  handleBlur: PropTypes.func.isRequired,
  handleChange: PropTypes.func.isRequired,
  errors: PropTypes.shape({
    currentPassword: PropTypes.string,
    newPassword: PropTypes.string,
    confirmNewPassword: PropTypes.string
  }).isRequired,

};

export default PasswordInputForm;
