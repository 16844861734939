import React from 'react';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
import styles from '../sass/Category.module.scss';

const Category = ({ heading, image, link }) => {
  const navigate = useNavigate();

  return (
    <div className={styles.category} onClick={() => navigate(link)} role="presentation">
      <h1 className={styles.heading}>{heading}</h1>
      <img className={styles.image} src={image} alt={heading} />
    </div>
  );
};

Category.propTypes = {
  heading: PropTypes.string.isRequired,
  image: PropTypes.string.isRequired,
  link: PropTypes.string.isRequired
};

export default Category;
