import React from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { SIGN_IN, SIGN_UP } from '../../../constants/links';
import Button from '../../../components/Button/Button';
import styles from '../sass/Header.module.scss';

import SignInIcon from '../../../assets/icons/signInCirclePink.svg';

const AuthButtons = () => {
  const { t } = useTranslation('', { keyPrefix: 'header' });
  return (
    <div className={styles.buttonsContainer}>
      <Link className={styles.linkButton} to={SIGN_IN}>
        <Button text={t('signIn')} iconLeft={SignInIcon} buttonStyle={styles.logInButton} />
      </Link>
      <Link className={styles.linkButton} to={SIGN_UP}>
        <Button text={t('signUp')} buttonStyle={styles.logInButton} isFilled />
      </Link>
    </div>
  );
};

export default AuthButtons;
