import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import { SEND_GIFTOON_MODALS as MODALS } from '../../../constants/constants';
import Modal from '../../../components/Modal/Modal';
import SendingTimeModal from './SendingTimeModal';
import DatePickerModal from './DatePickerModal';

const ModalWindows = ({
  activeModal,
  setActiveModal,
  handleSubmit,
  date: defaultDate,
  setDate: setDefaultDate
}) => {
  const [date, setDate] = useState(defaultDate || new Date());
  const closeModal = useCallback(() => setActiveModal(''), [setActiveModal]);

  const openModal = useCallback((modal) => setActiveModal(modal), [setActiveModal]);

  const handleSendLater = useCallback(() => {
    setDefaultDate(date);
    handleSubmit();
    closeModal();
  }, [defaultDate, setDate, setActiveModal, date]);

  const handleCloseMainModal = useCallback(() => {
    setDate(defaultDate || new Date());
    closeModal();
  }, [defaultDate, setDate, setActiveModal]);

  const chooseDate = (value) => {
    setDate(value);
    openModal(MODALS.SENDING_TIME);
  };

  const onChangeTime = (e) => {
    const [hours, minutes] = e.target.value.split(':');
    const newDate = new Date(date);
    newDate.setHours(hours, minutes, 0);
    if (newDate.getTime() > new Date().getTime()) {
      setDate(newDate);
    } else {
      setDate(new Date());
    }
  };

  return (
    <>
      <Modal onClose={handleCloseMainModal} isOpen={activeModal === MODALS.SENDING_TIME}>
        <SendingTimeModal
          onButtonClick={handleSendLater}
          openDatePickerModal={() => openModal(MODALS.DATE_PICKER)}
          date={date}
          onChangeTime={onChangeTime}
        />
      </Modal>
      <Modal onClose={() => openModal(MODALS.SENDING_TIME)} isOpen={activeModal === MODALS.DATE_PICKER}>
        <DatePickerModal selectedDate={date} setDate={chooseDate} closeModal={() => openModal(MODALS.SENDING_TIME)} />
      </Modal>
    </>
  );
};

ModalWindows.propTypes = {
  date: PropTypes.instanceOf(Date),
  activeModal: PropTypes.string.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  setActiveModal: PropTypes.func.isRequired,
  setDate: PropTypes.func.isRequired
};

ModalWindows.defaultProps = {
  date: null
};

export default ModalWindows;
