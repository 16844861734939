import { useMemo } from 'react';
import axios from './axios';
import { logOut, setTokens } from '../store/auth/slice';
import { REFRESH_TOKEN_API } from '../constants/api';
import { REDIRECT, SIGN_IN } from '../constants/links';

const interceptor = (store) => {
  axios.interceptors.request.use(
    (config) => {
      const newConfig = config;

      const { auth: { tokens: { accessToken } } } = store.getState();
      if (!config.withoutAuth && accessToken) {
        newConfig.headers.Authorization = `Bearer ${accessToken}`;
      }
      return newConfig;
    }
  );

  const { dispatch, getState } = store;

  const getRefreshToken = async () => {
    const { auth: { tokens: { refreshToken } } } = getState();

    try {
      return await axios.post(REFRESH_TOKEN_API, {
        refresh_token: refreshToken
      }, {
        withoutAuth: true
      });
    } catch (error) {
      localStorage.removeItem('accessToken');
      localStorage.removeItem('refreshToken');
      dispatch(logOut());
      window.location = `${SIGN_IN}${REDIRECT}${window.location.pathname}${window.location.search}`;
      return false;
    }
  };

  axios.interceptors.response.use(
    (response) => response,
    async (error) => {
      const config = error?.config;

      if (error?.response?.status === 401 && !config?.sent) {
        config.sent = true;

        const result = await getRefreshToken();

        if (result) {
          const { token_data: { access_token: newAccessToken, refresh_token: newRefreshToken } } = result.data;

          config.headers = {
            ...config.headers,
            Authorization: `Bearer ${newAccessToken}`
          };
          localStorage.setItem('accessToken', newAccessToken);
          localStorage.setItem('refreshToken', newRefreshToken);

          dispatch(setTokens({ accessToken: newAccessToken, refreshToken: newRefreshToken }));
        }
        return axios(config);
      }
      throw error;
    }
  );
};

const useInterceptor = (store) => {
  return useMemo(() => interceptor(store), [store]);
};

export default useInterceptor;
