import React from 'react';
import Page from '../../layout/Page/Page';
import '../../styles/contentPages.scss';

import WarningIcon from '../../assets/icons/warning.svg';

const PrivacyPolicy = () => {
  return (
    <Page>
      <section className="contentSection">
        <div className="contentContainer">

          <h1>Privacy Policy</h1>
          <p className="highlightedText">App Happy is committed to protecting and respecting your privacy.</p>
          <p>
            App Happy Pty Ltd (ABN: 71656119541) trading as Giftoon and Giftoon.com is the owner, operator and publisher
            of this website (Website). This privacy policy applies to App Happy Pty Ltd and all related entities
            (referred
            to in this policy as &quot;us&quot;, &quot;our&quot;, &quot;we&quot;).
          </p>
          <p>
            This Policy, together with our Terms and Conditions of Use and any other documents referred to in them, sets
            out how and why we collect, manage,
            use and store personal information.&nbsp;
            <span className="highlightedText">
              We maintain robust physical, electronic, and procedural safeguards to protect personal information.
            </span>
            &nbsp;Personal information is any information or an opinion about an identified individual,
            or an individual who is reasonably identifiable whether the
            information or opinion is true or not; and whether the information or opinion is recorded in a material form
            or not.
            <br />
            Please read the following carefully to understand our practices regarding your personal information.
            By using our Website or mobile applications (Platform) or providing your Personal Information to us, you
            consent to the
            collection, use, disclosure and storage of your personal information in accordance with this Policy.
            <br />
            We reserve the right to change this Privacy Policy from time to time by publishing the revised Privacy
            Policy
            on our Website. You can check this page for the most recent version of the Policy at any time.
          </p>

          <h2>What information do we collect from you and why?</h2>
          <p>We have set out below the information we may collect:</p>
          <ul>
            <li>
              Your contact details such as name, email address, telephone number, delivery address, residential address;
            </li>
            <li>
              Transaction details including your billing address, transaction pattern and behaviour, payment method,
              payment amount;
            </li>
            <li>Information to verify you such as your date of birth;</li>
            <li>Your marketing and communication preferences;</li>
            <li>Comments, images and hashtags you post on our social media posts;</li>
            <li>Technical information about your browser and device;</li>
            <li>Details of how you use our Websites your browser and device;</li>
            <li>Details of how you use our Websites; and</li>
            <li>Details of any feedback, query or complaint received from you.</li>
          </ul>
          <p>We may use the information set out above for purposes including the following:</p>
          <ul>
            <li>to fulfill an order placed by you on the Website or via our social media platforms;</li>
            <li>
              to be able to contact you in case of any queries, delays or issues regarding an order placed by you;
            </li>
            <li>to register your gift card and provide balance updates and to arrange for replacements or refunds;</li>
            <li>to sell our products, including through social media platforms;</li>
            <li>
              to send you advertising, marketing or promotional material relating to Giftoon or its related entities
              and/or affiliates, including marketing affiliate programs we are a part of where you have provided your
              consent;
            </li>
            <li>for reward, loyalty or privilege programs, promotional offers and related services;</li>
            <li>
              to invite you to participate in competitions or promotions and for your participation in such competitions
              or promotions;
            </li>
            <li>
              to design and conduct surveys or questionnaires for research purposes or to profile our clients and/or
              create client segmentation, statistical analysis, improving and furthering the provision of our products
              and services;
            </li>
            <li>
              to ensure that the content on our Website is presented in the most effective manner for you and for your
              computer;
            </li>
            <li>to personalise and customise your experience on our Website;</li>
            <li>
              allowing you to participate in interactive features of our services or Website, when you choose to do so;
            </li>
            <li>to inform our business and marketing strategy;</li>
            <li>
              to provide you with alerts, newsletters, education materials or information that you requested or signed
              up to;
            </li>
            <li>to carry out our obligations arising from any contracts entered into between you and us;</li>
            <li>for fraud prevention purposes;</li>
            <li>
              to comply with laws and regulations applicable to us or any of our related entities whether in Australia
              or elsewhere;
            </li>
            <li>
              for the purpose of legal proceedings, including collective overdue amounts and seeking professional
              advice;
            </li>
            <li>to research, design and launch services and products;</li>
            <li>for invite you to seminars, events and forums; and</li>
            <li>for purposes directly related to or incidental to the above.</li>
          </ul>
          <br />
          <p>
            There may be occasions where we will collect your information for specific purposes which we will disclose
            to you at the time of collection. If you choose not to provide your personal information, this may limit our
            ability to contact you or to undertake certain activities for you.
          </p>

          <h2>How is information collected and held?</h2>
          <p>Information is collected from you when you:</p>
          <ul>
            <li>subscribe to our Website;</li>
            <li>register your gift card;</li>
            <li>complete forms on our Website;</li>
            <li>sign up to our newsletter or marketing materials;</li>
            <li>purchase products or services from us;</li>
            <li>engage with us on social media;</li>
            <li>enter a competition or promotion;</li>
            <li>respond to a survey;</li>
            <li>request a service from us; or</li>
            <li>contact us through the Website, in store, by phone or by email.</li>
          </ul>

          <h2>Who do we disclose personal information to?</h2>
          <p>
            We may disclose your personal information to third parties to fulfill the purposes listed above, for
            example:
          </p>
          <ul>
            <li>retail partners;</li>
            <li>auditors;</li>
            <li>contractors;</li>
            <li>financial institutions;</li>
            <li>logistics and freight providers;</li>
            <li>fraud prevention providers;</li>
            <li>payment processing services;</li>
            <li>website and data hosting services;</li>
            <li>other service providers; and</li>
            <li>
              persons to whom we are required to make disclosures under applicable laws and regulations in or outside
              Australia.
            </li>
          </ul>
          <p>
            We use AWS to power our online stores.
            You can read more about how AWS uses your Personal Information here:&nbsp;
            <a href="https://aws.amazon.com/privacy/">https://aws.amazon.com/privacy/</a>
            . We may also share your information with the operators of our social media
            platforms. We may also disclose your personal information to our related entities due to shared resources,
            systems, and processes. We will never, ever, sell our client’s personal information to third parties. We may
            disclose your personal information to third parties if required or authorised by law, or otherwise with your
            consent. We require all third parties that we disclose your personal information to, to treat your personal
            information in accordance with the law and in accordance with our instructions and the purpose for which the
            information was collected from you, or as otherwise authorised by law or consented to by you.
          </p>

          <h2>Data Transfers</h2>
          <p>
            We may hold your personal information on servers in Australia and any other territories as we see fit from
            time to time. We may also transfer your data to any people listed at paragraph 3 above, who may be located
            in or outside of Australia.
          </p>

          <h2>How do we protect personal information?</h2>
          <p>
            We take reasonable precautions to protect your Personal Information against misuse, interference, and
            unauthorised disclosure.&nbsp;
            <span className="highlightedText">
              We are bound by the Privacy Act 1988 (Cth) (‘Privacy Act’) and will protect your
              personal information we collect from you, or that you provide to us, in accordance with the Australian
              Privacy Principles.
            </span>
            &nbsp;All information you provide to us is stored on our secure servers. Where we have given
            you (or where you have chosen) a password which enables you to access certain parts of our Website, you are
            responsible for keeping this password confidential. We recommend that you update your password from time to
            time and that you do not give your password to anyone. All information is protected via our Information
            Technology security processes (including firewalls and password-protected servers), secure document storage
            and confidentiality agreements. All data captured via the Website is securely stored and is transmitted via
            Secure Sockets Layer (SSL) technology. This encryption protocol automatically scrambles information such as
            credit card, billing and address details so that generally, unauthorised recipients are unable to decipher
            the information, ensuring privacy and security. While we take all reasonable steps to protect personal
            information, the transmission of information via the internet is not completely secure. Although we will do
            our best to protect your personal information, we cannot guarantee the security of your data transmitted to
            or via our Website, any transmission is at your own risk. We exclude all liability for the consequences of
            any unauthorised access, use or disclosure of personal information to the maximum extent permitted under
            law.
          </p>

          <h2>How can you access, update, correct, object to use of, or seek deletion of your personal information?</h2>
          <p>
            Should you wish to access, review or make any corrections or updates to your personal information held by
            us, you can do so at any time by visiting your Account via our Website. You can also contact us via the
            details on the “Contact Us” button for these purposes. You can object to the processing of your personal
            information or request the restriction of processing your personal information under certain circumstances.
            To do this, please contact us via the “Contact Us” button. You may unsubscribe from receiving electronic
            communications at any time by clicking on the “unsubscribe” link within an email you have received from us,
            or by contacting us directly via the “Contact Us” details. You can request the deletion or removal of your
            personal information in certain circumstances. If requested by you to do so, we will delete your personal
            information to the maximum extent technically practical. Please contact us via the details at “Contact Us”
            button should you wish to request deletion of your personal information that we hold. We may need to request
            information from you as a security measure to confirm your identity before acting on any requests to deal
            with your personal information in any of the ways mentioned above. There will not usually be any cost
            associated with a request under this section, however if your request is repetitive or excessive, we may
            charge a reasonable fee for handling such a request.
          </p>

          <h2>
            How long do we keep your personal information?
          </h2>
          <p>
            We will only retain your Personal Information for as long as needed to fulfil the purposes for which we
            collected it, including for the purpose of fulfilling any legal, accounting or reporting requirements. We
            may anonymise your personal information so that we can use it for analytical purposes without being able to
            identify you.
          </p>

          <h2>The Giftoon mobile application (our “App”)</h2>
          <p>
            Our mobile application is covered by this Policy for us to stay compliant with the Privacy Act and
            international privacy laws, as well as App Store guidelines. These terms and conditions govern your use of
            the App and the products, features, apps, services, technologies and software we offer, except where
            expressly stated that separate terms (and not these) apply.
            The terms and conditions establish the rules users must follow when downloading and using the App. Giftoon
            reserves the right to:
          </p>
          <ul>
            <li>Suspend or delete abusive accounts if they violate the terms and conditions;</li>
            <li>
              Update its terms regarding the operation of the App and establishes guidelines for dealing with consumer
              issues such as late delivery, payment problems and refunds;
            </li>
            <li>
              Establish the jurisdiction in which the laws govern the App; and
            </li>
            <li>
              Specify the intellectual property rights and the actions taken where this is infringed on.
            </li>
          </ul>
          <p>
            To access and use the Products and Services, you must register for a Giftoon account (‘Account’) by
            providing your full legal name, a valid email address, phone number and any other information indicated as
            required. Giftoon may reject your application for an Account, or cancel an existing Account, for any reason,
            at our sole discretion.
            A breach or violation of any terms and conditions as determined in the sole discretion of Giftoon may result
            in an immediate termination of your Products and Services
          </p>

          <h2>Facial data</h2>
          <p>
            We do not keep facial or photo data / information. Once the user’s chosen face is uploaded the original
            photo is destroyed from our cloud within the first 24hrs, but usually within 1 hour. We will never use or
            pass on this information to any 3rd parties unless required by law.
          </p>
          <div className="warningContainer">
            <div className="warning">
              <img src={WarningIcon} alt="warning" />
              <p className="warningText">
                We do not collect or store any photos you upload into the app. We use users faces exclusively for the
                purposes you are aware of and do not share them with any third parties.
              </p>
            </div>
          </div>
          <h2>Third party links</h2>
          <p>
            Where third party links are included on the Website, please be aware this policy does not apply to any
            third party websites and we do not control these sites. If you visit any third party sites we recommend you
            familiarise yourself with the applicable privacy policy.
          </p>

          <h2>Cookies</h2>
          <p>
            Our Website uses cookies to distinguish you from other users of our Website. This helps us to provide you
            with a good experience when you browse our Website and also allows us to improve our Website. By continuing
            to browse the Website, you are agreeing to our use of cookies. A cookie is a small file of letters and
            numbers that we store on your browser or the hard drive of your computer. Cookies contain information that
            is transferred to your computer’s hard drive. We use the following cookies: Strictly necessary cookies -
            these are cookies that are required for the operation of our Website. They include, for example, cookies
            that enable you to log into secure areas of our Website, use a shopping cart or make use of e-billing
            services. Third party cookies - third parties (including, for example, advertising networks and providers of
            external services like web traffic analysis services) may also use cookies, over which we have no control.
            These cookies are likely to be analytical/performance cookies or targeting cookies. Third party analytics -
            we may use third-party web analytic services on the Website. The service providers that administer these
            services use technologies such as cookies, web server logs and web beacons to help us analyse how visitors
            use the Website. The information collected through these means (including IP address) is disclosed to these
            service providers, who use the information to evaluate use of the website. These analytic services may use
            the data collected to contextualise and personalise the marketing materials of their own advertising
            network.
          </p>

          <h2>Google Analytics</h2>
          <p>
            Google Analytics is a web analysis service provided by Google Inc. (Google). Google’s ability to use and
            share information collected by Google Analytics is in accordance with its policies:&nbsp;
            <a href="https://policies.google.com/technologies/partners/">
              https://policies.google.com/technologies/partners/
            </a>
            &nbsp;You can prevent Google’s collection and processing of data
            by using the Google Ads Settings page or downloading and installing their browser plug-in (
            <a href="https://tools.google.com/dlpage/gaoptout/">
              https://tools.google.com/dlpage/gaoptout
            </a>
            ). You block cookies by activating the setting on your browser that
            allows you to refuse the setting of all or some cookies. However, if you use your browser settings to block
            all cookies (including essential cookies) you may not be able to access all or parts of our site.
          </p>

          <h2>Children</h2>
          <p>
            We are committed to protecting the privacy of children. Children under the age of 15 are prohibited from
            using the Website. We will not knowingly collect any information from children under the age of 15, or
            knowingly send any information to children under 15. If a child wishes to use the Website or make a purchase
            on the Website, this must be under the supervision of a parent or legal guardian who will be responsible for
            the use of the Website by the child and will be responsible for any financial liability as a result of such
            use or orders being placed. If you become aware that information from a child under the age of 15 has been
            disclosed to us, please notify us at the “Contact Us” details below so that we can remove and delete that
            information.
          </p>

          <h2>How do you make a complaint?</h2>
          <p>
            If you have any concerns about the way in which your personal information has been collected, held, used or
            disclosed by us, you may contact us at the details in the Contact Us section below. We will review your
            query or complaint, investigate, and provide you with our response as soon as practical. We will endeavour
            to deal with all queries or complaints within 30 days. Alternatively, or in addition, you can contact the
            Office of the Australian Information Commissioner – see information at&nbsp;
            <a href="https://oaic.gov.au">https://oaic.gov.au</a>
            &nbsp;or the regulator
            in your country of residence.
          </p>
          <br />
          <p>Last updated 11 October 2022</p>
        </div>
      </section>
    </Page>
  );
};

export default PrivacyPolicy;
