import React from 'react';
import PropTypes from 'prop-types';
import InfiniteScroll from 'react-infinite-scroll-component';
import ReceivedGiftoonItem from '../../../components/ReceivedGiftoonItem/ReceivedGiftoonItem';
import styles from '../sass/CouponList.module.scss';

import GiftoonSmileIcon from '../../../assets/icons/giftoonSmile.svg';

const CouponList = ({
  giftoons, nextLink, getNextGiftoons, setOpenedCoupon
}) => {
  return (
    <InfiniteScroll
      next={getNextGiftoons}
      hasMore={nextLink}
      loader={<img className={styles.giftoonSmile} src={GiftoonSmileIcon} alt="giftoon" />}
      dataLength={giftoons.length}
      className={styles.couponContainer}
    >
      {giftoons.map(
        ({
          id,
          giftoon,
          isUsed,
          isNew,
          brand: { image_url: brandImageUrl, high_resolution_image: highResolutionImage, product_name: productName },
          giveaway,
          brand_price: brandPrice,
          created_at: createdAt,
          email,
          phone,
          order_data: orderData,
        }) => (
          <ReceivedGiftoonItem
            withGiftoon={!!giveaway?.title}
            key={id}
            giftoonVideo={giftoon}
            isUsed={isUsed}
            isNew={isNew}
            brandImageUrl={highResolutionImage || brandImageUrl}
            previewImageUrl={giveaway?.preview_image}
            brandName={productName}
            brandPrice={brandPrice}
            createdAt={createdAt}
            email={email}
            phone={phone}
            onClickUseCoupon={() => setOpenedCoupon(orderData?.CustomerOrderId, productName)}
          />
        )
      )}
    </InfiniteScroll>
  );
};

CouponList.propTypes = {
  giftoons: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number,
    isUsed: PropTypes.bool,
    isNew: PropTypes.bool
  })).isRequired,
  getNextGiftoons: PropTypes.func.isRequired,
  nextLink: PropTypes.string,
  setOpenedCoupon: PropTypes.func.isRequired,
};

CouponList.defaultProps = {
  nextLink: '',
};

export default CouponList;
