import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import Button from '../../../components/Button/Button';
import Input from '../../../components/Input/Input';
import styles from '../sass/SendingTimeModal.module.scss';

import PlaneIcon from '../../../assets/icons/plane.svg';
import CalendarIcon from '../../../assets/icons/calendar.svg';
import ClockIcon from '../../../assets/icons/clockTransparent.svg';

const SendingTimeModal = ({
  date, onButtonClick, openDatePickerModal, onChangeTime
}) => {
  const { t } = useTranslation('', { keyPrefix: 'sendGiftoon' });

  return (
    <div className={styles.sendingTimeContainer}>
      <h2 className={styles.heading}>{t('sendingTime')}</h2>
      <div className={styles.inputsContainer}>
        <Input
          onLeftIconClick={openDatePickerModal}
          value={date.toString().slice(0, 15)}
          iconLeft={CalendarIcon}
          onClick={openDatePickerModal}
          inputStyle={styles.dateInput}
        />
        <Input
          onLeftIconClick={openDatePickerModal}
          value={date.toString().slice(16, 21)}
          iconLeft={ClockIcon}
          onChange={onChangeTime}
          type="time"
        />
      </div>
      <div className="buttonContainer">
        <Button onClick={onButtonClick} text={t('sandAtScheduledTime')} iconRight={PlaneIcon} isFilled />
      </div>
    </div>
  );
};

SendingTimeModal.propTypes = {
  date: PropTypes.instanceOf(Date).isRequired,
  onButtonClick: PropTypes.func.isRequired,
  openDatePickerModal: PropTypes.func.isRequired,
  onChangeTime: PropTypes.func.isRequired,
};

export default SendingTimeModal;
