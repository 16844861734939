import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ProfileGetUserDataThunk } from '../../store/profile/slice';
import MyInfoSection from './components/MyInfoSection';
import ChangePasswordSection from './components/ChangePasswordSection';
import DeleteAccountSection from './components/DeleteAccountSection';
import styles from './sass/ProfileSettings.module.scss';

const ProfileSettings = () => {
  const dispatch = useDispatch();
  const { id, fullName, email } = useSelector(({ profile }) => profile.data);

  useEffect(() => {
    dispatch(ProfileGetUserDataThunk({ id }));
  }, []);

  return (
    <section className={styles.section}>
      <MyInfoSection fullName={fullName} email={email} />
      <ChangePasswordSection />
      <DeleteAccountSection />
    </section>
  );
};

export default ProfileSettings;
